<template>
  <div class="card">
    <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
      <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Top Up Saldo</h1>
    </div>
    <div class="card-content" style="text-align: center;">
      <img style="width: 150px;" src="../../assets/invoice_terbuat.png" />
      <!-- <div>Invoice telah dibuat dengan nominal sebesar</div>
      <div style="margin-top: 10px;" class="title is-5">Rp. {{ nominal | numFormat }}</div> -->
      <div style="margin-top: 10px; margin-bottom: 10px;">
        <b-button :loading="isLoading" type="is-success" @click="lanjutkanOnClick()" style="width: 200px;">Lanjutkan Pembayaran</b-button>
      </div>
      <div class="is-size-7">Invoice yang belum terbayar dapat dilihat <br/> di Menu Laporan > Riwayat Top Up </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopUpConfirmInvoice',
  computed: {},
  props: {
    nominal: {
      type: Number
    }
  },
  methods: {
    lanjutkanOnClick () {
      this.isLoading = true
      this.$emit('setlanjutkan')
    }
  },
  data () {
    return {
      isLoading: false
    }
  }
}
</script>
