<template>
  <div>
    <b-table
      :total="paginate.total"
      :per-page="paginate.perPage"
      :data="paginate.data"
      :loading="paginate.loading"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
      backend-pagination
      scrollable>
      <b-table-column field="project_name" label="ID Penawaran" v-slot="props">
        <div
          class="project-btn"
          @click="detailOnClick(props.row)">
            <span> ID # {{ props.row.id }}</span>
        </div>
      </b-table-column>
      <b-table-column field="no_penawaran" label="No.Penawaran" v-slot="props">
        <span> {{ props.row.no_penawaran === null ? '-' : props.row.no_penawaran}}</span>
      </b-table-column>
      <b-table-column width="350" field="location" label="Lokasi" v-slot="props">
        {{ props.row.address }}
      </b-table-column>
      <b-table-column field="created_at" label="Tanggal Dibuat" v-slot="props">
        <span>
          {{ setFormat(props.row.created_at) }}
        </span>
      </b-table-column>
      <b-table-column field="type" label="Jenis Pesanan">
        <span class="tag is-primary is-light has-text-weight-bold">
          Penawaran
        </span>
      </b-table-column>
      <b-table-column field="button" label="Action" v-slot="props">
        <b-button
          @click="detailOnClick(props.row)"
          type="is-success">
          Lihat Detail
        </b-button>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="paginate.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferTableList',
  data () {
    return {
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  props: {},
  watch: {},
  components: {},
  methods: {
    lihatDetailOnClick () {},
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      this.loadData(v)
    },
    detailOnClick (v) {
      const str = v.status
      const status = str.replace(/\s+/g, '-')
      this.$router.push({
        path: `/penawaran/detail/${v.id}/${status}`
      })
    },
    loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      // const status = 'Active'

      const data = {
        url: this.url,
        // status: status,
        page: this.paginate.page,
        perPage: this.paginate.perPage
      }

      this.$store.dispatch('offer/offerListApi', data)
        .then((res) => {
          if (res.data.code === 1) {
            this.paginate.data = res.data.data.list
            this.paginate.total = (res.data.data.total_page * this.paginate.perPage)
            this.paginate.page = res.data.data.current_page
            this.paginate.loading = false
          }
        })
        .catch((err) => {
          throw new Error(err)
        })
    }
  },
  mounted () {},
  created () {
    this.loadData(1)
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
