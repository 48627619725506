import axios from 'axios'

const getDefaultState = () => {
  return {
    penawaran: null
  }
}

const state = () => ({
  penawaran: null
})

const getters = {
  getNewPenawaran: (state, getters, rootState) => {
    return state.penawaran
  }
}

const actions = {
  setNewPenawaran ({ commit, state }, payload) {
    commit('newPenawaran', payload)
  },
  resetState ({ commit }) {
    commit('resetState')
  },
  offerListApi ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}/api/v1/vendor/project_list_penawaran?page=${payload.page}&limit=${payload.perPage}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  newPenawaran (state) {
    state.penawaran = {
      idtransaction_item: '',
      address: '',
      amount: 0,
      fee_building: 0,
      net_amount: 0,
      total_harga_keseluruhan: 0,
      customer_name: '',
      email: '',
      project_name: 'MITRA10-KANGGO',
      description: '',
      product_id: [],
      service_id: [],
      duration: '',
      start_date: '',
      end_date: '',
      jenis_bangunan: '',
      building_id: '',
      latitude: -6.175381728927934,
      longitude: 106.8269489488614,
      user_id: 0,
      vendor_id: 1,
      detail_sesi: [],
      item: [],
      transaction_id: '',
      branch: 1,
      customer_id: null,
      schedules: []
    }
  },

  rescheduleProject (state) {
    state.penawaran = {
      project_id: '',
      detail_sesi_resch: []
    }
  },

  setTotalAll (state, payload) {
    state.penawaran.total_harga_keseluruhan = +payload
  },
  setCustomerId (state, val) {
    state.penawaran.customer_id = val
  },
  setInfo (state, info) {
    state.penawaran.idtransaction_item = info.idtransaction_item
    state.penawaran.user_id = info.user_id
    state.penawaran.transaction_id = info.transaction_id
  },
  setInfoDate (state, info) {
    state.penawaran.duration = info.duration
    state.penawaran.start_date = info.start_date
    state.penawaran.end_date = info.end_date
  },
  setItem (state, items) {
    state.penawaran.item = items
  },
  setProductId (state, products) {
    state.penawaran.product_id = products
  },
  setServiceId (state, services) {
    state.penawaran.service_id = services
  },
  setAmount (state, total) {
    state.penawaran.amount = total.amount
    state.penawaran.net_amount = total.net_amount
    state.penawaran.fee_building = total.fee_building
  },
  setCustomer (state, customer) {
    state.penawaran.customer_name = customer.name
    state.penawaran.cell_phone = customer.cell_phone
  },
  setTransactionProduct (state, products) {
    state.penawaran.transactionProduct.listProduct = products
  },
  setTransactionProductSelected (state, transaction) {
    state.penawaran.transaction_id = transaction.id
    state.penawaran.transactionProduct.transaction = transaction.products
  },
  setAddress (state, val) {
    state.penawaran.address = val.address
    state.penawaran.longitude = val.lng
    state.penawaran.latitude = val.lat
    state.penawaran.jenis_bangunan = val.jenis_bangunan
    state.penawaran.building_id = val.building_id
  },
  setImageUri (state, product) {
    state.penawaran.transactionProduct.listProduct[product.index].image_uri = product.image_uri
  },
  setDetailSesi (state, val) {
    state.penawaran.detail_sesi.push(val)
  },
  clearDetailSesi (state) {
    state.penawaran.detail_sesi = []
  },
  setItemTukang (state, items) {
    state.penawaran.item = items
  },
  clearItemTukang (state) {
    state.penawaran.item = []
  },
  setDescription (state, description) {
    state.penawaran.description = description
  },
  setSchedule (state, data) {
    state.penawaran.skills = data.skills
    state.penawaran.products = data.products
    state.penawaran.schedules = data.schedules
  },
  setDetailSesiReschedule (state, val) {
    state.penawaran.detail_sesi_resch.push(val)
  },
  clearDetailSesiReschedule (state, val) {
    state.penawaran.detail_sesi_resch = []
  },
  setProjectId (state, val) {
    state.penawaran.project_id = val
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
