<template>
  <transition name="fadeDown">
    <div class="dash-container">
      <section style="margin-bottom: 20px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                <b-icon icon="hand-heart" /> &nbsp; Penawaran
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            </div>
          </div>
        </div>
      </section>
      <div class="columns">
        <div class="column">
          <div class="card" style="background-color: rgb(240, 240, 240); padding-bottom: 20px;">
            <div class="card-content">
              <div class="level">
                <div class="level-left">
                  <!-- <h4 class="title is-6">Mencari Mitra</h4> -->
                </div>
              </div>
            </div>
            <offer-table-list/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'
import OfferTableList from '../../components/OfferTableList.vue'

export default {
  name: 'Penawaran',
  components: {
    OfferTableList
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      page: 1,
      limit: 10
    }
  },
  methods: {},
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
</style>
