<template>
  <section>
    <div v-show="!viewState" class="container is-max-desktop">
      <!-- Card Pilih Customer -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="has-text-weight-bold">Pilih Customer</div>
        <b-notification class="is-info mt-2">
          <span class="has-text-weight-bold has-text-white">Jika sudah menentukan Customer,
            infokan jadwal tukang yang akan datang ke Customer yang telah dipilih!
          </span>
        </b-notification>
        <div class="columns" v-if="newPenawaran && newPenawaran.customer_name" style="padding-top: 10px;">
          <div class="column is-four-fifths">
            <h1 class="title is-5 mt-2"> {{ newPenawaran.customer_name }} ( {{ newPenawaran.cell_phone }} ) </h1>
          </div>
          <div class="column">
            <b-button type="is-success" icon-left="account" @click="modal.customerIsView=true">Ubah</b-button>
          </div>
        </div>
        <div style="text-align: center; margin-top: 30px;">
          <b-button
            v-if='!newPenawaran || !newPenawaran.customer_name'
            @click="modal.customerIsView=true"
            type="is-success"
            style="width: 300px;"
            icon-left="account-alert-outline">
              Tentukan Client
          </b-button>
        </div>
      </div>
      <!-- Card Pilih Mitra -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="has-text-weight-bold">Pilih Tukang</div>
        <div class="dropdown-box">
          <b-dropdown
            append-to-body
            scrollable
            max-height="300"
            expanded
            @change="skillIsSelected"
            v-model="currentSkill"
            aria-role="list">
            <template #trigger>
              <b-button
                label="Pilih tukang yang anda butuhkan"
                type="is-light"
                icon-right="menu-down" />
            </template>
            <b-dropdown-item
              v-for="(menu, index) in skills"
              :key="index"
              :value="menu" aria-role="listitem">
              <div class="media">
                <img :src="menu.image_uri" class="media-left" style="width: 50px;" />
                <div class="media-content">
                  <h3>{{menu.name}}</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="m-1">
          <div v-for="(lay, idx) in skillsSelected" :key="lay.id">
            <div class="columns is-mobile mt-3">
              <div class="column is-one-third-mobile">
                <div style="display: flex;">
                  <div>
                    <img :src="lay.image_uri" style="max-width: 2rem;" />
                  </div>
                  <div class="m-2">
                    {{ lay.name }}
                  </div>
                </div>
              </div>
              <div class="column is-half-mobile">
                <b-field class="has-text-centered">
                  <b-numberinput
                    :min="1"
                    v-model="lay.tukang"
                    type="is-primary"
                    controls-rounded expanded>
                  </b-numberinput>
                </b-field>
              </div>
              <div class="column">
                <div class="has-text-right">
                  <b-button
                    @click="deleteSkill(idx)"
                    type="is-danger"
                    icon-left="delete"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Deskripsi Pekerjaan -->
      <div class="input-card" style="margin-top: 20px;">
        <div
          class="has-text-weight-bold">
          Deskripsi Pekerjaan
          <span
            class="has-text-danger is-italic">*
          </span>
        </div>
        <div style="margin-top: 25px;">
          <b-input
            v-model="deskripsiPekerjaan"
            rows="2"
            maxlength="200"
            type="textarea">
          </b-input>
        </div>
      </div>
      <!-- Card Pilih Alamat -->
      <div class="input-card" style="margin-top: 20px;">
        <div style="display: flex;">
          <div style="width: 50%;" class="has-text-weight-bold"> Alamat </div>
          <div style="width: 50%; text-align: right;" v-if="newPenawaran && newPenawaran.address">
            <div class="has-text-right" style="padding-top: 10px; color: blue;"> Klik disini untuk edit </div>
          </div>
        </div>
        <div style="display: flex;" v-if="newPenawaran && newPenawaran.address">
          <div style="padding-top: 20px; width: 100%;">
            <div style="margin-right: 20px;" v-if="newPenawaran && newPenawaran.latitude && newPenawaran.longitude">
              <GmapMap map-type-id="roadmap" :zoom="15"
                :center="map.currentPlace"
                :options="{disableDefaultUI: true}"
                style="width: 100%; height: 200px;">
                <GmapMarker :position="map.currentPlace">
                </GmapMarker>
              </GmapMap>
            </div>
            <div style="padding: 20px 20px 0px 0px;">
              {{ newPenawaran.address }}
              <br/>
              Bangunan: <strong>{{ newPenawaran.jenis_bangunan }}</strong>
            </div>
          </div>
          <div style="width: 220px; padding-top: 20px;">
            <b-button
              class="button is-info is-outlined"
              @click="modal.tentukanAlamatIsView=true"
              style="width: 200px;">
                Ubah
            </b-button>
          </div>
        </div>
        <div style="text-align: center; margin-top: 40px;">
          <b-button v-if="!newPenawaran || !newPenawaran.address" @click="modal.tentukanAlamatIsView=true"
            type="is-success" style="width: 300px;" icon-left="map-marker"> Tentukan Alamat </b-button>
        </div>
      </div>
      <!-- Card Pilih Jadwal -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="has-text-weight-bold">Jadwal</div>
        <div class="level" v-if="newPenawaran && newPenawaran.detail_sesi.length > 0">
          <div class="level-left">
            <table class="table" style="margin-top: 20px;">
              <tbody>
                <tr style="color: grey;" v-for="(v, i) in newPenawaran.detail_sesi" :key="i">
                  <td>{{ v.day }}</td>
                  <td>{{ v.skillsName }}</td>
                  <td>{{ convDate(v.date) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="level-right">
            <b-button
              @click="tentukanJadwalOnShow()"
              style="width: 200px;">
                Ubah
            </b-button>
          </div>
        </div>
        <div
          v-if="newPenawaran && newPenawaran.detail_sesi.length === 0"
          style="text-align: center; margin-top: 40px;"
          >
          <b-button
            @click="tentukanJadwalOnShow()"
            type="is-success"
            style="width: 300px;"
            icon-left="calendar-multiselect">
              Tentukan Jadwal
          </b-button>
        </div>
      </div>
      <!-- Card Deskripsi Pekerjaan -->
      <div class="input-card" style="margin-top: 20px;">
        <div
          class="has-text-weight-bold">
            Total Keseluruhan
            <span
              class="has-text-danger is-italic">*
            </span>
        </div>
        <div style="margin-top: 25px;">
          <b-input placeholder="Number" v-model="totalKeseluruhan" type="number"></b-input>
        </div>
      </div>
      <div style="text-align: center;">
        <b-icon
          style="color: whitesmoke;"
          size="is-medium"
          icon="arrow-down-thin-circle-outline">
        </b-icon>
      </div>
      <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
        <b-button
          @click.prevent="createProjectConfirm()"
          type="is-primary"
          style="width: 300px;"
          icon-right="arrow-right">
            Lanjutkan
        </b-button>
      </div>
    </div>
    <!-- ALL MODAL -->
    <b-modal :width="500" v-model="modal.customerIsView">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head" style="border-bottom: 0">
          <p class="modal-card-title" style="text-align: center; font-weight: 600;">Tentukan Client</p>
          <button type="button" class="delete" @click="modal.customerIsView = false"/>
        </header>
        <section class="modal-card-body">
          <customer-find @catchvalue="insertCustomer" />
        </section>
      </div>
    </b-modal>
    <b-modal v-model="modal.tentukanAlamatIsView" :width="630" scroll="keep">
      <div class="card">
        <div class="card-content">
          <b-field label="Lokasi di Peta">
            <div style="display: flex;">
              <div v-if="map.currentPlace.lat && map.mapSelectedIsShow">
                <GmapMap map-type-id="roadmap" :zoom="15"
                  :center="map.currentPlace"
                  :options="{disableDefaultUI: true}"
                  style="width: 450px; height: 150px">
                  <GmapMarker :position="map.currentPlace">
                  </GmapMarker>
                </GmapMap>
              </div>
              <div class="ml-1">
                <b-button @click="mapOnViewClick()" type="is-success" icon-left="map-marker-plus"> Pilih Lokasi </b-button>
              </div>
            </div>
          </b-field>
          <b-field label="Alamat">
            <b-input disabled v-model="modal.addressRef" rows="2" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Deskripsi Lokasi">
            <b-input v-model="modal.address" rows="2" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Jenis Bangunan">
            <div class="columns is-gapless">
              <div v-for="(v, i) in buildingType" :key="i" class="column">
                <div @click="setBuildingType(i)" :class="{'jenis-bangunan-selected': v.isSelect === true}" class="card jenis-bangunan">
                  <div class="card-content justify-center">
                    <img class="image is-32x32 justify-center" :src="v.image_uri" />
                    {{ v.name }} <br/>
                    <small>Rp.{{ v.fee_charge | numFormat }}</small>
                  </div>
                </div>
              </div>
            </div>
          </b-field>
          <div class="content" style="text-align: right; margin-top: 15px;">
            <b-button
              @click="insertAddress()"
              style="width: 200px;"
              :type="{'is-light': modal.address === '' && map.currentPlace.lat === null,
                        'is-success' : modal.address !== '' && map.currentPlace.lat !== null }">
                Simpan
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modal.isMapOnView">
      <div class="card">
        <div class="card-content">
          <b-field label="Cari Alamat">
            <b-autocomplete
              :data="map.address"
              placeholder="Telusuri Alamat"
              field="title"
              :loading="map.isFetchingAddress"
              @typing="getAddress"
              @select="option => map.addressSelected = option">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small> {{ props.option.address }} </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <GmapMap map-type-id="roadmap" :zoom="15"
            :center="map.gmapCenter"
            style="width: auto; height: 400px">
            <GmapInfoWindow :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
              <div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>
            </GmapInfoWindow>
            <GmapMarker :position="map.markerPosition" :clickable="true"
              @dragend="updateCoordinate"
              @click="mapMarkerOnClick()"
              :draggable="true">
            </GmapMarker>
          </GmapMap>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modal.isTentukanJadwal" :width="640" scroll="keep">
      <div class="card">
        <div style="border-bottom: solid thin whitesmoke; height: 60px;">
          <p class="title is-5"
            style="text-align: center; margin-bottom: 0px; margin-top: 60px;">
            Tentukan Jadwal Pengerjaan
          </p>
        </div>
        <div class="card-content">
          <div class="content">
            <v-date-picker
              :min-date='minDateOrder'
              @input="onDateRangeChange"
              is-range
              :columns="modal.dateColumn"
              value="range"
              is-expanded
              :attributes="dateAttributes" />
          </div>
          <div class="content" style="text-align: right;">
            <b-button
              @click="inputSesi()"
              style="width: 200px;"
              :type="{'is-light' : days.length === 0,
                        'is-success' : days.length > 1 }">
              Simpan
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- jika saldo tidak cukup -->
    <b-modal v-model="modal.isSaldoTidakCukup" :width="450">
      <div class="card">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Informasi</h1>
        </div>
        <div class="card-content" style="text-align: center;">
          <img style="width: 150px;" src="../assets/saldo-tidak-cukup.png" />
          <div style="font-weight: bold;"> Saldo tidak mencukupi! </div>
          <div> Silakan top up saldo terlebih dahulu, kemudian klik tombol buat proyek lagi </div>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <b-button type="is-success" @click="closeTopupSaldo()" style="width: 200px;">Top Up Saldo</b-button>
          </div>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <b-button type="is-danger" @click="modal.isSaldoTidakCukup = false" style="width: 200px;">Kembali</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <div v-show="viewState">
      <transition name="fade">
        <div>
          <ConfirmationProject :dataCalculation="dataCalculation"/>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
// import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import CustomerFind from '../views/project/Customer.vue'
import ConfirmationProject from './ConfirmationProject.vue'

export default {
  name: 'FormOffer',
  components: {
    CustomerFind,
    ConfirmationProject
  },
  data () {
    return {
      viewState: false,
      currentSkill: null,
      skillsSelected: [],
      skills: [],
      deskripsiPekerjaan: '',
      totalKeseluruhan: 0,
      buildingType: [],
      sessionSel: [],
      days: [],
      idtransaction_item: null,
      minDateOrder: null,
      dataCalculation: null,
      modal: {
        customerIsView: false,
        tentukanAlamatIsView: false,
        isMapOnView: false,
        isTentukanJadwal: false,
        dateColumn: 2,
        address: '',
        addressRef: '',
        isSaldoTidakCukup: false
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      },
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  watch: {
    deskripsiPekerjaan (newVal, oldVal) {
      this.$store.commit('offer/setDescription', newVal.trim())
    },
    totalKeseluruhan (newVal, oldVal) {
      this.$store.commit('offer/setTotalAll', newVal.trim())
    },
    'map.addressSelected' (newVal, oldVal) {
      this.refreshAllCoordinate(newVal)
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      url: 'setup/getUrl',
      dateSesi: 'project/getSesi',
      userSaldo: 'setup/getUserSaldo',
      newPenawaran: 'offer/getNewPenawaran'
    }),
    totalTukangSelected () {
      let total = 0
      this.productServiceSelected.forEach((row) => {
        row.skills.forEach((_row) => {
          total += _row.order_tukang
        })
      })
      return total
    },
    layoutCalender () {
      return this.$screens({
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true
        },
        lg: {
          columns: 2,
          rows: 1,
          isExpanded: true
        }
      })
    },
    dates () {
      return this.days.map(day => day.date)
    },
    dateAttributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    filter () {
      const data = [...new Map(this.listSesi.map(item => [item.id, item])).values()]
      return data
    },
    listSesi () {
      var x = []
      const sesi = []

      if (this.days.length >= 2) {
        const start = moment(this.days[0].date)
        const end = moment(this.days[1].date)

        for (var m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
          const _id = moment(m).format('YYYY-MM-DD')
          x.push({ id: _id, date: m.valueOf() })
        }
        x.forEach((v) => {
          const day = {
            id: v.id,
            selected: [],
            detail: [],
            name: []
          }
          this.skillsSelected.forEach((s) => {
            const _day = moment(v.date).weekday()
            const val = 'Minggu,Senin,Selasa,Rabu,Kamis,Jumat,Sabtu'.split(',')

            day.name.push({
              nameSkill: s.name
            })

            s.sesi.forEach((t) => {
              day.detail.push({
                date: v.id,
                sesi_id: t.id,
                skillId: s.id,
                skillsName: s.name,
                sesi: t.name,
                sesi_homecare: t.sesi_homecare,
                start_hour: t.start_hour,
                end_hour: t.end_hour,
                price: t.price,
                workerCount: s.tukang,
                day: val[_day]
                // disabled: false
              })
            })
          })
          sesi.push(day)
        })
        return sesi
      } else {
        return []
      }
    }
  },
  methods: {
    closeTopupSaldo () {
      this.modal.isSaldoTidakCukup = false
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    async getAddress (val) {
      await axios.get(`${this.url}/api/v1/vendor/map?place=${val}`)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.map.address = cb.data.data
          }
        })
    },
    async loadBuildingType () {
      await axios.get(`${this.url}/api/v1/vendor/building_type`)
        .then((cb) => {
          const x = []
          cb.data.data.forEach((rw) => {
            const r = rw
            r.isSelect = false
            x.push(r)
          })
          this.buildingType = x
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async loadSkillList () {
      try {
        await axios.get(`${this.url}/api/v1/vendor/skill?page=1&limit=1000`)
          .then((cb) => {
            this.skills = cb.data.data
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        throw new Error(error)
      }
    },
    mapOnViewClick () {
      if (this.map.mapSelectedIsShow === true) {
        const x = JSON.parse(JSON.stringify(this.map.currentPlace))
        this.map.markerPosition = x
        this.map.gmapCenter = x
      }
      this.infoWinOpen = false
      this.modal.isMapOnView = true
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      // this.updateCoordinate()
      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon
      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
    },
    getAddressMap (location) {
      // const geocoder = this.google.maps.Geocoder()
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
        // console.info(response.results)
      })
    },
    mapMarkerOnClick () {
      this.infoWindowPos = this.map.currentPlace
      this.infoWinOpen = !this.infoWinOpen

      const latLngObj = {
        lat: this.map.markerPosition.lat,
        lng: this.map.markerPosition.lng
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
      this.map.mapSelectedIsShow = true
      // this.modal.isMapOnView = false
    },
    simpanLokasiOnClick () {
      this.modal.isMapOnView = false
    },
    insertAddress () {
      if (this.modal.address.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Harap detail <b>alamat customer</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      let jenisBangunan = null
      let buildingId = null
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          jenisBangunan = rw.name
          buildingId = rw.id
        }
      })

      const addr = {
        address: this.modal.addressRef + ' + ' + this.modal.address,
        lat: this.map.currentPlace.lat,
        lng: this.map.currentPlace.lng,
        jenis_bangunan: jenisBangunan,
        building_id: buildingId
      }

      if (addr.jenis_bangunan == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Jenis Bangunan Harus Pilih!!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.$store.commit('offer/setAddress', addr)
      this.modal.tentukanAlamatIsView = false
    },
    setBuildingType (idx) {
      this.buildingType.forEach((rw, _idx) => {
        const changeIsSelect = idx === _idx ? this.buildingType[_idx].isSelect = !this.buildingType[_idx].isSelect : this.buildingType[_idx].isSelect = false
        return changeIsSelect
      })
    },
    inputSesi () {
      this.modal.isTentukanJadwal = false
    },
    tentukanJadwalOnShow () {
      this.modal.isTentukanJadwal = true
      this.days = []
      this.sessionSel.splice(0)
      this.$store.commit('offer/clearDetailSesi')
    },
    onDateRangeChange (day) {
      this.days = []
      this.days.push({
        id: moment(day.start).format('YYYY-MM-DD'),
        date: moment(day.start).toDate()
      })
      this.days.push({
        id: moment(day.end).format('YYYY-MM-DD'),
        date: moment(day.end).toDate()
      })

      this.filter.forEach((item) => {
        item.detail.forEach((property) => {
          if (property.sesi_homecare === 0) {
            this.$store.commit('offer/setDetailSesi', property)
          }
        })
      })
    },
    deleteSkill (idx) {
      this.skillsSelected.splice(idx, 1)
    },
    insertCustomer (val) {
      this.$store.commit('offer/setCustomer', val)
      this.$store.commit('offer/setCustomerId', val.id)
      this.modal.customerIsView = false
    },
    skillIsSelected (val) {
      const sel = JSON.parse(JSON.stringify(val))
      /** check skill is selected */
      let hasSelected = false
      this.skillsSelected.forEach((rw) => {
        if (rw.id === sel.id) {
          hasSelected = true
        }
      })
      if (hasSelected === false) {
        sel.tukang = 1
        this.skillsSelected.push(sel)
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${sel.name} sudah pernah dipilih!`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    setInfoDate () {
      const data = this.filter.slice().sort((a, b) => new Date(a.id) - new Date(b.id))
      const startDate = data[0].id
      const endDate = data[data.length - 1].id
      const duration = moment(endDate).diff(moment(startDate), 'days') + 1

      this.$store.commit('offer/setInfoDate', {
        start_date: startDate,
        end_date: endDate,
        duration: duration
      })
    },
    hitungAmount () {
      let amount = 0
      let orderTukang = 0
      let hargaSesi0 = 0
      let hargaSesi1 = 0
      let hargaSesi2 = 0
      let hargaSesi3 = 0
      let feeCharge = 0
      let feeBuilding = 0
      const durationDay = this.newPenawaran.duration
      // let totalSesi = 0

      if (durationDay === 0) {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Cek sesi terlebih dahulu!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      // total harga perhari
      this.newPenawaran.detail_sesi.forEach((row) => {
        if (row.sesi !== null) {
          if (row.sesi_homecare === 0) {
            hargaSesi0 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 1) {
            hargaSesi1 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 2) {
            hargaSesi2 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 3) {
            hargaSesi3 += (row.workerCount * row.price)
          }
        }
      })
      // total tukang
      this.skillsSelected.forEach((row) => {
        orderTukang += row.tukang
      })

      // fee_charge building type
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          feeCharge = rw.fee_charge
        }
      })

      amount = ((hargaSesi0 + hargaSesi1 + hargaSesi2 + hargaSesi3) + ((feeCharge * orderTukang) * durationDay))

      feeBuilding = ((feeCharge * orderTukang) * durationDay)

      return {
        amount: amount,
        orderTukang: orderTukang,
        dateCount: durationDay,
        fee_building: feeBuilding
        // startDate: startDate,
        // endDate: endDate,
      }
    },
    setItem () {
      const x = []
      this.filter.forEach((row) => {
        row.detail.forEach((item) => {
          const y = {
            date: item.date,
            premium_price: 0,
            premium_worker: 0,
            skill_id: item.skillId,
            skill_name: item.skillsName,
            standard_price: item.price,
            session1_price: item.session1_price,
            session2_price: item.session2_price,
            standard_worker: item.workerCount
          }
          x.push(y)
        })
      })

      const vv = [...new Map(x.map(item => [item.skill_id, item])).values()]
      this.$store.commit('offer/setItem', vv)
    },
    setSchedule () {
      const skills = []
      const schedules = []
      this.skillsSelected.forEach((row) => {
        skills.push({
          skill_id: row.id,
          skill_name: row.name,
          order_tukang: row.tukang,
          image_uri: row.image_uri
        })
      })
      this.newPenawaran.detail_sesi.forEach((row) => {
        schedules.push({
          date_sesi: row.date,
          sesi_id: row.sesi_id
        })
      })
      this.$store.commit('offer/setSchedule', {
        skills: skills,
        schedules: schedules
      })
    },
    createProjectConfirm () {
      try {
        if (this.newPenawaran === undefined) { return }

        if (this.newPenawaran.customer_name.trim() === '' ||
          this.newPenawaran.jenis_bangunan.trim() === '' ||
          this.newPenawaran.detail_sesi.length === 0 ||
          this.newPenawaran.address.trim() === '' ||
          this.skillsSelected.length === 0 ||
          this.deskripsiPekerjaan === '') {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Mohon lengkapi dulu data yang harus diinput',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }

        const n = this.hitungAmount()
        this.$store.commit('offer/setAmount', {
          amount: n.amount,
          net_amount: n.amount,
          fee_building: n.fee_building
        })

        this.setItem()
        this.setSchedule()
        this.setInfoDate()
        this.$store.commit('offer/setInfo', {
          idtransaction_item: 0,
          user_id: this.user.id,
          transaction_id: uuidv4()
        })

        // check saldo before create
        const amountsAll = this.newPenawaran.total_harga_keseluruhan
        if (this.userSaldo <= 0 || this.userSaldo < amountsAll) {
          this.modal.isSaldoTidakCukup = true
          return
        }

        axios.post(`${this.url}/api/v1/vendor/price_calculation`, this.newPenawaran)
          .then((dt) => {
            this.dataCalculation = dt.data.data
            this.viewState = !this.viewState
          })
          .catch((err) => {
            throw new Error(err.response.data.message)
          })

        // this.viewState = !this.viewState
      } catch (error) {
        throw new Error(error)
      }
    }
  },
  created () {
    this.loadBuildingType()
    this.loadSkillList()
    this.$store.dispatch('offer/setNewPenawaran')
    this.minDateOrder = moment().add(1, 'days').toDate()
    const now = moment()
    if (now.hour() >= 18) {
      this.minDateOrder = moment().add(2, 'days').toDate()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-card {
  padding: 20px 25px 20px 25px;
  margin-bottom: 20px;

  background: rgba(255, 255, 255, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.65);
}
.kang-label {
  margin-top: 20px;
}
.kang-val {
  margin-top: 5px;
}
.jenis-bangunan {
  cursor: pointer;
  &:hover {
    background-color: rgb(196, 196, 196);
  }
}
.jenis-bangunan-selected {
  background-color: rgb(196, 196, 196);
}

.modal {
  overflow: auto;
}
.dropdown-box {
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 2px;
  div, button {
    width: 100%;
    text-align: left;
  }
}
</style>
