
const state = () => ({
  url: null,
  userSaldo: 0
})

const getters = {
  getUrl: (state, getters, rootState) => {
    return state.url
  },
  getUserSaldo: (state) => {
    return state.userSaldo
  }
}

const actions = {}

const mutations = {
  setUrl (state, data) {
    state.url = data
  },
  setUserSaldo (state, data) {
    state.userSaldo = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
