<template>
  <div>
    <div class="columns is-vcentered mt-2">
      <div class="column is-two-thirds">
        <h1 class="title is-5 ml-5">Daftar User</h1>
      </div>
      <div class="columns is-gapless">
        <div class="column is-two-thirds">
          <b-field>
            <b-input style="width: 250px; margin-right: 20px;" placeholder="Cari User"
              v-model="search"
              type="search"
              icon="magnify"
              icon-clickable
              @keyup.native.enter="cariUser"
              @icon-click="cariUser">
            </b-input>
          </b-field>
        </div>
        <div class="column">
          <b-button @click="addUser()" type="is-success" icon-left="plus">Tambah User</b-button>
        </div>
      </div>
    </div>

    <!-- table list user with role -->
    <div>
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="name" label="Nama" v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column field="email" label="Email" v-slot="props">
          {{ props.row.email }}
        </b-table-column>
        <b-table-column field="jabatan" label="Jabatan" v-slot="props">
          {{ props.row.jabatan }}
        </b-table-column>
        <b-table-column field="act" label="Action" v-slot="props">
          <b-button icon-left="pencil" @click="setEdit(props.row)" type="is-success" /> &nbsp;
          <b-button icon-left="key" @click="setPass(props.row)" type="is-primary" /> &nbsp;
          <b-button icon-left="delete" @click="deleteUser(props.row)" type="is-danger" />
        </b-table-column>
      </b-table>
    </div>

    <!-- modal add user with role -->
    <b-modal
      v-model="modalTambahUser"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card" style="width: 400px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Tambah User</p>
            <button type="button" class="delete" @click="modalTambahUser = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama">
              <b-input v-model="name" placeholder="Nama" required></b-input>
            </b-field>
            <b-field label="Email">
              <b-input type="email" v-model="email" placeholder="Email" required></b-input>
            </b-field>
            <div>
              <b-field label="Password">
                <b-input v-model="password" placeholder="Password" type="password" required></b-input>
              </b-field>
              <b-field label="Confirm Password">
                <b-input type="password" v-model="confirmPassword" placeholder="Confirm Password" required></b-input>
              </b-field>
            </div>
            <b-field label="Jabatan">
              <b-select v-model="jabatanSelected" placeholder="Select jabatan">
                <option
                  v-for="option in roles"
                  :value="option"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="saveUser()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

    <!-- modal edit user with role -->
    <b-modal
      v-model="newIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card" style="width: 400px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Edit Profil</p>
            <button type="button" class="delete" @click="newIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama">
              <b-input v-model="name" placeholder="Nama" required></b-input>
            </b-field>
            <b-field label="Email">
              <b-input type="email" v-model="email" placeholder="Email" required></b-input>
            </b-field>
            <div v-if="!isEdit">
              <b-field label="Password">
                <b-input v-model="password" placeholder="Password" type="password" required></b-input>
              </b-field>
              <b-field label="Confirm Password">
                <b-input v-model="confirmPassword" type="password" placeholder="Confirm Password" required>
                </b-input>
              </b-field>
            </div>
            <b-field label="Jabatan">
              <b-select v-model="jabatanSelected" placeholder="Select jabatan">
                <option
                  v-for="option in roles"
                  :value="option"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="updateUser()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

    <!-- modal edit password user -->
    <b-modal
      v-model="modalEditPass"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card" style="width: 400px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Edit Password</p>
            <button type="button" class="delete" @click="modalEditPass = false"/>
          </header>
          <section class="modal-card-body">
            <div>
              <b-field label="Password">
                <b-input v-model="password" placeholder="Password" type="password" required></b-input>
              </b-field>
              <b-field label="Confirm Password">
                <b-input v-model="confirmPassword" type="password" placeholder="Confirm Password" required></b-input>
              </b-field>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="editPassword()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanUser',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  components: {},
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.loadData(1)
      }
    }
  },
  methods: {
    cariUser () {
      if (this.search === null) { return }
      this.loading = true
      axios.get(this.url + '/api/v1/vendor/user-search?search=' + this.search)
        .then((data) => {
          this.paginate.data = data.data.data
          this.paginate.total = 1
          this.paginate.page = 1
          this.paginate.loading = false
        })
        .catch(() => {
          this.paginate.loading = false
        })
    },
    clearInput () {
      this.name = null
      this.name = null
      this.email = null
      this.password = null
      this.confirmPassword = null
      this.jabatanSelected = null
    },
    deleteUser (val) {
      this.userSelected = val
      this.$buefy.dialog.confirm({
        message: 'Hapus user : ' + this.userSelected.name,
        onConfirm: () => {
          axios.delete(this.url + '/api/v1/vendor/user/' + this.userSelected.id + '/delete')
            .then(() => {
              this.loadData(1)
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Hapus user berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus user',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    saveUser () {
      let data = null
      let url = null
      if (!this.isEdit) {
        if (this.name === null || this.name === '' || this.email === null || this.email === '' ||
            this.password === null || this.password === '' || this.confirmPassword === null ||
            this.confirmPassword === '' || this.jabatanSelected === null) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'data tidak boleh kosong..',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }
        if (this.password !== this.confirmPassword) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Confirm password belum sama!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }
        data = {
          name: this.name,
          email: this.email,
          password: this.password,
          confirm_pass: this.confirmPassword,
          role_id: this.jabatanSelected.id
        }
        url = this.url + '/api/v1/vendor/user'
        this.isLoading = true
        axios.post(url, data)
          .then(() => {
            this.loadData(1)
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Save user berhasil!...',
              position: 'is-bottom',
              type: 'is-info'
            })
            this.clearInput()
            this.modalTambahUser = false
            this.isLoading = false
          })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error save',
              position: 'is-bottom',
              type: 'is-danger'
            })
            this.isLoading = false
            this.clearInput()
          })
      }
    },
    updateUser () {
      let data = null
      let url = null
      if (this.isEdit) {
        if (this.name === null || this.name === '' || this.email === null || this.email === '' || this.jabatanSelected === null) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'data tidak boleh kosong..',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }

        data = {
          user_id: this.user_id,
          name: this.name,
          email: this.email,
          role_id: this.jabatanSelected.id
        }

        this.isLoading = true
        url = this.url + '/api/v1/vendor/user/edit'
        axios.put(url, data)
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Save user berhasil!...',
              position: 'is-bottom',
              type: 'is-info'
            })
            this.loadData(1)
            this.clearInput()
            this.newIsShow = false
            this.isLoading = false
          })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error save',
              position: 'is-bottom',
              type: 'is-danger'
            })
            this.isLoading = false
          })
      }
    },
    editPassword () {
      let data = null
      let url = null
      const userId = this.user_id
      if (this.password === null || this.password === '' || this.confirmPassword === null || this.confirmPassword === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'data tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      if (this.password !== this.confirmPassword) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'confirm password belum sama!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      data = {
        password: this.password,
        confirm_pass: this.confirmPassword
      }
      url = this.url + '/api/v1/vendor/user/' + userId + '/change-password'
      this.isLoading = true
      axios.put(url, data)
        .then(() => {
          this.loadData(1)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Save user password berhasil!',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearInput()
          this.modalEditPass = false
          this.isLoading = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
          this.clearInput()
        })
    },
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      this.loadData(v)
    },
    loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const url = this.url + '/api/v1/vendor/user?page=' + this.paginate.page + '&limit=' + this.paginate.perPage
      axios.get(url)
        .then((data) => {
          this.paginate.data = data.data.data
          this.paginate.total = (data.data.total_page * this.paginate.perPage)
          this.paginate.page = data.data.current_page
          this.paginate.loading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    roleUser () {
      axios.get(this.url + '/api/v1/vendor/role')
        .then((cb) => {
          this.roles = cb.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setEdit (val) {
      this.isEdit = true
      this.user_id = val.id
      this.name = val.name
      this.email = val.email
      this.jabatanSelected = null
      this.roles.forEach((row) => {
        if (row.name === val.jabatan) {
          this.jabatanSelected = row
        }
      })
      this.newIsShow = true
    },
    setPass (val) {
      this.isEditPass = true
      this.user_id = val.id
      this.password = val.password
      this.modalEditPass = true
    },
    addUser () {
      this.modalTambahUser = true
      this.clearInput()
    }
  },
  data () {
    return {
      search: null,
      modalTambahUser: false,
      newIsShow: false,
      modalEditPass: false,
      isLoading: false,
      isEdit: false,
      isEditPass: false,
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      jabatanSelected: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      },
      roles: []
    }
  },
  created () {
    this.loadData(1)
    this.roleUser()
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
