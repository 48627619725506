<template>
  <div>
    <div class="top-info">
      <section style="margin-left: 12px; margin-bottom: 10px; margin-top: 5px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item"></div>
          </div>
          <div class="level-right">
            <div class="level-item" v-bind:class="buttonExportInv()">
              <!-- <download-excel
                :data="jsonSthatam"
                :name="fileName">
                <b-button icon-left="file-chart" outlined type="is-success">Export Invoice</b-button>
              </download-excel> -->
              <a class="button is-success" icon-left="file-chart" outlined :href="this.linkInvoice">Export Invoice</a>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div style="padding: 20px 40px 20px 40px;">
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="jenis" label="Project ID" v-slot="props">
          {{ props.row.jenis }}
        </b-table-column>
        <b-table-column field="penanggung_jawab" label="Penanggung Jawab" v-slot="props">
          {{ props.row.penanggung_jawab }}
        </b-table-column>
        <b-table-column field="consumer_name" label="Nama Customer" v-slot="props">
          {{ props.row.consumer_name }}
        </b-table-column>
        <b-table-column field="sesi" label="Sesi" v-slot="props">
          <div class="mb-2" v-for="(items, key) in props.row.sesi" :key="key">
            <ul>
              <li> <b> Sesi </b> : {{ items.name }} </li>
              <li> <b> Harga Sesi </b> : {{ items.price | numFormat }}</li>
              <li> <b> Hour </b> : {{ items.start_hour }} - {{ items.end_hour }}</li>
            </ul>
          </div>
        </b-table-column>
        <b-table-column field="price" label="Tanggal Pengerjaan" v-slot="props">
          {{ props.row.start_date }}
        </b-table-column>
        <b-table-column field="type_building" label="Fee Charge Building" v-slot="props">
          {{ props.row.fee_charge_building | numFormat }}
        </b-table-column>
        <!-- <b-table-column field="total_price" label="Harga Project">
          <div class="mb-2" v-for="(items, keys) in props.row.sesi" :key="keys">
            <ul>
              <li> <b> Harga Sesi </b> : {{ items.price | numFormat }}</li>
            </ul>
          </div>
        </b-table-column> -->
        <b-table-column field="pph" label="Nominal Pph" v-slot="props">
          {{ props.row.pph | numFormat }}
        </b-table-column>
        <b-table-column field="total_biaya" label="Total Biaya" v-slot="props">
          {{ parseInt(props.row.total_price) - parseInt(props.row.pph) | numFormat }}
        </b-table-column>
        <b-table-column field="export_pdf" label="Action" v-slot="props">
          <b-button type="is-info" @click="viewmodal(props.row.project_id)">Preview PDF</b-button>
        </b-table-column>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="paginate.loading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large"/>
              </p>
              <p>Nothing's here&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </div>

    <div>
      <b-modal v-model="modalPreviewPdf" trap-focus aria-modal no-close-on-backdrop>
        <div class="card">
          <pdf-invoice-print :project="this.projectId"></pdf-invoice-print>
        </div>
      </b-modal>
    </div>

  </div>

</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'
import PdfInvoicePrint from './InvoicePdf.vue'

export default {
  name: 'laporanInvoiceProyek',
  components: {
    PdfInvoicePrint
  },
  props: {
    date: Object
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
        this.exportToExcel(newVal, 1)
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    sumOfPriceSesi () {
      for (var i = 0; i > this.paginate.data.length; i++) {
        console.log(this.sesi)
      }
      return this.sesi
    }
  },
  methods: {
    buttonExportInv () {
      if (this.date !== null) {
        return 'is-show'
      }

      return 'is-hidden'
    },
    previewReport (valId) {
      this.viewmodal(valId)
    },
    viewmodal (valId) {
      if (valId !== null) {
        this.modalPreviewPdf = !this.modalPreviewPdf
        this.projectId = valId
      }
    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (page) {
      this.getData(this.date, page)
    },
    convDate (val) {
      return moment(val).format('DD MMMM YYYY hh:mm')
    },
    getData (val, page) {
      this.paginate.loading = true
      this.paginate.page = page
      const pr = '/api/v1/vendor/report/invoice-project?start_date=' + val.tgl1 + '&end_date=' + val.tgl2 + '&limit=' + this.paginate.perPage + '&page=' + this.paginate.page
      axios.get(this.url + pr)
        .then((cb) => {
          const arrSesi = []
          // var totalPrice = 0
          cb.data.data.list.forEach((item, index) => {
            arrSesi.push(item)
          })
          this.paginate.data = arrSesi
          this.paginate.total = (cb.data.data.total_page * this.paginate.perPage)
          this.paginate.page = cb.data.data.current_page
          this.paginate.loading = false
        })
    },
    exportToExcel (val) {
      const endpoint = '/api/v1/vendor/export/invoice-project?start_date=' + val.tgl1 + '&end_date=' + val.tgl2
      axios({
        url: this.url + endpoint,
        method: 'GET'
      }).then((cb) => {
        this.linkInvoice = this.url + '/' + cb.data.path
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  data () {
    return {
      projectId: null,
      modalPreviewPdf: false,
      linkInvoice: '',
      fileName: 'invoice-project' + this.date,
      activeTab: 0,
      cabangSelected: null,
      dateRange: [],
      infoState: '',
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      }
    }
  },
  created () {},
  mounted () {}
}
</script>

<style lang="scss" scoped>
.top-info {
  border-bottom: solid thin #f0f0f0;
}
.is-hidden {
  visibility: hidden;
}
.is-show {
  visibility: visible;
}
</style>
