<template>
  <section>
    <div class="container is-max-desktop">
      <div class="level" style="margin-top: 20px;">
        <div class="level-left">
          <b-button @click="kembaliKeProyek()" type="is-light" size="is-small" icon-left="arrow-left"></b-button>
          <h1 class="subtitle is-6" style="margin-top: 5px; margin-left: 10px;"> Kembali ke proyek </h1>
        </div>
        <div class="level-right">
          <div class="title is-4 has-text-weight-light">Buat Proyek</div>
        </div>
      </div>
    </div>
    <div style="border-bottom: solid thin #f0f0f0; width: 100%; height: 20px; margin-bottom: 20px;"></div>
    <transition name="fade">
      <div v-show="!viewState" class="container is-max-desktop">
        <!-- Card Pilih Customer -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="has-text-weight-bold">Pilih Customer</div>
          <notification class="is-info mt-2">
            <span class="has-text-weight-bold has-text-white">Jika sudah menentukan Customer,
              infokan jadwal tukang yang akan datang ke Customer yang telah dipilih!
            </span>
          </notification>
          <div class="columns" v-if="newProject && newProject.customer_name" style="padding-top: 10px;">
            <div class="column is-four-fifths">
              <h1 class="title is-5 mt-2"> {{ newProject.customer_name }} ( {{ newProject.cell_phone }} ) </h1>
            </div>
            <div class="column">
              <b-button type="is-success" icon-left="account" @click="modal.customerIsView=true">Ubah</b-button>
            </div>
          </div>
          <div style="text-align: center; margin-top: 30px;">
            <b-button v-if='!newProject || !newProject.customer_name' @click="modal.customerIsView=true" type="is-success" style="width: 300px;" icon-left="account-alert-outline">
              Tentukan Client
            </b-button>
          </div>
        </div>
        <!-- Card Pilih Mitra -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="has-text-weight-bold">Pilih Tukang</div>
          <div class="dropdown-box">
            <b-dropdown
              append-to-body
              scrollable
              max-height="300"
              expanded
              @change="skillIsSelected"
              v-model="currentSkill"
              aria-role="list">
              <template #trigger>
                <b-button
                  label="Pilih tukang yang anda butuhkan"
                  type="is-light"
                  icon-right="menu-down" />
              </template>
              <b-dropdown-item
                v-for="(menu, index) in skills"
                :key="index"
                :value="menu" aria-role="listitem">
                <div class="media">
                  <img :src="menu.image_uri" class="media-left" style="width: 50px;" />
                  <div class="media-content">
                    <h3>{{menu.name}}</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="m-1">
            <div v-for="(lay, idx) in skillsSelected" :key="lay.id">
              <div class="columns is-mobile mt-3">
                <div class="column is-one-third-mobile">
                  <div style="display: flex;">
                    <div>
                      <img :src="lay.image_uri" style="max-width: 2rem;" />
                    </div>
                    <div class="m-2">
                      {{ lay.name }}
                    </div>
                  </div>
                </div>
                <div class="column is-half-mobile">
                  <b-field class="has-text-centered">
                    <b-numberinput
                      :min="1"
                      v-model="lay.tukang"
                      type="is-primary"
                      controls-rounded expanded>
                    </b-numberinput>
                  </b-field>
                </div>
                <div class="column">
                  <div class="has-text-right">
                    <b-button @click="deleteSkill(idx)" type="is-danger" icon-left="delete" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <!-- Card Deskripsi Pekerjaan -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="has-text-weight-bold"> Deskripsi Pekerjaan </div>
          <div style="margin-top: 25px;">
            <b-input v-model="deskripsiPekerjaan" rows="2" maxlength="200" type="textarea"></b-input>
          </div>
        </div>
        <!-- Card Pilih Alamat -->
        <div class="input-card" style="margin-top: 20px;">
          <div style="display: flex;">
            <div style="width: 50%;" class="has-text-weight-bold"> Alamat </div>
            <div style="width: 50%; text-align: right;" v-if="newProject && newProject.address">
              <div class="has-text-right" style="padding-top: 10px; color: blue;"> Klik disini untuk edit </div>
            </div>
          </div>
          <div style="display: flex;" v-if="newProject && newProject.address">
            <div style="padding-top: 20px; width: 100%;">
              <div style="margin-right: 20px;" v-if="newProject && newProject.latitude && newProject.longitude">
                <GmapMap map-type-id="roadmap" :zoom="15"
                  :center="map.currentPlace"
                  :options="{disableDefaultUI: true}"
                  style="width: 100%; height: 200px;">
                  <GmapMarker :position="map.currentPlace">
                  </GmapMarker>
                </GmapMap>
              </div>
              <div style="padding: 20px 20px 0px 0px;">
                {{ newProject.address }}
                <br/>
                Bangunan: <strong>{{ newProject.jenis_bangunan }}</strong>
              </div>
            </div>
            <div style="width: 220px; padding-top: 20px;">
              <b-button class="button is-info is-outlined" @click="modal.tentukanAlamatIsView=true" style="width: 200px;">Ubah</b-button>
            </div>
          </div>
          <div style="text-align: center; margin-top: 40px;">
            <b-button v-if="!newProject || !newProject.address" @click="modal.tentukanAlamatIsView=true"
              type="is-success" style="width: 300px;" icon-left="map-marker"> Tentukan Alamat </b-button>
          </div>
        </div>
        <!-- Card Pilih Jadwal -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="has-text-weight-bold">Jadwal</div>
          <div class="level" v-if="newProject && newProject.detail_sesi.length > 0">
            <div class="level-left">
              <table class="table" style="margin-top: 20px;">
                <tbody>
                  <tr style="color: grey;" v-for="(v, i) in newProject.detail_sesi" :key="i">
                    <td>{{ v.day }}</td>
                    <td>{{ v.skillsName }}</td>
                    <td>{{ convDate(v.date) }}</td>
                    <td>{{ v.start_hour }} - {{ v.end_hour }}</td>
                    <td>Rp.{{ v.price | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="level-right">
              <b-button @click="tentukanJadwalOnShow()" style="width: 200px;">Ubah</b-button>
            </div>
          </div>
          <div v-if="newProject && newProject.detail_sesi.length === 0" style="text-align: center; margin-top: 40px;">
            <b-button @click="tentukanJadwalOnShow()" type="is-success" style="width: 300px;" icon-left="calendar-multiselect">
              Tentukan Jadwal
            </b-button>
          </div>
        </div>
        <div style="text-align: center;">
          <b-icon style="color: whitesmoke;" size="is-medium" icon="arrow-down-thin-circle-outline"></b-icon>
        </div>
        <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
          <b-button
            @click.prevent="createProjectConfirm()"
            type="is-primary"
            style="width: 300px;"
            icon-right="arrow-right">
              Lanjutkan
          </b-button>
        </div>
      </div>
    </transition>
    <!-- pilih PIC -->
    <b-modal :width="500" v-model="modal.customerIsView">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head" style="border-bottom: 0">
          <p class="modal-card-title" style="text-align: center; font-weight: 600;">Tentukan Client</p>
          <button type="button" class="delete" @click="modal.customerIsView = false"/>
        </header>
        <section class="modal-card-body">
          <project-customer @catchvalue="insertCustomer" />
        </section>
      </div>
    </b-modal>
    <!-- pilih alamat pengerjaan -->
    <b-modal v-model="modal.tentukanAlamatIsView" :width="630" scroll="keep">
      <div class="card">
        <div class="card-content">
          <b-field label="Lokasi di Peta">
            <div style="display: flex;">
              <div v-if="map.currentPlace.lat && map.mapSelectedIsShow">
                <GmapMap map-type-id="roadmap" :zoom="15"
                  :center="map.currentPlace"
                  :options="{disableDefaultUI: true}"
                  style="width: 450px; height: 150px">
                  <GmapMarker :position="map.currentPlace">
                  </GmapMarker>
                </GmapMap>
              </div>
              <div class="ml-1">
                <b-button @click="mapOnViewClick()" type="is-success" icon-left="map-marker-plus"> Pilih Lokasi </b-button>
              </div>
            </div>
          </b-field>
          <b-field label="Alamat">
            <b-input disabled v-model="modal.addressRef" rows="2" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Deskripsi Lokasi">
            <b-input v-model="modal.address" rows="2" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Jenis Bangunan">
            <div class="columns is-gapless">
              <div v-for="(v, i) in buildingType" :key="i" class="column">
                <div @click="setBuildingType(i)" :class="{'jenis-bangunan-selected': v.isSelect === true}" class="card jenis-bangunan">
                  <div class="card-content justify-center">
                    <img class="image is-32x32 justify-center" :src="v.image_uri" />
                    {{ v.name }} <br/>
                    <small>Rp.{{ v.fee_charge | numFormat }}</small>
                  </div>
                </div>
              </div>
            </div>
          </b-field>
          <div class="content" style="text-align: right; margin-top: 15px;">
            <b-button @click="insertAddress()" style="width: 200px;" :type="{'is-light': modal.address === '' && map.currentPlace.lat === null, 'is-success' : modal.address !== '' && map.currentPlace.lat !== null }">
              Simpan
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- maps -->
    <b-modal v-model="modal.isMapOnView">
      <div class="card">
        <div class="card-content">
          <b-field label="Cari Alamat">
            <b-autocomplete
              :data="map.address"
              placeholder="Telusuri Alamat"
              field="title"
              :loading="map.isFetchingAddress"
              @typing="getAddress"
              @select="option => map.addressSelected = option">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small> {{ props.option.address }} </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <GmapMap map-type-id="roadmap" :zoom="15"
            :center="map.gmapCenter"
            style="width: auto; height: 400px">
            <GmapInfoWindow :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
              <div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>
            </GmapInfoWindow>
            <GmapMarker :position="map.markerPosition" :clickable="true"
              @dragend="updateCoordinate"
              @click="mapMarkerOnClick()"
              :draggable="true">
            </GmapMarker>
          </GmapMap>
        </div>
      </div>
    </b-modal>
    <!-- pilih tanggal pengerjaan -->
    <b-modal v-model="modal.isTentukanJadwal" :width="640" scroll="keep">
      <div class="card">
        <div style="border-bottom: solid thin whitesmoke; height: 60px;">
          <p class="title is-5" style="text-align: center; margin-bottom: 0px; margin-top: 60px;">Tentukan Jadwal Pengerjaan</p>
        </div>
        <div class="card-content">
          <div class="content">
            <v-date-picker
              :min-date='minDateOrder'
              @input="onDateRangeChange" is-range :columns="modal.dateColumn" value="range" is-expanded :attributes="dateAttributes" />
          </div>
          <div class="content" style="text-align: right;">
            <b-button @click="inputSesi()" style="width: 200px;" :type="{'is-light' : days.length === 0, 'is-success' : days.length > 1 }">Simpan</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- alert jika saldo tidak cukup -->
    <b-modal v-model="modal.isSaldoTidakCukup" :width="450">
      <div class="card">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Informasi</h1>
        </div>
        <div class="card-content" style="text-align: center;">
          <img style="width: 150px;" src="../../assets/saldo-tidak-cukup.png" />
          <div style="font-weight: bold;"> Saldo tidak mencukupi! </div>
          <div> Silakan top up saldo terlebih dahulu, kemudian buat proyek kembali</div>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <b-button type="is-danger" @click="modal.isSaldoTidakCukup = false" style="width: 200px;">Kembali</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- pilih sesi pengerjaan -->
    <b-modal v-model="modal.isSesi" :width="1100" scroll="keep">
      <div class="card" style="background-color: #ffffff;">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Sesi Pengerjaan</h1>
        </div>
        <div class="card-content">
          <notification class="is-info">
            <span class="has-text-weight-bold has-text-white">
              Pilih salah satu sesi, pada Tukang dan tanggal yang telah dipilih!
            </span>
          </notification>
          <div class="table-container">
            <table class="table is-narrow is-bordered is-mobile" style="width: 100%;">
              <thead class="is-title-5">
                <tr>
                  <th>Tanggal Pengerjaan</th>
                  <th colspan="1">Penugasan Tukang & Sesi</th>
                  <th class="has-text-right">
                    <b-button class="is-warning" @click="uncheckedAll()">
                      All Unchecked
                    </b-button>
                  </th>
                </tr>
              </thead>
              <tbody v-for="(value, index) in filter" v-bind:key="index">
                <tr style="padding-bottom: 10%">
                  <td class="is-size-6 has-text-weight-bold p-6">{{ convDate(value.id) }}</td>
                  <td class="table-container" colspan="2">
                    <table class="is-mobile">
                      <tbody>
                        <tr v-for="(nnn, i) in value.detail" :key="'A' +i">
                          <td class="has-text-weight-bold">{{ nnn.skillsName }}</td>
                          <td>{{ nnn.sesi }}</td>
                          <td>Rp.{{ nnn.price | numFormat }}</td>
                          <td>{{ nnn.start_hour }} - {{ nnn.end_hour }}</td>
                          <td>
                            <b-checkbox
                              class="is-center"
                              v-bind:value="nnn"
                              v-model.number="sessionSel"
                              :native-value="nnn"
                              :disabled="nnn.disabled"
                              @input="compareData(value.id, nnn.skillId)"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="columns is-mobile m-3">
          <div class="column is-4 is-offset-8">
            <b-button @click="buildSesi()" type="is-primary" style="width: 100%;">Simpan</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <transition name="fade">
      <div v-show="viewState" class="input-card container is-max-desktop">
        <div class="level" style="margin-bottom: 40px;">
          <div class="level-center">
            <h1 class="title is-4">Konfirmasi Proyek</h1>
          </div>
        </div>
        <div>
          <div class="kang-label"> Client </div>
          <div class="kang-val">
            <h1 class="title is-5">{{ newProject.customer_name }}</h1>
          </div>
        </div>
        <div class="input-card" style="margin-top: 20px;">
          <div style="margin-bottom: 10px;"> Penugasan Tukang </div>
          <div v-if="skillsSelected.length > 0">
            <div v-for="(v, idx) in skillsSelected" :key="'_idxx' + idx"
              style="display: flex; height: auto; border-bottom: solid thin #f0f0f0; padding: 10px; margin-bottom: 10px;">
              <div style="width: 80px; text-align: center;">
                <img style="width: 50px; height: 50px; border-radius: 5px;" :src="v.image_uri" />
              </div>
              <div style="width: 100%;">
                <p class="title is-6 mt-2">{{ v.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="kang-label"> Alamat Pengerjaan </div>
          <div class="kang-val">
            <h1 class="title is-5 is-size-6-mobile">{{ newProject.address }}</h1>
            <div v-if="newProject && newProject.jenis_bangunan">
              Tipe Bangunan: <span class="has-text-weight-bold is-size-5 is-size-6-mobile">{{ newProject.jenis_bangunan }}</span>
            </div>
          </div>
          <div v-if="newProject && newProject.address">
            <div style="padding-top: 20px; padding-bottom: 20px;">
              <div v-if="newProject && newProject.latitude && newProject.longitude">
                <GmapMap map-type-id="roadmap" :zoom="15"
                  :center="map.currentPlace"
                  :options="{disableDefaultUI: true}"
                  style="width: 100%; height: 200px;">
                  <GmapMarker :position="map.currentPlace">
                  </GmapMarker>
                </GmapMap>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="kang-label"> Deskripsi Pekerjaan</div>
          <div class="kang-val">
            <h1 class="title is-5">{{ newProject.description }}</h1>
          </div>
        </div>
        <div>
          <div class="kang-label">Rincian Biaya</div>
          <div style="margin-top: 10px;" v-if="newProject && newProject.detail_sesi.length > 0">
            <div class="table-container">
              <table class="table" style="max-width: 100%;">
                <thead>
                  <tr>
                    <td class="has-text-weight-bold">Tanggal</td>
                    <td class="has-text-weight-bold">Sesi</td>
                    <td class="has-text-weight-bold">Harga Sesi</td>
                    <td class="has-text-weight-bold">Total Tukang</td>
                    <td class="has-text-weight-bold">Jumlah</td>
                  </tr>
                </thead>
                <tbody v-if="dataCalculation !== null">
                  <tr v-for="(vv, ii) in dataCalculation.details" :key="ii">
                    <td>{{ convDate(vv.date) }}</td>
                    <td>{{ vv.sesi }} - ({{ vv.start_hour }} - {{ vv.end_hour }})</td>
                    <td>Rp.{{ vv.price | numFormat }}</td>
                    <td class="has-text-centered">{{ vv.workerCount }}</td>
                    <td>Rp.{{ (vv.price * vv.workerCount) | numFormat }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="has-text-right has-text-weight-bold">Sub Total</td>
                    <td>Rp.{{ dataCalculation.amount | numFormat}}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="has-text-right has-text-weight-bold">Pph(2%)</td>
                    <td>Rp.{{ dataCalculation.pph | numFormat}}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="has-text-right has-text-weight-bold">Total Setelah Pph</td>
                    <td>Rp.{{ dataCalculation.amount_after_tax | numFormat}}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="has-text-right has-text-weight-bold">Biaya Parkir</td>
                    <td>Rp.{{ dataCalculation.fee_building | numFormat }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="has-text-right has-text-weight-bold">Total</td>
                    <td>Rp.{{ dataCalculation.net_amount | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="dataCalculation !== null">
          <div class="kang-label"> Total Biaya </div>
          <div style="margin-top: 10px;">
            <div class="title is-5">
              Rp.{{ dataCalculation.net_amount | numFormat }}
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
          <b-button @click.prevent="createProject()" type="is-primary" style="width: 300px;"> Buat Proyek </b-button>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
// import guard from '../../routerGuard'
import ProjectCustomer from './Customer.vue'
import Notification from '@/components/Notification.vue'

export default {
  name: 'ProjectInput',
  components: {
    ProjectCustomer,
    Notification
  },
  data () {
    return {
      sessionSel: [],
      days: [],
      idtransaction_item: null,
      viewState: false,
      modal: {
        customerIsView: false,
        pilihProductIsView: false,
        tentukanAlamatIsView: false,
        isMapOnView: false,
        isTentukanJadwal: false,
        dateColumn: 2,
        isPenugasanTukangIsView: false,
        transactionProducts: [],
        isSesi: false,
        address: '',
        addressRef: '',
        listProductIsView: false,
        isSaldoTidakCukup: false
      },
      fetchProductIsAuto: false,
      productToDo: [],
      productServiceRelated: [],
      productServiceSelected: [],
      pilihProductOnEdit: false,
      pilihProductEditIndex: -1,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      },
      deskripsiPekerjaan: '',
      buildingType: [],
      isCustomerNew: false,
      project: null,
      minDateOrder: null,
      skills: [],
      currentSkill: null,
      skillsSelected: [],
      sesiSkills: [],
      dataCalculation: null,
      // paymentData: null,
      pembayaranProjectIsShow: false
    }
  },
  watch: {
    deskripsiPekerjaan (newVal, oldVal) {
      this.$store.commit('project/setDescription', newVal.trim())
    },
    'map.addressSelected' (newVal, oldVal) {
      this.refreshAllCoordinate(newVal)
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      url: 'setup/getUrl',
      dateSesi: 'project/getSesi',
      newProject: 'project/getNewProject',
      userSaldo: 'setup/getUserSaldo'
    }),
    dates () {
      return this.days.map(day => day.date)
    },
    dateAttributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    filter () {
      const data = [...new Map(this.listSesi.map(item => [item.id, item])).values()]
      return data
    },
    listSesi () {
      var x = []
      const sesi = []

      if (this.days.length >= 2) {
        const start = moment(this.days[0].date)
        const end = moment(this.days[1].date)

        for (var m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
          const _id = moment(m).format('YYYY-MM-DD')
          x.push({ id: _id, date: m.valueOf() })
        }
        x.forEach((v) => {
          const day = {
            id: v.id,
            selected: [],
            detail: [],
            name: []
          }
          this.skillsSelected.forEach((s) => {
            const _day = moment(v.date).weekday()
            const val = 'Minggu,Senin,Selasa,Rabu,Kamis,Jumat,Sabtu'.split(',')

            day.name.push({
              nameSkill: s.name
            })

            s.sesi.forEach((t) => {
              day.detail.push({
                date: v.id,
                sesi_id: t.id,
                skillId: s.id,
                skillsName: s.name,
                sesi: t.name,
                sesi_homecare: t.sesi_homecare,
                start_hour: t.start_hour,
                end_hour: t.end_hour,
                price: t.price,
                workerCount: s.tukang,
                day: val[_day],
                disabled: false
              })
            })
          })
          sesi.push(day)
        })
        return sesi
      } else {
        return []
      }
    },
    hasDataProduct () {
      if (_.has(this.newProject, 'transactionProduct.transaction')) {
        if (this.newProject && this.newProject.transactionProduct.transaction !== null) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    totalTukangSelected () {
      let total = 0
      this.productServiceSelected.forEach((row) => {
        row.skills.forEach((_row) => {
          total += _row.order_tukang
        })
      })
      return total
    }
  },
  methods: {
    async loadSesiSkills () {
      try {
        const getData = await axios.get(`${this.url}/api/v1/vendor/sesi?page=1&limit=1000`)
        const responseValidate = getData.data.code === 1 ? this.sesiSkills = getData.data.data : this.sesiSkills = []
        return responseValidate
      } catch (error) {
        throw new Error(error)
      }
    },
    async loadSkillList () {
      try {
        const getDataSkill = await axios.get(`${this.url}/api/v1/vendor/skill?page=1&limit=1000`)
        getDataSkill.data.data.forEach((item) => {
          if (item.name === 'Konsultan') {
            const data = {
              id: item.id,
              name: item.name,
              image_uri: item.image_uri,
              sesi: item.sesi
            }
            this.skills.push(data)
          }
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async loadBuildingType () {
      try {
        const response = await axios.get(`${this.url}/api/v1/vendor/building_type`)
        const dataBuildingType = []
        response.data.data.forEach((rw) => {
          const r = rw
          r.isSelect = false
          dataBuildingType.push(r)
        })
        this.buildingType = dataBuildingType
      } catch (error) {
        throw new Error(error)
      }
    },
    async getAddress (val) {
      try {
        const response = await axios.get(`${this.url}/api/v1/vendor/map?place=${val}`)
        const validate = response.data.data.length > 0 ? this.map.address = response.data.data : this.map.address = null
        return validate
      } catch (error) {
        throw new Error(error)
      }
    },
    close () {
      this.pembayaranProjectIsShow = false
      if (this.pembayaranProjectIsShow === false) {
        this.$router.push('/')
      }
    },
    skillIsSelected (val) {
      const sel = JSON.parse(JSON.stringify(val))
      /** check skill is selected */
      let hasSelected = false
      this.skillsSelected.forEach((rw) => {
        if (rw.id === sel.id) {
          hasSelected = true
        }
      })
      if (hasSelected === false) {
        sel.tukang = 1
        this.skillsSelected.push(sel)
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${sel.name} sudah pernah dipilih!`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    deleteSkill (idx) {
      this.skillsSelected.splice(idx, 1)
    },
    topupSaldo () {
      this.modal.isSaldoTidakCukup = false
    },
    setBuildingType (idx) {
      this.buildingType.forEach((rw, _idx) => {
        if (idx === _idx) {
          this.buildingType[_idx].isSelect = !this.buildingType[_idx].isSelect
        } else {
          this.buildingType[_idx].isSelect = false
        }
      })
    },
    simpanLokasiOnClick () {
      this.modal.isMapOnView = false
    },
    mapMarkerOnClick () {
      this.infoWindowPos = this.map.currentPlace
      // this.infoOptions.content = '<div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>'
      this.infoWinOpen = !this.infoWinOpen

      const latLngObj = {
        lat: this.map.markerPosition.lat,
        lng: this.map.markerPosition.lng
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
      this.map.mapSelectedIsShow = true
      // this.modal.isMapOnView = false
    },
    editProductOnShow (idx) {
      this.pilihProductOnEdit = true
      this.pilihProductEditIndex = idx
      this.modal.listProductIsView = true
    },
    tentukanJadwalOnShow () {
      this.modal.isTentukanJadwal = true
      this.days = []
      this.sessionSel.splice(0)
    },
    deleteProductList (idx) {
      this.productServiceSelected.splice(idx, 1)
    },
    kembaliKeProyek () {
      this.$buefy.dialog.confirm({
        message: 'Batalkan pembuatan proyek?',
        onConfirm: () => this.$router.push({ name: 'ProjectList' })
      })
    },
    productListOnClick (val) {
      if (this.pilihProductOnEdit === false) {
        this.productServiceSelected.push(val)
      } else {
        this.productServiceSelected.splice(this.pilihProductEditIndex, 1)
        this.productServiceSelected.push(val)
      }
      this.modal.listProductIsView = false
      this.pilihProductOnEdit = false
      this.pilihProductEditIndex = -1
      // this.buildServiceRelated()
    },
    productListRemove (idx) {
      this.productToDo.splice(idx, 1)
      // this.buildServiceRelated()
    },
    buildServiceRelated () {
      this.productToDo.forEach((row) => {
        row.worker_primary.forEach((wp) => {
          if (!_.find(this.productServiceRelated, { skill_id: wp.skill_id })) {
            const _l = JSON.parse(JSON.stringify(wp))
            _l.order_tukang = 1
            this.productServiceRelated.push(_l)
          }
        })
        row.worker_optional.forEach((wp) => {
          if (!_.find(this.productServiceRelated, { skill_id: wp.skill_id })) {
            const _l = JSON.parse(JSON.stringify(wp))
            _l.order_tukang = 1
            this.productServiceRelated.push(_l)
          }
        })
      })
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    convDateLong (date) {
      return moment(date).format('DD MMMM')
    },
    gettingImageProducts () {
      _.forEach(this.modal.transactionProducts, (v, i) => {
        setTimeout(() => {
          axios.get(v.i_uri)
            .then((cb) => {
              this.modal.transactionProducts[i].image_uri = cb.data.data.url
            })
        }, 500)
      })
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      // this.updateCoordinate()
      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon
      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
    },
    getAddressMap (location) {
      // const geocoder = this.google.maps.Geocoder()
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    pilihLokasiOnClick () {},
    onDateRangeChange (day) {
      this.days = []
      this.days.push({
        id: moment(day.start).format('YYYY-MM-DD'),
        date: moment(day.start).toDate()
      })
      this.days.push({
        id: moment(day.end).format('YYYY-MM-DD'),
        date: moment(day.end).toDate()
      })
    },
    insertCustomer (val) {
      this.$store.commit('project/setCustomer', val)
      this.$store.commit('project/setCustomerId', val.id)
      this.modal.customerIsView = false
    },
    insertTransactionProduct () {
      const p = []
      this.modal.transactionProducts.forEach((val) => {
        if (val.is_select) {
          p.push(JSON.parse(JSON.stringify(val)))
        }
      })
      const transaction = {
        id: '123123',
        products: p
      }
      this.$store.commit('project/setTransactionProductSelected', transaction)
      this.modal.pilihProductIsView = false
    },
    insertAddress () {
      if (this.modal.address.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Harap detail <b>alamat customer</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      let jenisBangunan = null
      let buildingId = null
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          jenisBangunan = rw.name
          buildingId = rw.id
        }
      })

      const addr = {
        address: this.modal.addressRef + ' + ' + this.modal.address,
        lat: this.map.currentPlace.lat,
        lng: this.map.currentPlace.lng,
        jenis_bangunan: jenisBangunan,
        building_id: buildingId
      }

      if (addr.jenis_bangunan == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Jenis Bangunan Harus Pilih!!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.$store.commit('project/setAddress', addr)
      this.modal.tentukanAlamatIsView = false
    },
    inputSesi () {
      this.modal.isTentukanJadwal = false
      this.modal.isSesi = true
    },
    uncheckedAll () {
      this.filter.forEach((item) => {
        item.detail.forEach((item2) => {
          const v = item2.disabled = false
          return v
        })
      })
      this.sessionSel = []
      this.sessionSel.splice(0)
    },
    compareData (x, y) {
      const dateSelect = x
      const skillId = y

      for (let i = 0; i < this.skillsSelected.length; i++) {
        if (this.skillsSelected[i].id === skillId) {
          this.filter.forEach((item) => {
            item.detail.forEach((item2) => {
              if (dateSelect === item2.date && skillId === item2.skillId) {
                const v = item2.disabled = true
                return v
              }
            })
          })
        }
      }
      return false
    },
    buildSesi () {
      let countSesi = 0
      this.$store.commit('project/clearDetailSesi')

      // validasi jika kosong
      if (!this.sessionSel.length) {
        this.$store.commit('project/clearDetailSesi')
        this.$buefy.toast.open({
          duration: 5000,
          message: 'pilihan sesi harus di isi!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.sessionSel.forEach((vals) => {
        if (!_.isEmpty(vals)) {
          countSesi += 1
          const _sel = JSON.parse(JSON.stringify(vals))
          if (vals.sesi !== null) {
            _sel.harga_tukang = 0
          }
          _sel.total_tukang = 0
          this.$store.commit('project/setDetailSesi', vals)
          console.log(vals)
        }
      })

      const x = [...new Set(this.days.map(m => m.id))]
      const y = x.length

      // validasi multiple day dan belum terceklis
      if (countSesi < y) {
        this.$store.commit('project/clearDetailSesi')
        this.$buefy.toast.open({
          duration: 3000,
          message: 'pilihan sesi harus sama dengan tanggal yang dipilih!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      // if (countSesi !== this.sessionSel.length) {
      //   this.$store.commit('project/clearDetailSesi')
      //   this.$buefy.toast.open({
      //     duration: 3000,
      //     message: 'pilihan sesi harus sama dengan tanggal yang dipilih!',
      //     position: 'is-bottom',
      //     type: 'is-danger'
      //   })
      //   return
      // }

      this.modal.isTentukanJadwal = false
      this.modal.isSesi = false
    },
    setInfoDate () {
      const storeDetails = this.newProject.detail_sesi
      const sortDate = storeDetails.slice().sort((a, b) => new Date(a.date) - new Date(b.date))

      const startDate = sortDate[0].date
      const endDate = sortDate[this.newProject.detail_sesi.length - 1].date
      const duration = moment(endDate).diff(moment(startDate), 'days') + 1

      this.$store.commit('project/setInfoDate', {
        start_date: startDate,
        end_date: endDate,
        duration: duration
      })
    },
    hitungAmount () {
      let amount = 0
      let orderTukang = 0
      let hargaSesi0 = 0
      let hargaSesi1 = 0
      let hargaSesi2 = 0
      let hargaSesi3 = 0
      let feeCharge = 0
      let feeBuilding = 0
      // let totalSesi = 0

      const storeDetails = this.newProject.detail_sesi
      const sortDate = storeDetails.slice().sort((a, b) => new Date(a.date) - new Date(b.date))

      const startDate = sortDate[0].date
      const endDate = sortDate[this.newProject.detail_sesi.length - 1].date
      const dateCount = moment(endDate).diff(moment(startDate), 'days') + 1

      if (dateCount === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Cek sesi terlebih dahulu!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      // total sesi
      // totalSesi = this.newProject.detail_sesi.length

      // total harga perhari
      this.newProject.detail_sesi.forEach((row) => {
        if (row.sesi !== null) {
          if (row.sesi_homecare === 0) {
            hargaSesi0 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 1) {
            hargaSesi1 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 2) {
            hargaSesi2 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 3) {
            hargaSesi3 += (row.workerCount * row.price)
          }
        }
      })
      // total tukang
      this.skillsSelected.forEach((row) => {
        orderTukang += row.tukang
      })

      // fee_charge building type
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          feeCharge = rw.fee_charge
        }
      })

      amount = ((hargaSesi0 + hargaSesi1 + hargaSesi2 + hargaSesi3) + ((feeCharge * orderTukang) * dateCount))

      feeBuilding = ((feeCharge * orderTukang) * dateCount)

      return {
        amount: amount,
        orderTukang: orderTukang,
        dateCount: dateCount,
        fee_building: feeBuilding
        // startDate: startDate,
        // endDate: endDate,
      }
    },
    setItem () {
      const x = []
      this.sessionSel.forEach((row) => {
        const y = {
          date: row.date,
          premium_price: 0,
          premium_worker: 0,
          skill_id: row.skillId,
          skill_name: row.skillsName,
          standard_price: row.price,
          session1_price: row.session1_price,
          session2_price: row.session2_price,
          standard_worker: row.workerCount
        }
        x.push(y)
      })

      const vv = [...new Map(x.map(item => [item.skill_id, item])).values()]
      this.$store.commit('project/setItem', vv)
    },
    setSchedule () {
      const skills = []
      const schedules = []
      this.skillsSelected.forEach((row) => {
        skills.push({
          skill_id: row.id,
          skill_name: row.name,
          order_tukang: row.tukang
        })
      })
      this.newProject.detail_sesi.forEach((row) => {
        schedules.push({
          date_sesi: row.date,
          sesi_id: row.sesi_id
        })
      })
      this.$store.commit('project/setSchedule', {
        skills: skills,
        schedules: schedules
      })
    },
    createProjectConfirm () {
      try {
        if (this.newProject === undefined) { return }

        if (this.newProject.customer_name.trim() === '' ||
          this.newProject.jenis_bangunan.trim() === '' ||
          this.newProject.detail_sesi.length === 0 ||
          this.newProject.address.trim() === '' ||
          this.skillsSelected.length === 0) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Mohon lengkapi dulu data yang harus diinput',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }
        const n = this.hitungAmount()
        this.$store.commit('project/setAmount', {
          amount: n.amount,
          net_amount: n.amount,
          fee_building: n.fee_building
        })
        // this.setServiceId()
        // this.setProductId()
        this.setItem()
        this.setSchedule()
        this.setInfoDate()
        this.$store.commit('project/setInfo', {
          idtransaction_item: 0,
          user_id: this.user.id,
          transaction_id: uuidv4()
        })
        // const amountsAll = n.amount
        // if (this.userSaldo <= 0 || amountsAll < this.userSaldo) {
        //   this.modal.isSaldoTidakCukup = true
        //   return
        // }
        if (this.deskripsiPekerjaan.trim() === '') {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Mohon informasi <b>Deskripsi Pekerjaan</b> diisi dulu',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }

        axios.post(`${this.url}/api/v1/vendor/price_calculation`, this.newProject)
          .then((dt) => {
            this.dataCalculation = dt.data.data
            this.viewState = !this.viewState
          })
          .catch((err) => {
            throw new Error(err.response.data.message)
          })

        // this.viewState = !this.viewState
      } catch (error) {
        throw new Error(error)
      }
    },
    createProject () {
      if (this.processing === true) {
        console.log('test async, tolak')
      }
      this.processing = true
      axios.post(`${this.url}/api/v1/vendor/create_project_deposit`, this.newProject)
        .then((res) => {
          this.processing = false
          if (res.data.code === 1) {
            this.$buefy.toast.open({
              duration: 3000,
              message: res.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
            this.$router.push({ name: 'ProjectList' })
          }
        })
        .catch((err) => {
          this.$buefy.toast.open({
            duration: 4000,
            message: err.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    mapOnViewClick () {
      if (this.map.mapSelectedIsShow === true) {
        const x = JSON.parse(JSON.stringify(this.map.currentPlace))
        this.map.markerPosition = x
        this.map.gmapCenter = x
      }
      this.infoWinOpen = false
      this.modal.isMapOnView = true
    }
  },
  created () {
    this.loadSesiSkills()
    this.loadSkillList()
    this.loadBuildingType()
    // this.newProject = this.setProject()
    this.$store.dispatch('project/setNewProject')
    this.minDateOrder = moment().add(1, 'days').toDate()
    const now = moment()
    if (now.hour() >= 18) {
      this.minDateOrder = moment().add(2, 'days').toDate()
    }
    // guard.check(this.$router, this.$router.currentRoute.path)
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.input-card {
  padding: 20px 25px 20px 25px;
  margin-bottom: 20px;

  background: rgba(255, 255, 255, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.65);
}
.kang-label {
  margin-top: 20px;
}
.kang-val {
  margin-top: 5px;
}
.jenis-bangunan {
  cursor: pointer;
  &:hover {
    background-color: rgb(196, 196, 196);
  }
}
.jenis-bangunan-selected {
  background-color: rgb(196, 196, 196);
}

.modal {
  overflow: auto;
}
.dropdown-box {
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 2px;
  div, button {
    width: 100%;
    text-align: left;
  }
}
</style>
