import Vue from 'vue'
import Vuex from 'vuex'
import project from './modules/project'
import skill from './modules/skill'
import auth from './modules/auth'
import payment from './modules/payment'
import member from './modules/member'
import setup from './modules/setup'
import offer from './modules/offer'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    project,
    skill,
    auth,
    payment,
    member,
    setup,
    offer
  },
  strict: debug
  // plugins: debug ? [createLogger()] : []
})
