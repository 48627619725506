<template>
  <section>
    <div class="card">
      <div style="border-bottom: solid thin whitesmoke; height: 40px;">
        <p class="title is-5" style="text-align: center; margin-bottom: 0px; margin-top: 20px;">Jadwal Pengerjaan</p>
      </div>
      <div class="card-content">
        <div class="content">
          <v-date-picker
          :min-date='minDateOrder'
          @input="onDateRangeChange" is-range :columns="modal.dateColumn" value="range" is-expanded :attributes="dateAttributes" />
        </div>
        <div class="content" style="text-align: right;">
          <b-button @click="inputSesi()" style="width: 200px;" :type="{'is-light' : days.length === 0, 'is-success' : days.length > 1 }">Simpan</b-button>
        </div>
      </div>
    </div>

    <b-modal v-model="modal.isSesi" :width="1400" scroll="keep">
      <div class="card">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Sesi Pengerjaan</h1>
        </div>
        <div class="card-content">
          <table class="table" style="width: 100%;">
            <thead class="is-title-5">
              <tr>
                <td><h5 class="title is-5">Tanggal Pengerjaan</h5></td>
                <td><h5 class="title is-5">Nama Produk</h5></td>
                <td><h5 class="title is-5">Penugasan Tukang & Sesi</h5></td>
                <td></td>
              </tr>
            </thead>

            <tbody v-for="(value, index) in filter" v-bind:key="index">
              <tr>
                <td class="is-size-6 has-text-weight-bold">{{ convDate(value.id) }}</td>
                <div v-for="(n) in value.productSel" v-bind:key="n.name_product">
                  <td class="is-size-6 has-text-weight-bold">{{ n.name_product }}</td>
                </div>
                <td>
                  <table>
                    <tbody>
                      <tr v-for="(nnn) in sortBySesi(value.detail)" v-bind:key="nnn.sesi_id">
                        <td>{{ nnn.skill_name }}</td>
                        <td>{{ nnn.sesi_name }}</td>
                        <td>Rp.{{ nnn.price | numFormat }}</td>
                        <td>{{ nnn.start }} - {{ nnn.end }}</td>
                        <td>
                          <b-checkbox class="is-center" v-bind:value="nnn" v-model.number="sessionSel" :native-value="nnn"></b-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="columns is-mobile m-3">
          <div class="column is-4 is-offset-8">
            <b-button @click="buildSesi()" type="is-primary" style="width: 100%;">Simpan</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'rescheduleProject',
  props: ['product'],
  components: {},
  data () {
    return {
      sessionSel: [],
      minDateOrder: null,
      days: [],
      modal: {
        dateColumn: 2,
        isSesi: false
      },
      isLoading: false,
      details: null,
      productId: null,
      productSelected: []
    }
  },
  computed: {
    ...mapGetters({
      rescheduleProject: 'project/getRescheduleProject',
      user: 'auth/user',
      url: 'setup/getUrl'
    }),
    dates () {
      return this.days.map(day => day.date)
    },
    dateAttributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    filter () {
      return [...new Map(this.listSesi.map(item => [item.id, item])).values()]
    },
    listSesi () {
      var x = []
      const sesi = []

      if (this.days.length >= 2) {
        const start = moment(this.days[0].date)
        const end = moment(this.days[1].date)
        for (var m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
          const _id = moment(m).format('YYYY-MM-DD')
          x.push({ id: _id, date: m.valueOf() })
        }

        x.forEach((v) => {
          const day = {
            id: v.id,
            selected: [],
            dateSel: [],
            productSel: [],
            tukangSel: [],
            detail: []
          }

          if (this.productSelected.length > 0) {
            const _day = moment(v.date).weekday()
            const val = 'Minggu,Senin,Selasa,Rabu,Kamis,Jumat,Sabtu'.split(',')

            day.dateSel.push({
              date: v.id,
              day: val[_day]
            })

            this.productSelected.forEach((t) => {
              day.productSel.push({
                name_product: t.product_name
              })
              t.service_list.forEach((z) => {
                z.skill_list.forEach((u) => {
                  day.tukangSel.push({
                    skill_name: u.name
                  })

                  u.price_skill_sesi.forEach((w) => {
                    day.detail.push({
                      sesi_id: w.sesi_id,
                      sesi: w.sesi,
                      sesi_name: w.sesi_name,
                      price: w.price,
                      start: w.start_hour,
                      end: w.end_hour,
                      date: v.id,
                      day: val[_day],
                      name_product: t.product_name,
                      skill_name: u.name,
                      name: u.name
                    })
                  })
                })
              })
            })
            sesi.push(day)
          }
        })
        return sesi
      } else {
        return []
      }
    }
  },
  methods: {
    sortBySesi (val) {
      const t = val.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.sesi_id]: current
        })
      }, {})
      return t
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    convDateLong (date) {
      return moment(date).format('DD MMMM')
    },
    inputSesi () {
      this.modal.isTentukanJadwal = false
      this.modal.isSesi = true
    },
    onDateRangeChange (day) {
      this.days = []
      this.days.push({
        id: moment(day.start).format('YYYY-MM-DD'),
        date: moment(day.start).toDate()
      })
      this.days.push({
        id: moment(day.end).format('YYYY-MM-DD'),
        date: moment(day.end).toDate()
      })
    },
    projectDetails (id) {
      axios.get(this.url + '/api/v1/vendor/project_detail_sesi?project_id=' + id)
        .then((cb) => {
          this.details = cb.data.data[0]
          this.$store.commit('project/setProjectId', cb.data.data[0].id)
        })
    },
    productDetails () {
      const idProduct = this.product
      const pds = this.url + '/api/v1/vendor/product_detail_sesi?product_id=' + idProduct
      axios.get(pds)
        .then((cb) => {
          const val = cb.data.data
          this.productSelected.push(val)
        })
    },
    buildSesi () {
      let countSesi = 0
      this.selectedSesi = ''

      // validasi jika kosong
      if (!this.sessionSel.length) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'pilihan sesi harus di isi!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.sessionSel.forEach((vals) => {
        if (!_.isEmpty(vals)) {
          countSesi += 1
          const _sel = JSON.parse(JSON.stringify(vals))
          if (vals.sesi !== null) {
            _sel.harga_tukang = 0
          }
          _sel.total_tukang = 0
          this.$store.commit('project/setDetailSesiReschedule', vals)
        }
      })

      const x = [...new Set(this.days.map(m => m.id))]
      const y = x.length

      // validasi multiple day dan belum terceklis
      if (countSesi < y) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'pilihan sesi harus sama dengan tanggal yang dipilih!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      axios.post(this.url + '/api/v1/vendor/project_detail/reschedule', this.rescheduleProject)
        .then((res) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Reschedule Berhasil!',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.modal.isTentukanJadwal = false
          this.modal.isSesi = false
          this.$router.go()
        })
        .catch((err) => {
          console.log(err)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Error reschedule!....',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.minDateOrder = moment().add(1, 'days').toDate()
    const now = moment()
    if (now.hour() >= 18) {
      this.minDateOrder = moment().add(2, 'days').toDate()
    }
    this.projectDetails(this.$route.params.projectId)
    this.$store.dispatch('project/setRescheduleProject')
    this.productDetails()
  },
  mounted () {}
}
</script>
