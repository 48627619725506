<template>
  <div v-if="activeProjects">
    <b-table
      :total="paginate.total"
      :per-page="paginate.perPage"
      :data="paginate.data"
      :loading="paginate.loading"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
      backend-pagination
      scrollable>
      <b-table-column field="project_name" label="ID Proyek" v-slot="props">
        <div
          class="project-btn"
          @click="detailOnClick(props.row)"
          style="text-align: center; margin-top: 20px;">
            <span> ID # {{ props.row.id }}</span>
            <div>
              {{ props.row.consumer_name }}
            </div>
        </div>
      </b-table-column>
      <b-table-column width="350" field="location" label="Lokasi" v-slot="props">
        {{ props.row.address }}
      </b-table-column>
      <b-table-column field="start_date" label="Tanggal Pengerjaan" v-slot="props">
        <ul style="margin-left: 20px; margin-top: 10px;">
          <li style="list-style-type: circle;">
            Mulai: {{ setFormat(props.row.start_date) }}
          </li>
          <li style="list-style-type: circle;">
            Selesai: {{ setFormat(props.row.end_date) }}
          </li>
        </ul>
      </b-table-column>
      <b-table-column field="type" label="Jenis Pesanan" v-slot="props">
        <span
          :class="{'tag is-success is-light has-text-weight-bold': props.row.type === 'Langsung',
                    'tag is-info is-light has-text-weight-bold': props.row.type === 'Khusus'}">
          {{ props.row.type }}
        </span>
      </b-table-column>
      <b-table-column field="status" label="Status Project" v-slot="props">
        <b-tag
          :class=" {'tag is-success': props.row.status === 'Completed',
                    'tag is-danger': props.row.status === 'Cancelled'} ">
          {{ props.row.status }}
        </b-tag>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="paginate.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'Arsip',
  data () {
    return {
      activeProjects: true,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  props: {
    initData: Array
  },
  watch: {
    initData (newVal) {
      if (newVal.length > 0) {
        this.paginate.data = newVal
        this.paginate.total = 1
        this.paginate.page = 1
      } else {
        this.loadData(1)
      }
    }
  },
  components: {},
  methods: {
    searchOnClick () {},
    detailOnClick (v) {
      this.$router.push({
        path: `/project/detail/${v.id}/Arsip`
      })
    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      this.loadData(v)
    },
    loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const status = 'Completed'

      const data = {
        url: this.url,
        status: status,
        page: this.paginate.page,
        perPage: this.paginate.perPage
      }

      this.$store.dispatch('project/proyekArsipListApi', data)
        .then((res) => {
          if (res.data.code === 1) {
            this.paginate.data = res.data.data.list
            this.paginate.total = (res.data.data.total_page * this.paginate.perPage)
            this.paginate.page = res.data.data.current_page
            this.paginate.loading = false
          }
        })
        .catch((err) => {
          throw new Error(err)
        })
    }
  },
  created () {
    this.loadData(1)
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
