<template>
  <transition name="fadeDown">
    <div class="dash-container">
      <section style="margin-bottom: 20px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                <b-icon icon="file-chart-outline" /> &nbsp; Laporan Saldo: Rp. {{ userSaldo | numFormat }}
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            </div>
          </div>
        </div>
      </section>
      <div class="column is-12">
        <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
          <div class="level-left">
            <div @click="selectTab(0)" class="tab-label" :class="{'active' : activeTab === 0}">Jurnal</div>
            <div @click="selectTab(1)" class="tab-label" :class="{'active' : activeTab === 1}"> Riwayat Top Up </div>
            <div @click="selectTab(3)" class="tab-label" :class="{'active' : activeTab === 3}"> Invoice Proyek </div>
          </div>
          <div class="level-right">
            <div class="columns">
              <div class="column">
                <b-datepicker
                  v-model="filterTanggalStart"
                  ref="date"
                  placeholder="Start Date"
                  icon="calendar-today"
                  clearable
                  trap-focus>
                </b-datepicker>
              </div>
              <div class="my-4"> - </div>
              <div class="column">
                <b-datepicker
                  v-model="filterTanggalEnd"
                  ref="date"
                  placeholder="End Date"
                  icon="calendar-today"
                  clearable
                  trap-focus>
                </b-datepicker>
              </div>
              <div class="column">
                <b-button @click="filterTanggal()" type="is-primary"> Filter </b-button> &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="activeTab === 0">
        <jurnal :date="valueDate" />
      </div>
      <div v-show="activeTab === 1">
        <riwayat-top-up :date="valueDate" />
      </div>
      <div v-show="activeTab === 3">
        <invoice-proyek :date="valueDate" />
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Jurnal from './Jurnal.vue'
import guard from '../../routerGuard'
import RiwayatTopUp from './RiwayatTopUp.vue'
import InvoiceProyek from './InvoiceProyek.vue'
// import PendingTopUp from './PendingTopUp.vue'

export default {
  name: 'Laporan',
  data () {
    return {
      activeTab: 0,
      dateSelected: [],
      startDate: '',
      endDate: '',
      filterTanggalStart: null,
      filterTanggalEnd: null,
      dateSelectedStart: null,
      dateSelectedEnd: null,
      fromDate: null,
      toDate: null,
      valueDate: null
      // filterTanggal: null,
      // cabang: [
      //   { id: 1, name: 'Mitra 10 Aeon Mall' },
      //   { id: 2, name: 'Mitra 10 Mall Serpong' },
      //   { id: 3, name: 'Mitra 10 BTC Tangerang Selatan' }
      // ]
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      userSaldo: 'setup/getUserSaldo'
    })
  },
  components: {
    Jurnal,
    RiwayatTopUp,
    InvoiceProyek
    // PendingTopUp,
  },
  watch: {
    filterTanggalStart (val) {
      if (val === null) {
        return new Date()
      } else {
        this.dateSelected.push({
          fromDate: moment(val).format('YYYY-MM-DD')
        })
      }
    },
    filterTanggalEnd (val) {
      if (val === null) {
        return new Date()
      } else {
        this.dateSelected.push({
          toDate: moment(val).format('YYYY-MM-DD')
        })
      }
    }
  },
  methods: {
    filterTanggal () {
      if (this.dateSelected !== null) {
        this.valueDate = {
          tgl1: this.dateSelected[0].fromDate,
          tgl2: this.dateSelected[1].toDate
        }
        this.dateSelected.splice(0)
      } else {
        this.resetDate()
      }
    },
    initialState () {
      return {
        valueDate: {
          tgl1: null,
          tgl2: null
        }
      }
    },
    resetDate () {
      Object.assign(this.$data, this.initialState())
    },
    clearDate () {
      this.filterTanggalStart = null
      this.filterTanggalEnd = null
    },
    selectTab (val) {
      this.activeTab = val
    },
    btnTambahOnClick () {
      this.$router.push('/project/input')
    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    searchOnClick () {},
    onPageChange (v) {},
    onDateRangeChange (val) {}
  },
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
