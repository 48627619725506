<template>
  <transition name="fadeDown">
    <div class="dash-container">
      <section style="margin-bottom: 10px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                <b-icon icon="account-hard-hat" /> &nbsp; Proyek
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <app-button-project/>
            </div>
          </div>
        </div>
      </section>
      <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
        <div class="level-left">
          <div @click="selectTab(0)" class="tab-label" :class="{'active' : activeTab === 0}">
            Mencari Mitra
          </div>
          <div @click="selectTab(1)" class="tab-label" :class="{'active' : activeTab === 1}">
            Dalam Rencana
          </div>
          <div @click="selectTab(2)" class="tab-label" :class="{'active' : activeTab === 2}">
            Proyek Aktif
          </div>
          <div @click="selectTab(3)" class="tab-label" :class="{'active' : activeTab === 3}">
            Arsip
          </div>
        </div>
      </div>
      <div v-show="activeTab === 0">
        <proyek-mencari-mitra-list :initData="searchDataDalamRencana" />
      </div>
      <div v-show="activeTab === 1">
        <dalam-rencana :initData="searchDataDalamRencana" />
      </div>
      <div v-show="activeTab === 2">
        <proyek-aktif :initData="searchDataProyekAktif" />
      </div>
      <div v-show="activeTab === 3">
        <arsip :initData="searchDataArsip" />
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'
import Arsip from '../../components/ProyekArsipList.vue'
import ProyekAktif from '../../components/ProyekAktifList.vue'
import DalamRencana from '../../components/ProyekInPlanningList.vue'
import AppButtonProject from '../../components/AppButtonProject.vue'
import ProyekMencariMitraList from '../../components/ProyekMencariMitraList.vue'

export default {
  name: 'ProjectList',
  data () {
    return {
      search: null,
      searchDataDalamRencana: [],
      searchDataProyekAktif: [],
      searchDataArsip: [],
      activeTab: 0,
      cabang: [
        { id: 1, name: 'Mitra 10 Aeon Mall' },
        { id: 2, name: 'Mitra 10 Mall Serpong' },
        { id: 3, name: 'Mitra 10 BTC Tangerang Selatan' }
      ]
    }
  },
  components: {
    ProyekAktif,
    DalamRencana,
    Arsip,
    AppButtonProject,
    ProyekMencariMitraList
  },
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.searchDataProyekAktif = []
        this.searchDataDalamRencana = []
        this.searchDataArsip = []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath'
    })
  },
  methods: {
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    selectTab (val) {
      this.activeTab = val
    },
    searchOnClick () {
      if (this.search === null || this.search === '') {
        return
      }
      axios.get(`${this.url}/api/v1/vendor/project_search?search=${this.search}`)
        .then((cb) => {
          if (cb.data.data.length === 0) {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Tidak ada data yang ditemukan!...',
              position: 'is-bottom',
              type: 'is-danger'
            })
            return
          }
          if (this.activeTab === 0) {
            this.searchDataProyekAktif = cb.data.data
          }
          if (this.activeTab === 1) {
            this.searchDataDalamRencana = cb.data.data
          }
          if (this.activeTab === 2) {
            this.searchDataArsip = cb.data.data
          }
        })
    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      console.log(v)
    }
  },
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
