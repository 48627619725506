<template>
  <div class="container">
    <div class="columns is-centered" style="margin-top: 20px;">
      <div class="column is-two-fifths" style="text-align: center; padding: 20px;">
        <img alt="b2b kanggo" style="width: 200px;" src="../../assets/kanggo-ace-logo.png">
        <div class="card" style="text-align: left;">
          <div style="text-align: center; margin-top: 20px;">
            <b-icon icon="account-lock-outline" size="is-large"></b-icon>
          </div>
          <div class="card-content">
            <div style="margin-top: 10px;">
              Please click to Log In
            </div>
            <div style="text-align: right; margin-top: 20px; margin-bottom: 10px;">
              <b-button @click="login()" style="width: 200px;">Log In</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  computed: {
    ...mapGetters('auth', {
      isLogin: 'isLogin',
      token: 'token'
    })
  },
  watch: {
    isLogin (newVal, oldVal) {
      if (newVal === true) {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  data () {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    login () {
      this.$router.push({ name: 'Login' })
    }
  },
  created () {
    const rs = localStorage.getItem('webapp_b2b')
    const auth = JSON.parse(rs)
    if (auth && auth.token) {
      this.$store.commit('auth/setLoginStatus', true)
      this.$router.push({ name: 'Home' })
    } else {
      this.$store.commit('auth/setLoginStatus', false)
    }
  }
}
</script>
