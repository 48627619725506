<template>
  <section>
    <div class="container is-max-desktop">
      <div class="level mt-3">
        <div class="level-left">
          <b-button
            @click="kembaliKeProyek()"
            type="is-light"
            size="is-small"
            icon-left="arrow-left">
          </b-button>
          <h1
            class="subtitle is-6"
            style="margin-top: 5px; margin-left: 10px;">
            Kembali ke List
          </h1>
        </div>
        <div class="level-right">
          <div class="title is-4 has-text-weight-light">Buat Penawaran</div>
        </div>
      </div>
    </div>
    <div class="borderForm"></div>
      <transition name="fade">
        <form-offer/>
      </transition>
  </section>
</template>

<script>
import FormOffer from '../../components/FormOffer.vue'
// import guard from '../../routerGuard'

export default {
  name: 'CreateOffer',
  components: {
    FormOffer
  },
  data () {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    kembaliKeProyek () {
      this.$buefy.dialog.confirm({
        message: 'Batalkan pembuatan Penawaran?',
        onConfirm: () => this.$router.push({ name: 'Penawaran' })
      })
    }
  },
  created () {}
}

</script>

<style lang="scss" scoped>
.borderForm {
  border-bottom: solid thin #f0f0f0;
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
}
</style>
