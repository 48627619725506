<template>
  <section>
    <div class="input-card container is-max-desktop">
      <div class="level" style="margin-bottom: 40px;">
        <div class="level-center">
          <h1 class="title is-4">Konfirmasi Proyek</h1>
        </div>
      </div>
      <div>
        <div class="kang-label"> Client </div>
        <div class="kang-val">
          <h1 class="title is-5">{{ newPenawaran.customer_name }}</h1>
        </div>
      </div>
      <div class="input-card" style="margin-top: 20px;">
        <div style="margin-bottom: 10px;"> Penugasan Tukang </div>
        <div v-if="newPenawaran.skills">
          <div v-for="(v, idx) in newPenawaran.skills" :key="'_idxx' + idx"
            style="display: flex; height: auto; border-bottom: solid thin #f0f0f0; padding: 10px; margin-bottom: 10px;">
            <div style="width: 80px; text-align: center;">
              <img style="width: 50px; height: 50px; border-radius: 5px;" :src="v.image_uri" />
            </div>
            <div style="width: 100%;">
              <p class="title is-6 mt-2">{{ v.skill_name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="kang-label"> Alamat Pengerjaan </div>
        <div class="kang-val">
          <h1 class="title is-5 is-size-6-mobile">{{ newPenawaran.address }}</h1>
          <div v-if="newPenawaran && newPenawaran.jenis_bangunan">
            Tipe Bangunan: <span class="has-text-weight-bold is-size-5 is-size-6-mobile">{{ newPenawaran.jenis_bangunan }}</span>
          </div>
        </div>
        <div v-if="newPenawaran && newPenawaran.address">
          <div style="padding-top: 20px; padding-bottom: 20px;">
            <div v-if="newPenawaran && newPenawaran.latitude && newPenawaran.longitude">
              <GmapMap map-type-id="roadmap" :zoom="15"
                :center="map.currentPlace"
                :options="{disableDefaultUI: true}"
                style="width: 100%; height: 200px;">
                <GmapMarker :position="map.currentPlace">
                </GmapMarker>
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="kang-label"> Deskripsi Pekerjaan</div>
        <div class="kang-val">
          <h1 class="title is-5">{{ newPenawaran.description }}</h1>
        </div>
      </div>
      <div v-if="newPenawaran.total_harga_keseluruhan === 0">
        <div class="kang-label">Rincian Biaya</div>
        <div style="margin-top: 10px;" v-if="newPenawaran">
          <div class="table-container">
            <table class="table" style="max-width: 100%;">
              <thead>
                <tr>
                  <td class="has-text-weight-bold">Tanggal</td>
                  <td class="has-text-weight-bold">Sesi</td>
                  <td class="has-text-weight-bold">Harga Sesi</td>
                  <td class="has-text-weight-bold">Total Tukang</td>
                  <td class="has-text-weight-bold">Jumlah</td>
                </tr>
              </thead>
              <tbody v-if="dataCalculation !== null">
                <tr v-for="(vv, ii) in dataCalculation.details" :key="ii">
                  <td>{{ convDate(vv.date) }}</td>
                  <td>{{ vv.sesi }} - ({{ vv.start_hour }} - {{ vv.end_hour }})</td>
                  <td>Rp.{{ vv.price | numFormat }}</td>
                  <td class="has-text-centered">{{ vv.workerCount }}</td>
                  <td>Rp.{{ (vv.price * vv.workerCount) | numFormat }}</td>
                </tr>
                <tr>
                  <td colspan="4" class="has-text-right has-text-weight-bold">Sub Total</td>
                  <td>Rp.{{ dataCalculation.amount | numFormat}}</td>
                </tr>
                <tr>
                  <td colspan="4" class="has-text-right has-text-weight-bold">Pph(2%)</td>
                  <td>Rp.{{ dataCalculation.pph | numFormat}}</td>
                </tr>
                <tr>
                  <td colspan="4" class="has-text-right has-text-weight-bold">Total Setelah Pph</td>
                  <td>Rp.{{ dataCalculation.amount_after_tax | numFormat}}</td>
                </tr>
                <tr>
                  <td colspan="4" class="has-text-right has-text-weight-bold">Biaya Parkir</td>
                  <td>Rp.{{ dataCalculation.fee_building | numFormat }}</td>
                </tr>
                <tr>
                  <td colspan="4" class="has-text-right has-text-weight-bold">Total</td>
                  <td>Rp.{{ dataCalculation.net_amount | numFormat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="dataCalculation !== null">
        <div class="kang-label"> Total Biaya </div>
        <div style="margin-top: 10px;">
          <div class="title is-5" v-if="newPenawaran.total_harga_keseluruhan === 0">
            Rp.{{ dataCalculation.net_amount | numFormat }}
          </div>
          <div class="title is-5" v-else>
            Rp.{{ newPenawaran.total_harga_keseluruhan | numFormat }}
          </div>
        </div>
      </div>
      <div
        style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
        <b-button
          @click.prevent="createProject()"
          type="is-primary"
          style="width: 300px;">
            Buat Proyek
        </b-button>
      </div>
    </div>

    <b-modal v-model="isSaldoTidakCukup" :width="450">
      <div class="card">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Informasi</h1>
        </div>
        <div class="card-content" style="text-align: center;">
          <img style="width: 150px;" src="../assets/saldo-tidak-cukup.png" />
          <div style="font-weight: bold;"> Saldo tidak mencukupi! </div>
          <div> Silakan top up saldo terlebih dahulu, kemudian klik tombol buat proyek lagi </div>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <b-button type="is-danger" @click="isSaldoTidakCukup = false" style="width: 200px;">Kembali</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ConfirmationProject',
  components: {},
  props: ['dataCalculation'],
  data () {
    return {
      isSaldoTidakCukup: false,
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      }
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      newPenawaran: 'offer/getNewPenawaran'
    })
  },
  methods: {
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    createProject () {
      axios.post(`${this.url}/api/v1/vendor/create_project_penawaran`, this.newPenawaran)
        .then((res) => {
          if (res.data.code === 1) {
            this.$buefy.toast.open({
              duration: 4000,
              message: res.data.message,
              position: 'is-bottom',
              type: 'is-success'
            })
            this.$router.push({ name: 'Penawaran' })
          }
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.isSaldoTidakCukup = true
          }
        })
    }
  },
  created () {}
}
</script>

<style lang="scss" scoped>
.kang-label {
  margin-top: 20px;
}
</style>
