<template>
  <div class="card">
    <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
      <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Top Up Saldo</h1>
    </div>
    <div class="card-content">
      <b-field label="Rp." horizontal>
        <b-input v-model="nominal" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="Masukan nominal Top-Up"></b-input>
        <b-button @click="setTopUp()">Top Up</b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TopUpInputNominal',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    clearInput () {
      this.nominal = null
    },
    setTopUp () {
      if (this.nominal === '' || this.nominal === null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Nominal tidak boleh kosong..',
          position: 'is-bottom',
          type: 'is-danger'
        })
        this.clearInput()
        return
      }
      this.$emit('settopup')
    }
  },
  watch: {
    nominal () {
      this.$emit('isinput', this.nominal)
    }
  },
  data () {
    return {
      nominal: null
    }
  }
}
</script>
