/* eslint-disable */

const _customers = [
  { 'user_id': 1, 'name': 'Fredy Djoniawan', 'cell_phone': '081282258320' },
  { 'user_id': 2, 'name': 'Herry Chow', 'cell_phone': '081297882285' }
]
export default {
  getCustomers (cb) {
    setTimeout(() => cb(_customers), 100)
  },
  getCustomerByPhone (cb) {
    setTimeout(() => cb(_customers[0]), 100)
  }
}