// import axios from 'axios'

const getDefaultState = () => {
  return {
    skillTukang: []
  }
}

const state = () => ({
  skillTukang: []
})

const getters = {
  getSkill: (state, getters, rootState) => {
    return state.skillTukang
  }
}

const actions = {
  skillApi ({ commit }, value) {
    // axios.get(`${value.url}/${process.env.VUE_APP_URL_SKILL_ALL}?page=1&limit=1000`)
    //   .then((response) => {
    //     if (response.data.code === 1) {
    //       commit('setSkillTukang', response.data.data)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  },
  resetState ({ commit }) {
    commit('resetState')
  }
}

const mutations = {
  setSkillTukang (state, value) {
    state.skillTukang.push(value)
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
