<template>
  <div>
    <!-- PDF Content Here -->
    <div class="columns is-mobile pdf" ref="document">
      <div :class="{'watermarkCancel': this.statusProject === 'Cancelled', 'watermarkPaid': this.statusProject !== 'Cancelled'}"></div>
      <div class="column is-10 is-offset-1">
        <div class="columns">
          <div class="column">
            <div class="level">
              <div class="level-left">
                <div class="column is-one-quarter">
                  <img src="../../assets/logo-kanggo.png" class="image" style="width: 50%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-bold has-text-centered is-size-4">
              <strong><u>INVOICE</u></strong>
            </p>
          </div>
        </div>
        <div class="columns" v-if="detailsProject !== null">
          <div class="column">
            <table class="table is-bordered is-fullwidth">
              <tr>
                <td colspan="2">
                  <p>Kepada: <br>
                    <span><strong> PT Catur Mitra Sejati Sentosa </strong></span> <br>
                    <span>(MITRA10)</span> <br>
                    <span>Jl.Boulevard Gading Serpong Blok Mitra 10 <br>
                      Kelurahan Curug Sangereng <br>
                      Kecamatan Kelapa Dua <br>
                      Kabupaten Tangerang <br>
                      Provinsi Banten
                    </span>
                  </p>
                </td>
                <td colspan="4">
                  <span class="has-text-weight-bold">No.Invoice : {{ invNumber }}/FNC-TKI/INV/{{ convertToRoman(new Date()) }}/2022</span> <br>
                  <span class="has-text-weight-bold">Tanggal Invoice:</span> <span>{{ convDate(detailsProject.created_at) }}</span> <br>
                  <span class="has-text-weight-bold">Project ID:</span> <span>#{{ detailsProject.id }}</span> <br>
                  <span class="has-text-weight-bold">PIC:</span> <span>{{ detailsProject.consumer_name }}</span><br>
                  <span class="has-text-weight-bold">Nama Pembuat Pesanan:</span> <span>{{ detailsProject.created_project }}</span> <br>
                  <span class="has-text-weight-bold">Periode:</span> <span>{{ convDate(detailsProject.start_date) }} – {{ convDate(detailsProject.end_date) }}</span> <br>
                </td>
              </tr>
            </table>
            <table class="table is-bordered is-fullwidth">
              <tr class="has-text-weight-bold">
                <th>No.</th>
                <th>Tanggal Pengerjaan</th>
                <th>Jenis Tukang</th>
                <th>Jumlah Tukang</th>
                <th>Harga Pertukang</th>
                <th>Jumlah</th>
              </tr>
              <tbody v-for="(v, index) in detailsProject.schedule" :key="index">
                <tr v-for="(vv, idx) in v.detail_sesi" :key="idx">
                  <td class="has-text-centered"> {{ idx + 1 }} </td>
                  <td>{{ convDate(v.date_sesi) }}</td>
                  <td> {{ vv.name }} - {{ vv.dataWorker.name }} </td>
                  <td class="has-text-centered"> {{ vv.dataWorker.total_worker }} </td>
                  <td> Rp.{{ vv.price | numFormat }} </td>
                  <td>Rp.{{ parseInt(vv.dataWorker.total_worker) * parseInt(vv.price) | numFormat }}</td>
                </tr>
                <!-- <tr>
                  <td>Net Amount</td>
                  <td>{{ netAmountPerDay }}</td>
                </tr> -->
              </tbody>
              <tr>
                <td colspan="5" class="has-text-right"> <span> Sub Total </span> </td>
                <td>Rp.{{ (parseInt(totalSesi)) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="5" class="has-text-right">
                  <span> Pph 2% </span>
                </td>
                <td>Rp.{{ detailsProject.pph | numFormat }} (-)</td>
              </tr>
              <tr>
                <td colspan="5" class="has-text-right"> <span> Total Setelah Dikurangi Pph </span> </td>
                <td>Rp.{{ amountAfterTax | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="5" class="has-text-right"> <span> Total Biaya Parkir </span> </td>
                <td>Rp.{{ parseInt(detailsProject.fee_charge_building) * parseInt(totalTukang) | numFormat }} (+)</td>
              </tr>
              <tr>
                <td colspan="5" class="has-text-right">
                  <span><strong> Net Total </strong></span>
                </td>
                <td>
                  <strong>Rp.{{ detailsProject.amount | numFormat }}</strong>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right">
                <b-button type="is-primary" @click="exportPDF()">Export PDF</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'
// import loadash from 'lodash'

export default {
  name: 'InvoicePDF',
  props: ['project'],
  data () {
    return {
      detailsProject: null,
      statusProject: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    netAmountPerDay () {
      let amountPerDay = 0
      let amountPD = 0
      let totalWorker = 0
      this.detailsProject.schedule.forEach((row, index) => {
        if (row.date_sesi) {
          row.detail_sesi.forEach((rows, idx) => {
            amountPD = rows.price
            totalWorker = rows.dataWorker.total_worker
          })
        }
      })
      amountPerDay = amountPD * totalWorker
      return amountPerDay
    },
    totalDay () {
      const day = []
      this.detailsProject.schedule.forEach((row) => {
        day.push({
          date: row.date_sesi
        })
      })

      const totalDay = day.length

      return totalDay
    },
    totalTukang () {
      let totalTukang = 0
      this.detailsProject.schedule.forEach((row, index) => {
        row.detail_sesi.forEach((rows, idx) => {
          totalTukang += rows.dataWorker.total_worker
        })
      })
      return totalTukang
    },
    totalSesi () {
      let x = 0
      this.detailsProject.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          x += (result.price * result.dataWorker.total_worker)
        })
      })
      return x
    },
    amountAfterTax () {
      let totalTukang = 0
      this.detailsProject.schedule.forEach((row, index) => {
        row.detail_sesi.forEach((rows, idx) => {
          totalTukang += rows.dataWorker.total_worker
        })
      })

      const amountProject = this.detailsProject.amount
      const afterPajak = (amountProject) - parseInt(this.detailsProject.fee_charge_building) * parseInt(totalTukang)
      return afterPajak
    },
    invNumber () {
      const min = 0
      const max = 500000

      const Mins = Math.ceil(min)
      const Maxs = Math.floor(max)

      const num = Math.floor(Math.random() * (Maxs - Mins + 1)) + Mins
      return num.toString().padStart(5, '0')
    }
  },
  components: {},
  methods: {
    convertToRoman (date) {
      let monthNum = moment().get(date).format('M')
      var roman =
      {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1
      }
      var str = ''
      for (var i of Object.keys(roman)) {
        var q = Math.floor(monthNum / roman[i])
        monthNum -= q * roman[i]
        str += i.repeat(q)
      }
      return str
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    exportPDF () {
      const date = moment(new Date()).format('DD MMMM YYYY')
      html2pdf(this.$refs.document, {
        margin: 0,
        filename: 'FNC-TKI/INV/' + date + '.pdf',
        image: { type: 'jpeg', quality: 10 },
        enableLinks: false,
        html2canvas: { scale: 1, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
      })
    },
    getDetails () {
      axios.get(this.url + '/api/v1/vendor/project_detail_sesi?project_id=' + this.project)
        .then((cb) => {
          this.detailsProject = cb.data.data[0]
          this.statusProject = cb.data.data[0].project_status
        })
    }
  },
  created () {
    this.getDetails()
  }
}
</script>

<style lang="scss" scoped>
.pdf {
  background-color: white;
  align-items: center;
}

.watermarkCancel {
  background:url("../../assets/Dibatalkan.png") center center no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermarkPaid {
  background:url("../../assets/Lunas.png") center center no-repeat;
  top: 2rem;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
