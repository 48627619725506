<template>
  <div>
    <div class="container is-max-desktop">
      <div class="level" style="margin-top: 20px;">
        <div class="level-left">
          <b-button @click="kembaliKeProyek()" type="is-light" size="is-small" icon-left="arrow-left"></b-button>
          <h1 class="subtitle is-6" style="margin-top: 5px; margin-left: 10px;">
            Kembali ke proyek
          </h1>
        </div>
        <div class="level-right">
          <div class="title is-4 has-text-weight-light">Buat Proyek</div>
        </div>
      </div>
    </div>
    <div style="border-bottom: solid thin #f0f0f0; width: 100%; height: 20px; margin-bottom: 20px;"></div>

    <div>
      <div class="container is-max-desktop">
        <div class="input-card">
          <div style="margin-bottom: 20px;" class="is-size-5">ID Transaksi Barang</div>
          <b-field style="max-width: 50%;">
            <b-input v-model="idtransaction_item" placeholder="#" />
          </b-field>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="!viewState" class="container is-max-desktop">
        <div class="input-card" style="margin-top: 20px;">
          <div>
            Customer
          </div>
          <div class="columns" v-if="newProject && newProject.customer_name" style="padding-top: 10px;">
            <div class="column is-four-fifths">
              <h1 class="title is-5"> {{ newProject.customer_name }} ( {{ newProject.cell_phone }} ) </h1>
            </div>
            <div class="column">
              <b-button type="is-success" icon-left="account" @click="modal.customerIsView=true">Ubah</b-button>
            </div>
          </div>
          <div style="text-align: center; margin-top: 40px;">
            <b-button v-if='!newProject || !newProject.customer_name' @click="modal.customerIsView=true" type="is-success" style="width: 300px;" icon-left="account-alert-outline">
              Tentukan Customer
            </b-button>
          </div>
        </div>
        <div style="text-align: center;">
          <b-icon style="color: whitesmoke;" size="is-medium" icon="arrow-down-thin-circle-outline"></b-icon>
        </div>
        <div class="input-card" style="margin-top: 20px;">
          <div style="margin-bottom: 10px;">
            Produk Yang Dikerjakan
          </div>
          <div>
            <div style="margin-top: 40px;">
              <div v-if="productServiceSelected.length > 0">
                <div class="columns" v-for="(v, idx) in productServiceSelected" :key="'idxprod_' + idx">
                  <div class="column is-four-fifths">
                    <div style="display: flex;">
                      <div>
                        <img style="width: 60px; border-radius: 5px;" :src="v.product.iamge_uri" />
                      </div>
                      <div style="padding-left: 20px; padding-top: 10px;">
                        <strong>{{ v.product.name }}</strong> <br/>
                        Layanan: {{ v.service.name }}
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <b-button @click="editProductOnShow(idx)" type="is-success" style="margin-right: 5px;">Edit</b-button>
                    <b-button @click="deleteProductList(idx)" type="is-danger" icon-left="delete" />
                  </div>
                </div>
              </div>
              <div style="text-align: center;">
                <b-button @click="modal.listProductIsView = true" type="is-success" style="width: 300px;" icon-left="basket-plus">
                  Tambah Produk
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <b-icon style="color: whitesmoke;" size="is-medium" icon="arrow-down-thin-circle-outline"></b-icon>
        </div>
        <!-- <div class="input-card" style="margin-top: 20px;">
          <div style="margin-bottom: 10px;"> Penugasan Tukang </div>
          <div v-if="productServiceSelected.length > 0">
            <div v-for="(v, _idxx) in productServiceSelected" :key="'prod_' + _idxx">
              <div v-for="(lay, _idx) in v.skills" :key="_idxx + '_skl_' + _idx" style="display: flex; height: auto; border-bottom: solid thin #f0f0f0; padding: 10px; margin-bottom: 10px;">
                <div style="width: 80px; text-align: center;">
                  <img style="width: 30px; height: 30px; border-radius: 5px;" :src="lay.image_uri" />
                </div>
                <div style="width: 100%;">
                  <p class="title is-6">{{ lay.name }}</p>
                </div>
                <div style="width: 250px;">
                  <b-field>
                    <b-numberinput :min="0" v-model="lay.order_tukang" type="is-light" controls-rounded></b-numberinput>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="input-card" style="margin-top: 20px;">
          <div> Alamat Pengerjaan </div>
          <div style="display: flex;" v-if="newProject && newProject.address">
            <div style="padding-top: 20px; width: 100%;">
              <div style="margin-right: 20px;" v-if="newProject && newProject.latitude && newProject.longitude">
                <GmapMap map-type-id="roadmap" :zoom="15"
                  :center="map.currentPlace"
                  :options="{disableDefaultUI: true}"
                  style="width: 100%; height: 200px;">
                  <GmapMarker :position="map.currentPlace">
                  </GmapMarker>
                </GmapMap>
              </div>
              <div style="padding: 20px 20px 0px 0px;">
                {{ newProject.address }}
                <br/>
                Bangunan: <strong>{{ newProject.jenis_bangunan }}</strong>
              </div>
            </div>
            <div style="width: 220px; padding-top: 20px;">
              <b-button @click="modal.tentukanAlamatIsView=true" style="width: 200px;">Ubah</b-button>
            </div>
          </div>
          <div style="text-align: center; margin-top: 40px;">
            <b-button v-if="!newProject || !newProject.address" @click="modal.tentukanAlamatIsView=true"
              type="is-success" style="width: 300px;" icon-left="map-marker">
              Tentukan Alamat
            </b-button>
          </div>
        </div>
        <div style="text-align: center;">
          <b-icon style="color: whitesmoke;" size="is-medium" icon="arrow-down-thin-circle-outline"></b-icon>
        </div>
        <div class="input-card" style="margin-top: 20px;">
          <div> Tanggal Pengerjaan </div>
          <div class="level" v-if="newProject && newProject.detail_sesi.length > 0">
            <div class="level-left">
              <table class="table" style="margin-top: 20px;">
                <tbody>
                  <tr style="color: grey;" v-for="(v, i) in newProject.detail_sesi" :key="i">
                    <td>{{ v.day }}</td>
                    <td>{{ v.date }}</td>
                    <td>{{ v.sesi_name }}</td>
                    <td>{{ v.skill_name }}</td>
                    <td>Rp.{{ v.price | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="level-right">
              <b-button @click="tentukanJadwalOnShow()" style="width: 200px;">Ubah</b-button>
            </div>
          </div>
          <div v-if="newProject && newProject.detail_sesi.length === 0" style="text-align: center; margin-top: 40px;">
            <b-button @click="tentukanJadwalOnShow()" type="is-success" style="width: 300px;" icon-left="calendar-multiselect">
              Tentukan Jadwal
            </b-button>
          </div>
        </div>
        <div style="text-align: center;">
          <b-icon style="color: whitesmoke;" size="is-medium" icon="arrow-down-thin-circle-outline"></b-icon>
        </div>
        <div class="input-card" style="margin-top: 20px;">
          <div>
            Deskripsi Pekerjaan
          </div>
          <div style="margin-top: 25px;">
            <b-input v-model="deskripsiPekerjaan" rows="2" maxlength="200" type="textarea"></b-input>
          </div>
        </div>
        <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
          <b-button @click="createProjectConfirm()" type="is-primary" style="width: 300px;" icon-right="arrow-right"> Lanjutkan </b-button>
        </div>
      </div>
    </transition>

    <!-- pilih customer -->
    <b-modal :width="500" v-model="modal.customerIsView">
      <div class="card" style="padding-bottom: 20px;">
        <project-customer @catchvalue="insertCustomer" />
      </div>
    </b-modal>

    <!-- pilih alamat pengerjaan -->
    <b-modal v-model="modal.tentukanAlamatIsView" :width="640" scroll="keep">
    <div class="card">
      <div class="card-content">
        <b-field label="Lokasi di Peta">
          <div style="display: flex;">
            <div v-if="map.currentPlace.lat && map.mapSelectedIsShow">
              <GmapMap map-type-id="roadmap" :zoom="15"
                :center="map.currentPlace"
                :options="{disableDefaultUI: true}"
                style="width: 450px; height: 150px">
                <GmapMarker :position="map.currentPlace">
                </GmapMarker>
              </GmapMap>
            </div>
            <div>
              <b-button @click="mapOnViewClick()" type="is-success" icon-left="map-marker-plus"> Pilih Lokasi </b-button>
            </div>
          </div>
        </b-field>
        <div>
          <b-field label="Alamat">
            <b-input disabled v-model="modal.addressRef" rows="2" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Deskripsi Lokasi">
            <b-input v-model="modal.address" rows="2" maxlength="200" type="textarea"></b-input>
          </b-field>
        </div>
        <b-field label="Jenis Bangunan">
          <div class="columns is-gapless">
            <div v-for="(v, i) in buildingType" :key="i" class="column">
              <div @click="setBuildingType(i)" :class="{'jenis-bangunan-selected': v.isSelect === true}" class="card jenis-bangunan">
                <div class="card-content justify-center">
                  <img class="image is-32x32 justify-center" :src="v.image_uri" />
                  {{ v.name }} <br/>
                  <small>Rp.{{ v.fee_charge | numFormat }}</small>
                </div>
              </div>
            </div>
          </div>
        </b-field>
        <div class="content" style="text-align: right; margin-top: 20px;">
          <b-button @click="insertAddress()" style="width: 200px;" :type="{'is-light': modal.address === '' && map.currentPlace.lat === null, 'is-success' : modal.address !== '' && map.currentPlace.lat !== null }">
            Simpan
          </b-button>
        </div>
      </div>
    </div>
    </b-modal>

    <!-- maps -->
    <b-modal v-model="modal.isMapOnView">
      <div class="card">
        <div class="card-content">
          <b-field label="Cari Alamat">
            <b-autocomplete
              :data="map.address"
              placeholder="Telusuri Alamat"
              field="title"
              :loading="map.isFetchingAddress"
              @typing="getAddress"
              @select="option => map.addressSelected = option">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small>
                      {{ props.option.address }}
                    </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <GmapMap map-type-id="roadmap" :zoom="15"
            :center="map.gmapCenter"
            style="width: auto; height: 400px">
            <GmapInfoWindow :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
              <div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>
            </GmapInfoWindow>
            <GmapMarker :position="map.markerPosition" :clickable="true"
              @dragend="updateCoordinate"
              @click="mapMarkerOnClick()"
              :draggable="true">
            </GmapMarker>
          </GmapMap>
        </div>
      </div>
    </b-modal>

    <!-- pilih tanggal pengerjaan -->
    <b-modal v-model="modal.isTentukanJadwal" :width="640" scroll="keep">
      <div class="card">
        <div style="border-bottom: solid thin whitesmoke; height: 40px;">
          <p class="title is-5" style="text-align: center; margin-bottom: 0px; margin-top: 20px;">Jadwal Pengerjaan</p>
        </div>
        <div class="card-content">
          <div class="content">
            <v-date-picker
              :min-date='minDateOrder'
              @input="onDateRangeChange" is-range :columns="modal.dateColumn" value="range" is-expanded :attributes="dateAttributes" @dayclick="onDayClick" />
          </div>
          <div class="content" style="text-align: right;">
            <b-button @click="inputSesi()" style="width: 200px;" :type="{'is-light' : days.length === 0, 'is-success' : days.length > 1 }">Simpan</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="modal.isPenugasanTukangIsView" :width="1024"></b-modal>

    <!-- jika saldo tidak cukup -->
    <b-modal v-model="modal.isSaldoTidakCukup" :width="450">
      <div class="card">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Informasi</h1>
        </div>
        <div class="card-content" style="text-align: center;">
          <img style="width: 150px;" src="../../assets/saldo-tidak-cukup.png" />
          <div style="font-weight: bold;"> Saldo tidak mencukupi! </div>
          <div> Silakan top up saldo terlebih dahulu, kemudian klik tombol buat proyek lagi </div>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <b-button type="is-success" @click="topupSaldo()" style="width: 200px;">Top Up Saldo</b-button>
          </div>
          <div style="margin-top: 10px; margin-bottom: 10px;">
            <b-button type="is-danger" @click="modal.isSaldoTidakCukup = false" style="width: 200px;">Kembali</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- pilih sesi pengerjaan -->
    <b-modal v-model="modal.isSesi" :width="1400" scroll="keep">
      <div class="card">
        <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
          <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Sesi Pengerjaan</h1>
        </div>
        <div class="card-content">
          <table class="table" style="width: 100%;">
            <thead class="is-title-5">
              <tr>
                <td><h5 class="title is-5">Tanggal Pengerjaan</h5></td>
                <td><h5 class="title is-5">Nama Produk</h5></td>
                <td><h5 class="title is-5">Penugasan Tukang & Sesi</h5></td>
                <td></td>
              </tr>
            </thead>

            <tbody v-for="(value, index) in filter" v-bind:key="index">
              <tr>
                <td class="is-size-6 has-text-weight-bold">{{ convDate(value.id) }}</td>
                <div v-for="(n) in value.productSel" v-bind:key="n.name_product">
                  <td class="is-size-6 has-text-weight-bold">{{ n.name_product }}</td>
                </div>
                <!-- <td>
                  <table>
                    <tbody>
                      <tr v-for="(nn, i) in value.tukangSel" :key="'C'+ i">
                        <td>{{ nn.skill_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td> -->
                <td>
                  <table>
                    <tbody>
                      <tr v-for="(nnn, i) in value.detail" :key="'D'+ i">
                        <td>{{ nnn.skill_name }}</td>
                        <td>{{ nnn.sesi_name }}</td>
                        <td>Rp.{{ nnn.price | numFormat }}</td>
                        <td>{{ nnn.start }} - {{ nnn.end }}</td>
                        <td>
                          <b-checkbox class="is-center" v-bind:value="nnn" v-model.number="sessionSel" :native-value="nnn"></b-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="columns is-mobile m-3">
          <div class="column is-4 is-offset-8">
            <b-button @click="buildSesi()" type="is-primary" style="width: 100%;">Simpan</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- product search view page -->
    <b-modal v-model="modal.listProductIsView" :width="800">
      <div class="card">
        <product-list v-on:productonclick="productListOnClick" />
      </div>
    </b-modal>

    <!-- page konfrim project -->
    <transition name="fade">
      <div v-show="viewState" class="input-card container is-max-desktop">
        <div class="level" style="margin-bottom: 40px;">
          <!-- <div class="level-left">
            <b-button @click="createProjectConfirm()" type="is-success" icon-right="arrow-left" />
          </div> -->
          <div class="level-center">
            <h1 class="title is-4">Konfirmasi Proyek</h1>
          </div>
        </div>
        <div>
          <div class="kang-label"> Customer </div>
          <div class="kang-val">
            <h1 class="title is-5">{{ newProject.customer_name }}</h1>
          </div>
        </div>
        <div>
          <div class="kang-label"> Produk Yang Dikerjakan </div>
          <div>
            <div style="margin-top: 40px;">
              <div v-if="productServiceSelected.length > 0">
                <div v-for="(v) in productServiceSelected" :key="v.product.id">
                  <div style="display: flex;">
                    <div>
                      <img style="width: 60px; border-radius: 5px;" :src="v.product.iamge_uri" />
                    </div>
                    <div style="padding-left: 20px; padding-top: 10px;">
                      <strong>{{ v.product.name }}</strong> <br/>
                      Layanan: {{ v.service.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-card" style="margin-top: 20px;">
          <div style="margin-bottom: 10px;"> Penugasan Tukang </div>
          <div v-if="productServiceSelected.length > 0">
            <div v-for="(v) in productServiceSelected" :key="v.product.id">
              <div v-for="(lay ) in v.skills" :key="lay.id" style="display: flex; height: auto; border-bottom: solid thin #f0f0f0; padding: 10px; margin-bottom: 10px;">
                <div style="width: 80px; text-align: center;">
                  <img style="width: 30px; height: 30px; border-radius: 5px;" :src="lay.image_uri" />
                </div>
                <div style="width: 100%;">
                  <p class="title is-6">{{ lay.name }}</p>
                </div>
                <!-- <div style="width: 250px;">
                  <p class="title is-6">{{ lay.order_tukang }}</p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="kang-label"> Alamat Pengerjaan </div>
          <div class="kang-val">
            <h1 class="title is-5">{{ newProject.address }}</h1>
          </div>
          <div v-if="newProject && newProject.address">
            <div style="padding-top: 20px; padding-bottom: 20px;">
              <div v-if="newProject && newProject.latitude && newProject.longitude">
                <GmapMap map-type-id="roadmap" :zoom="15"
                  :center="map.currentPlace"
                  :options="{disableDefaultUI: true}"
                  style="width: 100%; height: 200px;">
                  <GmapMarker :position="map.currentPlace">
                  </GmapMarker>
                </GmapMap>
              </div>
            </div>
          </div>
        </div>
        <!-- <div>
          <div class="kang-label"> Tanggal Pengerjaan </div>
          <div style="margin-top: 10px;" v-if="newProject && newProject.detail_sesi.length > 0">
            <div>
              <table class="table" style="width: 100%;">
                <tbody>
                  <tr v-for="(v, i) in newProject.detail_sesi" :key="i">
                    <td>{{ v.day }}</td>
                    <td>{{ v.date }}</td>
                    <td>{{ v.sesi_name }}</td>
                    <td>Rp. {{ v.price | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> -->
        <div>
          <div class="kang-label"> Deskripsi Pekerjaan </div>
          <div class="kang-val">
            <h1 class="title is-5">{{ newProject.description }}</h1>
          </div>
        </div>
        <div>
          <div class="kang-label">Rincian Biaya</div>
          <div style="margin-top: 10px;" v-if="newProject && newProject.detail_sesi.length > 0">
            <div>
              <table class="table" style="width: 100%;">
                <thead>
                  <tr>
                    <td class="has-text-weight-bold">Tanggal</td>
                    <td class="has-text-weight-bold">Sesi</td>
                    <td class="has-text-weight-bold">Harga Sesi</td>
                    <!-- <td class="has-text-weight-bold">Total Tukang</td> -->
                    <td class="has-text-weight-bold">Jumlah</td>
                  </tr>
                </thead>
                <tbody v-if="dataCalculation !== null">
                  <tr v-for="(vv, ii) in dataCalculation.details" :key="ii">
                    <td>{{ vv.day }},{{ convDate(vv.date) }}</td>
                    <td>{{ vv.sesi_name }} - ({{ vv.start }} - {{ vv.end }})</td>
                    <td>Rp.{{ vv.price | numFormat }}</td>
                    <!-- <td class="has-text-centered">{{ vv.workerCount }}</td> -->
                    <td>Rp.{{ (vv.price) | numFormat }}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="has-text-right has-text-weight-bold">Sub Total</td>
                    <td>Rp.{{ dataCalculation.amount | numFormat}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="has-text-right has-text-weight-bold">Tax</td>
                    <td>Rp.{{ dataCalculation.pph | numFormat}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="has-text-right has-text-weight-bold">Total Setelah Dikurangi Pajak</td>
                    <td>Rp.{{ dataCalculation.amount_after_tax | numFormat}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="has-text-right has-text-weight-bold">Biaya Parkir</td>
                    <td>Rp.{{ dataCalculation.fee_building | numFormat }}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="has-text-right has-text-weight-bold">Total</td>
                    <td>Rp.{{ dataCalculation.net_amount | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="dataCalculation !== null">
          <div class="kang-label"> Total Biaya </div>
          <div style="margin-top: 10px;">
            <div class="title is-5">
              Rp.{{ dataCalculation.net_amount | numFormat }}
            </div>
          </div>
        </div>
        <!-- <div>
          <div class="kang-label">
            Total Biaya
          </div>
          <div style="margin-top: 10px;">
            <div class="title is-5">
              Rp. {{ newProject.amount | numFormat }}
            </div>
          </div>
        </div> -->
        <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
          <b-button @click.prevent="createProject()" type="is-primary" style="width: 300px;"> Buat Proyek </b-button>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import guard from '../../routerGuard'

import axios from 'axios'
import moment from 'moment'
import ProjectCustomer from './Customer.vue'
import ProductList from '../product/ProductList.vue'

export default {
  name: 'ProjectInput',
  computed: {
    ...mapGetters({
      // dateSesi: 'project/getSesi',
      newProject: 'project/getNewProject',
      user: 'auth/user',
      url: 'setup/getUrl'
    }),
    dates () {
      return this.days.map(day => day.date)
    },
    dateAttributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    filter () {
      return [...new Map(this.listSesi.map(item => [item.id, item])).values()]
    },
    listSesi () {
      var x = []
      const sesi = []

      if (this.days.length >= 2) {
        const start = moment(this.days[0].date)
        const end = moment(this.days[1].date)
        for (var m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
          const _id = moment(m).format('YYYY-MM-DD')
          x.push({ id: _id, date: m.valueOf() })
        }

        x.forEach((v) => {
          const day = {
            id: v.id,
            selected: [],
            dateSel: [],
            productSel: [],
            tukangSel: [],
            sesiSel: [],
            detail: []
          }

          if (this.productServiceSelected.length > 0) {
            this.productServiceSelected.forEach((s) => {
              const _day = moment(v.date).weekday()
              const val = 'Minggu,Senin,Selasa,Rabu,Kamis,Jumat,Sabtu'.split(',')

              day.dateSel.push({
                date: v.id,
                day: val[_day]
              })

              day.productSel.push({
                name_product: s.product.name
              })

              s.service.skill_list.forEach((t) => {
                day.tukangSel.push({
                  skill_name: t.name
                })

                t.price_skill_sesi.forEach((j) => {
                  day.sesiSel.push({
                    sesi_id: j.sesi_id,
                    sesi_name: j.sesi_name,
                    price: j.price,
                    start: j.start_hour,
                    end: j.end_hour
                  })

                  day.detail.push({
                    date: v.id,
                    name_product: s.product.name,
                    skill_name: t.name,
                    day: val[_day],
                    sesi_id: j.sesi_id,
                    name: t.name,
                    sesi: j.sesi,
                    sesi_name: j.sesi_name,
                    price: j.price,
                    start: j.start_hour,
                    end: j.end_hour
                  })
                })
              })
              sesi.push(day)
            })
          }
        })
        return sesi
      } else {
        return []
      }
    },
    hasDataProduct () {
      if (_.has(this.newProject, 'transactionProduct.transaction')) {
        if (this.newProject && this.newProject.transactionProduct.transaction !== null) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    totalTukangSelected () {
      let total = 0
      this.productServiceSelected.forEach((row) => {
        row.skills.forEach((_row) => {
          total += _row.order_tukang
        })
      })
      return total
    }
  },
  components: {
    ProjectCustomer,
    ProductList
  },
  watch: {
    deskripsiPekerjaan (newVal, oldVal) {
      this.$store.commit('project/setDescription', newVal.trim())
    },
    'map.addressSelected' (newVal, oldVal) {
      this.refreshAllCoordinate(newVal)
    }
  },
  data () {
    return {
      sessionSel: [],
      selectedSesi: '',
      days: [],
      idtransaction_item: null,
      viewState: false,
      modal: {
        customerIsView: false,
        pilihProductIsView: false,
        tentukanAlamatIsView: false,
        isMapOnView: false,
        isTentukanJadwal: false,
        dateColumn: 2,
        isPenugasanTukangIsView: false,
        transactionProducts: [],
        isSesi: false,
        address: '',
        addressRef: '',
        listProductIsView: false,
        isSaldoTidakCukup: false
      },
      fetchProductIsAuto: false,
      productToDo: [],
      productServiceRelated: [],
      productServiceSelected: [],
      pilihProductOnEdit: false,
      pilihProductEditIndex: -1,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      },
      deskripsiPekerjaan: '',
      buildingType: [],
      isCustomerNew: false,
      project: null,
      minDateOrder: null,
      dataCalculation: null
    }
  },
  methods: {
    limitArray (arr, length = 3) {
      if (arr && arr.length) {
        if (length === -1) {
          return arr
        }
        if (length > arr.length) {
          return arr
        }
        return arr.slice(0, length)
      }
      return null
    },
    topupSaldo () {
      this.modal.isSaldoTidakCukup = false
    },
    setBuildingType (idx) {
      this.buildingType.forEach((rw, _idx) => {
        if (idx === _idx) {
          this.buildingType[_idx].isSelect = !this.buildingType[_idx].isSelect
        } else {
          this.buildingType[_idx].isSelect = false
        }
      })
    },
    loadBuildingType () {
      axios.get(this.url + '/api/v1/vendor/building_type')
        .then((cb) => {
          const x = []
          cb.data.data.forEach((rw) => {
            const r = rw
            r.isSelect = false
            x.push(r)
          })
          this.buildingType = x
        })
        .catch((err) => {
          console.log(err)
        })
    },
    simpanLokasiOnClick () {
      this.modal.isMapOnView = false
    },
    mapMarkerOnClick () {
      this.infoWindowPos = this.map.currentPlace
      // this.infoOptions.content = '<div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>'
      this.infoWinOpen = !this.infoWinOpen

      const latLngObj = {
        lat: this.map.markerPosition.lat,
        lng: this.map.markerPosition.lng
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
      this.map.mapSelectedIsShow = true
      // this.modal.isMapOnView = false
    },
    editProductOnShow (idx) {
      this.pilihProductOnEdit = true
      this.pilihProductEditIndex = idx
      this.modal.listProductIsView = true
    },
    tentukanJadwalOnShow () {
      this.modal.isTentukanJadwal = true
      this.days = []
      this.sessionSel.splice(0)
    },
    deleteProductList (idx) {
      this.productServiceSelected.splice(idx, 1)
    },
    kembaliKeProyek () {
      this.$buefy.dialog.confirm({
        message: '<span class="subtitle is-5 has-text-weight-bold">Batalkan pembuatan proyek?</span> <br> <p class="subtitle is-7">Membatalkan akan menghapus inputan</p>',
        onConfirm: () => this.$router.push({ name: 'ProjectList' })
      })
    },
    validateProduct () {},
    productListOnClick (val) {
      this.x = val
      this.productServiceSelected.forEach((rw, _idx) => {
        if (this.x.product.id === rw.product.id) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Produk Sudah Terpilih!!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.productServiceSelected.splice(_idx, 1)
          return false
        }
      })

      if (this.pilihProductOnEdit === false) {
        this.productServiceSelected.push(val)
      }

      if (this.pilihProductOnEdit === true) {
        this.productServiceSelected.splice(this.pilihProductEditIndex, 1)
        this.productServiceSelected.push(val)
      }

      this.modal.listProductIsView = false
      this.pilihProductOnEdit = false
      this.pilihProductEditIndex = -1
      // this.buildServiceRelated()
    },
    productListRemove (idx) {
      this.productToDo.splice(idx, 1)
      // this.buildServiceRelated()
    },
    buildServiceRelated () {
      this.productToDo.forEach((row) => {
        row.worker_primary.forEach((wp) => {
          if (!_.find(this.productServiceRelated, { skill_id: wp.skill_id })) {
            const _l = JSON.parse(JSON.stringify(wp))
            _l.order_tukang = 1
            this.productServiceRelated.push(_l)
          }
        })
        row.worker_optional.forEach((wp) => {
          if (!_.find(this.productServiceRelated, { skill_id: wp.skill_id })) {
            const _l = JSON.parse(JSON.stringify(wp))
            _l.order_tukang = 1
            this.productServiceRelated.push(_l)
          }
        })
      })
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    convDateLong (date) {
      return moment(date).format('DD MMMM')
    },
    setSchedule () {
      const skills = []
      const products = []
      const schedules = []
      this.productServiceSelected.forEach((row) => {
        row.skills.forEach((_row) => {
          skills.push({
            skill_id: _row.id,
            skill_name: _row.name,
            order_tukang: _row.order_tukang
          })
        })
        products.push({
          product_id: row.product.id,
          product_name: row.product.name
          // service_id: row.service.id,
          // service_name: row.service.name
        })
      })
      this.newProject.detail_sesi.forEach((row) => {
        schedules.push({
          date_sesi: row.date,
          sesi_id: row.sesi_id
        })
      })
      this.$store.commit('project/setSchedule', {
        skills: skills,
        products: products,
        schedules: schedules
      })
    },
    gettingImageProducts () {
      _.forEach(this.modal.transactionProducts, (v, i) => {
        setTimeout(() => {
          axios.get(v.i_uri)
            .then((cb) => {
              this.modal.transactionProducts[i].image_uri = cb.data.data.url
            })
        }, 500)
      })
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      // this.updateCoordinate()
      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon
      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
    },
    getAddressMap (location) {
      // const geocoder = this.google.maps.Geocoder()
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
        console.log(response.results)
      })
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    pilihLokasiOnClick () {},
    getAddress (val) {
      axios.get(this.url + '/api/v1/vendor/map?place=' + val)
        .then((cb) => {
          this.map.address = cb.data.data
        })
    },
    onDateRangeChange (day) {
      this.days = []
      this.days.push({
        id: moment(day.start).format('YYYY-MM-DD'),
        date: moment(day.start).toDate()
      })
      this.days.push({
        id: moment(day.end).format('YYYY-MM-DD'),
        date: moment(day.end).toDate()
      })
    },
    onDayClick (day) {},
    insertCustomer (val) {
      this.$store.commit('project/setCustomer', val)
      this.$store.commit('project/setCustomerId', val.id)
      this.modal.customerIsView = false
    },
    insertTransactionProduct () {
      const p = []
      this.modal.transactionProducts.forEach((val) => {
        if (val.is_select) {
          p.push(JSON.parse(JSON.stringify(val)))
        }
      })
      const transaction = {
        id: '123123',
        products: p
      }
      this.$store.commit('project/setTransactionProductSelected', transaction)
      this.modal.pilihProductIsView = false
    },
    insertAddress () {
      if (this.modal.address.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Harap detail <b>alamat customer</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      let jenisBangunan = null
      let buildingId = null
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          jenisBangunan = rw.name
          buildingId = rw.id
        }
      })

      const addr = {
        address: this.modal.addressRef + ' + ' + this.modal.address,
        lat: this.map.currentPlace.lat,
        lng: this.map.currentPlace.lng,
        jenis_bangunan: jenisBangunan,
        building_id: buildingId
      }

      if (addr.jenis_bangunan == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Jenis Bangunan Harus Pilih!!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.$store.commit('project/setAddress', addr)
      this.modal.tentukanAlamatIsView = false
    },
    inputSesi () {
      this.modal.isTentukanJadwal = false
      this.modal.isSesi = true
    },
    buildSesi () {
      let countSesi = 0
      this.$store.commit('project/clearDetailSesi')
      this.selectedSesi = ''

      // validasi jika kosong
      if (!this.sessionSel.length) {
        this.$store.commit('project/clearDetailSesi')
        this.$buefy.toast.open({
          duration: 5000,
          message: 'pilihan sesi harus di isi!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.sessionSel.forEach((vals) => {
        if (!_.isEmpty(vals)) {
          countSesi += 1
          const _sel = JSON.parse(JSON.stringify(vals))
          if (vals.sesi !== null) {
            _sel.harga_tukang = 0
          }
          _sel.total_tukang = 0
          this.$store.commit('project/setDetailSesi', vals)
        }
      })

      const x = [...new Set(this.days.map(m => m.id))]
      const y = x.length

      // validasi multiple day dan belum terceklis
      if (countSesi < y) {
        this.$store.commit('project/clearDetailSesi')
        this.$buefy.toast.open({
          duration: 3000,
          message: 'pilihan sesi harus sama dengan tanggal yang dipilih!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      // if (countSesi !== this.sessionSel.length) {
      //   this.$store.commit('project/clearDetailSesi')
      //   this.$buefy.toast.open({
      //     duration: 3000,
      //     message: 'pilihan sesi harus sama dengan tanggal yang dipilih!',
      //     position: 'is-bottom',
      //     type: 'is-danger'
      //   })
      //   return
      // }

      this.modal.isTentukanJadwal = false
      this.modal.isSesi = false
    },
    hitungAmount () {
      let amount = 0
      let orderTukang = 0
      let hargaSesi = 0
      let feeCharge = 0
      let feeBuilding = 0
      // let totalSesi = 0

      const startDate = this.newProject.detail_sesi[0].date
      const endDate = this.newProject.detail_sesi[this.newProject.detail_sesi.length - 1].date
      const dateCount = moment(endDate).diff(moment(startDate), 'days') + 1

      // total sesi
      // totalSesi = this.newProject.detail_sesi.length

      // total harga perhari
      this.newProject.detail_sesi.forEach((row) => {
        if (row.sesi !== null) {
          hargaSesi += row.price
        }
      })

      // total tukang
      this.productServiceSelected.forEach((row) => {
        row.skills.forEach((_row) => {
          row.service.skill_list.forEach((_row2) => {
            if (_row2.id === _row.id) {
              orderTukang += _row.order_tukang
            }
          })
        })
      })

      // fee_charge building type
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          feeCharge = rw.fee_charge
        }
      })

      amount = ((hargaSesi) + ((feeCharge * orderTukang) * dateCount))
      feeBuilding = ((feeCharge * orderTukang) * dateCount)

      return {
        amount: amount,
        orderTukang: orderTukang,
        startDate: startDate,
        endDate: endDate,
        dateCount: dateCount,
        fee_building: feeBuilding
      }
    },
    setServiceId () {
      const x = []
      this.productServiceSelected.forEach((row) => {
        x.push(row.service.id)
      })
      this.$store.commit('project/setServiceId', x)
    },
    setProductId () {
      const x = []
      this.productServiceSelected.forEach((row) => {
        x.push(row.product.id)
      })
      this.$store.commit('project/setProductId', x)
    },
    setInfoDate () {
      const startDate = this.newProject.detail_sesi[0].date
      const endDate = this.newProject.detail_sesi[this.newProject.detail_sesi.length - 1].date
      const duration = moment(endDate).diff(moment(startDate), 'days') + 1
      this.$store.commit('project/setInfoDate', {
        start_date: startDate,
        end_date: endDate,
        duration: duration
      })
    },
    createProject () {
      axios.post(this.url + '/api/v1/vendor/create_project_khusus', this.newProject)
        .then((res) => {
          /** check sisa saldo **/
          if (this.user !== null && this.user !== undefined) {
            axios.get(this.url + '/api/v1/vendor/ending_balance')
              .then((data) => {
                this.$store.commit('setup/setUserSaldo', data.data.data)
                this.$router.push({ name: 'Home' })
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.$router.push({ name: 'Home' })
          }

          this.$buefy.toast.open({
            duration: 4000,
            message: 'Pembuatan project baru berhasil!',
            position: 'is-bottom',
            type: 'is-info'
          })
        })
        .catch((err) => {
          console.log(err)

          if (err.response.data.status === 400) {
            this.modal.isSaldoTidakCukup = true
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error create project!....',
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        })
    },
    createProjectConfirm () {
      if (this.idtransaction_item === null || this.idtransaction_item.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'ID Transaksi barang harus diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      if (this.newProject === undefined) {
        return
      }
      if (this.newProject.customer_name.trim() === '' ||
        this.newProject.jenis_bangunan.trim() === '' ||
        this.newProject.detail_sesi.length === 0 ||
        this.newProject.address.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Mohon lengkapi dulu data yang harus diinput',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      const n = this.hitungAmount()
      this.$store.commit('project/setAmount', {
        amount: n.amount,
        net_amount: n.amount,
        fee_building: n.fee_building
      })
      // this.setItem()
      this.setServiceId()
      this.setProductId()
      this.setInfoDate()
      this.setSchedule()
      this.$store.commit('project/setInfo', {
        idtransaction_item: this.idtransaction_item,
        user_id: this.user.id,
        transaction_id: uuidv4()
      })
      if (this.deskripsiPekerjaan.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Mohon informasi <b>Deskripsi Pekerjaan</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      axios.post(this.url + '/api/v1/vendor/price_calculation', this.newProject)
        .then((dt) => {
          this.dataCalculation = dt.data.data
          this.viewState = !this.viewState
        })
        .catch((err) => {
          console.log(err.response.data.message)
        })

      // this.viewState = !this.viewState
    },
    mapOnViewClick () {
      if (this.map.mapSelectedIsShow === true) {
        const x = JSON.parse(JSON.stringify(this.map.currentPlace))
        this.map.markerPosition = x
        this.map.gmapCenter = x
      }
      this.infoWinOpen = false
      this.modal.isMapOnView = true
    }
  },
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
    this.minDateOrder = moment().add(1, 'days').toDate()
    const now = moment()
    if (now.hour() >= 18) {
      this.minDateOrder = moment().add(2, 'days').toDate()
    }
    // this.newProject = this.setProject()
    this.$store.dispatch('project/setNewProject')
    this.loadBuildingType()
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>

.input-card {
  border: solid thin #f0f0f0;
  border-radius: 10px;
  padding: 20px 25px 20px 25px;
  margin-bottom: 20px;
  div {
    color: grey;
  }
}
.kang-label {
  margin-top: 20px;
}
.kang-val {
  margin-top: 5px;
}
.jenis-bangunan {
  cursor: pointer;
  &:hover {
    background-color: rgb(196, 196, 196);
  }
}
.jenis-bangunan-selected {
  background-color: rgb(196, 196, 196);
}
</style>
