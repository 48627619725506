<template>
  <div>
    <b-table
      :total="paginate.total"
      :per-page="paginate.perPage"
      :data="paginate.data"
      :loading="paginate.loading"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
      backend-pagination
      scrollable>
      <b-table-column field="project_id" label="Jenis" v-slot="props">
        Project ID #{{ props.row.project_id }}
      </b-table-column>
      <b-table-column field="timestamp" label="Timestamp" v-slot="props">
        {{ convDate(props.row.created_at) }}
      </b-table-column>
      <b-table-column field="saldo_awal" label="Saldo Awal" v-slot="props">
        {{ props.row.saldo_awal | numFormat }}
      </b-table-column>
      <b-table-column field="mutasi" label="Mutasi" v-slot="props">
        {{ props.row.mutasi | numFormat }}
      </b-table-column>
      <b-table-column field="saldo_akhir" label="Saldo Akhir" v-slot="props">
        {{ props.row.saldo_akhir | numFormat }}
      </b-table-column>
      <b-table-column field="in" label="status" v-slot="props">
        {{ props.row.status }}
      </b-table-column>
      <b-table-column field="in" label="Deskripsi" v-slot="props">
        {{ props.row.description }}
      </b-table-column>
      <b-table-column field="penanggung_jawab" label="Penanggung Jawab" v-slot="props">
        {{ props.row.penanggung_jawab }}
      </b-table-column>
      <!-- <b-table-column field="catatan" label="Catatan" v-slot="props">
        {{ props.row.catatan }}
      </b-table-column> -->

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="paginate.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'laporanJurnal',
  props: {
    date: Object
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
      }
    }
  },
  methods: {
    convDate (val) {
      return moment(val).format('DD MMMM YYYY hh:mm')
    },
    onPageChange (page) {
      this.getData(this.date, page)
    },
    getData (val, page) {
      this.paginate.loading = true
      this.paginate.page = page
      const pr = '/api/v1/vendor/report/jurnal?start_date=' + val.tgl1 + '&end_date=' + val.tgl2 + '&limit=' + this.paginate.perPage + '&page=' + this.paginate.page
      axios.get(this.url + pr)
        .then((cb) => {
          this.paginate.data = cb.data.data.list
          this.paginate.total = (cb.data.data.total_page * this.paginate.perPage)
          this.paginate.page = cb.data.data.current_page
          this.paginate.loading = false
        })
    },
    loadDataFirst (page) {
      this.paginate.loading = true
      let tgl1 = null
      let tgl2 = null
      if (this.filterTanggal === null) {
        tgl1 = moment().startOf('month').format('YYYY-MM-DD')
        tgl2 = moment().endOf('month').format('YYYY-MM-DD')
      }
      const pr = '/api/v1/vendor/report/jurnal?start_date=' + tgl1 + '&end_date=' + tgl2 + '&limit=10&page=' + page
      axios.get(this.url + pr)
        .then((cb) => {
          this.paginate.data = cb.data.data.list
          this.paginate.total = cb.data.data.total_page
          this.paginate.page = cb.data.data.current_page
          this.paginate.loading = false
        })
    }
  },
  data () {
    return {
      filterTanggal: null,
      valueDateFilter: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      }
    }
  },
  created () {}
}
</script>
