<template>
  <transition name="fadeDown">
    <div class="dash-container">
      <section style="margin-bottom: 20px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                <b-icon icon="cog" /> &nbsp; Pengaturan
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
        <div class="level-left">
          <div style="width: 190px;" @click="selectTab(0)" class="tab-label" :class="{'active' : activeTab === 0}">
            Produk & Layanan
          </div>
          <div @click="selectTab(1)" class="tab-label" :class="{'active' : activeTab === 1}">
            Daftar User
          </div>
          <div @click="selectTab(2)" class="tab-label" :class="{'active' : activeTab === 2}">
            User Role
          </div>
          <div @click="selectTab(6)" class="tab-label" :class="{'active' : activeTab === 6}">
            Data Permission
          </div>
          <div @click="selectTab(3)" class="tab-label" :class="{'active' : activeTab === 3}">
            Biaya Bank & PPh
          </div>
          <div @click="selectTab(4)" class="tab-label" :class="{'active' : activeTab === 4}">
            Data Sesi
          </div>
          <div @click="selectTab(5)" class="tab-label" :class="{'active' : activeTab === 5}">
            Data Skill
          </div>
          <!-- <div @click="selectTab(7)" class="tab-label" :class="{'active' : activeTab === 7}">
            Virtual Account
          </div> -->
        </div>
      </div>
      <div>
        <div v-show="activeTab===0">
          <product />
        </div>
        <div v-show="activeTab===1">
          <user />
        </div>
        <div v-show="activeTab===2">
          <jabatan />
        </div>
        <div v-show="activeTab===3">
          <biaya-bank-pph />
        </div>
        <div v-show="activeTab===4">
          <sesi />
        </div>
        <div v-show="activeTab===5">
          <skill />
        </div>
        <div v-show="activeTab===6">
          <permission />
        </div>
        <!-- <div v-show="activeTab===7">
          <virtual-account/>
        </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import Product from './Product.vue'
import User from './User.vue'
import Jabatan from './Jabatan.vue'
import BiayaBankPph from './BiayaBankPph.vue'
import Sesi from './Sesi.vue'
import Skill from './Skill.vue'
import Permission from './Permission.vue'
// import VirtualAccount from './VirtualAccountList.vue'

export default {
  name: 'PengaturanIndex',
  components: {
    Product,
    User,
    Jabatan,
    BiayaBankPph,
    Sesi,
    Skill,
    Permission
    // VirtualAccount
  },
  methods: {
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    selectTab (val) {
      this.activeTab = val
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 170px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
