<template>
  <div v-if="isLogin" class="bg-top">
    <div class="container is-fullhd">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <img alt="b2b kanggo" style="width: 200px;" src="../../assets/mitra_10_cms_logo.png">
          </div>
          <div class="level-item">
            <router-link class="level-item" to="/">Beranda</router-link>
            <router-link v-if="!menuAccess('/project/list')" class="level-item" to="/project/list">Proyek</router-link>
            <router-link v-if="!menuAccess('/penawaran')" class="level-item" to="/penawaran">Penawaran</router-link>
            <router-link v-if="!menuAccess('/laporan')" class="level-item" to="/laporan">Laporan</router-link>
            <router-link v-if="!menuAccess('/pengaturan')" class="level-item" to="/pengaturan">Pengaturan</router-link>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right" style="align-items: flex-start; font-size: 12px;">
          <div style="width: 220px; text-align: center; border-left: solid thin grey; border-right: solid thin grey; padding-top: 5px;">
            <saldo-account></saldo-account>
            <div class="columns is-gapless">
              <div class="column">
                <button :disabled="menuAccess('topup')" @click="setTopUp()" class="button is-success is-small" style="margin-bottom: 5px;">
                  <img style="width: 20px; margin-right: 5px;" src="../../assets/top_up_icon.png" />
                  Top Up Saldo
                </button>
              </div>
            </div>
          </div>

          <div style="width: 21.5rem;">
            <div class="account-nav" style="display: flex;">
              <div @click="ubahPasswordOnClick()" class="account-link is-5">UBAH PASSWORD</div>
              <div @click="logout()" class="account-link">LOGOUT</div>
            </div>
            <div style="text-align: center; padding-top: 10px; font-weight: bold;">
              <span v-if="user !== null && user !== undefined">{{ user.name }}</span>
            </div>
          </div>
        </div>
      </nav>

      <b-modal v-model="topUpSaldoVaShow" :width="550">
        <top-up-virtual-account v-on:settopupva="setTopUpVA"></top-up-virtual-account>
      </b-modal>

      <b-modal v-model="topUpSaldoIsShow" :width="500">
        <top-up-input-nominal v-on:settopup="setTopUp" v-on:isinput="topUpIsInput" />
      </b-modal>

      <b-modal v-model="topUpConfirmIsShow" :width="500">
        <top-up-confirm v-on:setubahnominal="setUbahNominal" v-on:setiya="saveTopUp" :nominal="topUpNominal" />
      </b-modal>

      <b-modal v-model="topUpConfirmInvoiceIsShow" :width="500">
        <top-up-confirm-invoice :nominal="topUpNominal" v-on:setlanjutkan="setInvoice" />
      </b-modal>

      <b-modal v-model="topUpMenungguPembayaranIsShow" :width="800">
        <top-up-menunggu-pembayaran
          v-if="paymentInfo !== null"
          :amount="paymentInfo.amount"
          :payment_link="paymentInfo.payment_link"
          :source_id="paymentInfo.source_id"
          :original_amount="paymentInfo.original_amount"
          :external_id="paymentInfo.external_id"
          :is_paid="paymentInfo.is_paid"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import SaldoAccount from '../Saldo.vue'
import TopUpConfirm from '../top-up/Confirm.vue'
import TopUpInputNominal from '../top-up/InputNominal.vue'
import TopUpConfirmInvoice from '../top-up/ConfirmInvoice.vue'
import TopUpVirtualAccount from '../top-up/TopupVirtualAccount.vue'
import TopUpMenungguPembayaran from '../top-up/MenungguPembayaran.vue'

export default {
  name: 'Navbar',
  components: {
    TopUpInputNominal,
    TopUpConfirm,
    TopUpConfirmInvoice,
    TopUpMenungguPembayaran,
    TopUpVirtualAccount,
    SaldoAccount
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user'
    })
  },
  data () {
    return {
      topUpSaldoVaShow: false,
      topUpSaldoIsShow: false,
      topUpConfirmIsShow: false,
      topUpConfirmInvoiceIsShow: false,
      topUpNominal: 0,
      topUpMenungguPembayaranIsShow: false,
      paymentInfo: null,
      saldo: null
    }
  },
  methods: {
    topUpIsInput (val) {
      this.topUpNominal = parseInt(val)
    },
    setUbahNominal () {
      this.topUpConfirmIsShow = false
      this.topUpSaldoIsShow = true
    },
    saveTopUp () {
      this.topUpConfirmIsShow = false
      this.topUpConfirmInvoiceIsShow = true
    },
    setTopUp () {
      this.topUpSaldoIsShow = false
      this.topUpConfirmIsShow = true
    },
    setTopUpVA () {
      this.topUpSaldoVaShow = false
    },
    // ENDPOINT TOPUP SALDO
    setInvoice () {
      axios.post(`${this.url}/api/v1/vendor/topup-nominal-switching`, {
        user_vendor_id: this.user.id,
        nominal: this.topUpNominal
      })
        .then((data) => {
          this.paymentInfo = data.data.data.invoice
          console.log(data.data.data.invoice)
          this.topUpMenungguPembayaranIsShow = true
          this.topUpConfirmInvoiceIsShow = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    topUpOnClick () {
      // this.topUpConfirmInvoiceIsShow = !this.topUpConfirmInvoiceIsShow
      this.topUpSaldoIsShow = !this.topUpSaldoIsShow
    },
    topUpStaticVA () {
      this.topUpSaldoVaShow = !this.topUpSaldoVaShow
    },
    approvalTopup () {
      this.$router.push({ name: 'ApprovalTopUp' })
    },
    pendingTopUp () {
      this.$router.push({ name: 'PendingTopUp' })
    },
    ubahPasswordOnClick () {
      this.$router.push({ name: 'UbahPassword' })
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login' })
    }
  },
  created () {}
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.project-btn {
  color: green;
  cursor: pointer;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.linkdisable {
  opacity: 0.5;
  pointer-events: none;
}
.bg-top {
  background-color: #ebeeff;
}
.level-item {
  color: rgb(95, 95, 95);
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  &.active {
    background: linear-gradient(to right, #FF0000,#FF1A1A, #FF3333, #ffffff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: bold;
  }
}
.right-border {
  border-right: solid thin rgb(184, 184, 184);
}
.account-nav {
  border-bottom: solid thin grey;
  font-size: 12px;
  height: 30px;
}
.account-link {
  width: 50%;
  cursor: pointer;
  padding-top: 5px;
  &:hover {
    background-color: black;
    color: white;
  }
  text-align: center;
  border-right: solid thin grey;
}
</style>
