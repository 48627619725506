<template>
  <div class="container">
    <div class="columns is-centered" style="margin-top: 40px;">
      <div class="column is-one-third" style="text-align: center; padding: 20px;">
        <img alt="b2b kanggo" style="width: 200px;" src="../../assets/mitra_10_cms_logo.png">
        <div class="card" style="text-align: left; margin-top: 10px;">
          <div style="text-align: center; margin-top: 20px;">
            <b-icon icon="account-lock-outline" size="is-large"></b-icon>
          </div>
          <div class="card-content">
            <b-field label="Email">
              <b-input type="email" v-model="email" maxlength="30"></b-input>
            </b-field>
            <b-field label="Password">
              <b-input type="password" v-model="password" password-reveal></b-input>
            </b-field>
            <!-- <div style="margin-top: 10px;"> Forget your password? </div> -->
            <div style="text-align: right; margin-top: 20px; margin-bottom: 10px;">
              <b-button @click="login()" style="width: 200px;">Log In</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isLogin: 'auth/isLogin',
      user: 'auth/user',
      token: 'auth/token'
    })
  },
  watch: {
    isLogin (newVal, oldVal) {
      if (newVal === true) {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  data () {
    return {
      email: null,
      password: null,
      branchSelected: {
        branch_id: 1, branch: 'none'
      },
      lokasiLogin: [
        { branch_id: 1, branch: 'Q-BIG BSD' },
        { branch_id: 2, branch: 'Fatmawati' },
        { branch_id: 3, branch: 'Gading Serpong' },
        { branch_id: 4, branch: 'Iskandar Bogor' },
        { branch_id: 5, branch: 'Jatimakmur' }
      ]
    }
  },
  methods: {
    login () {
      this.loading = true
      if (this.email === null && this.password === null) {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Email & Password tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      if (this.email === null) {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Email tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      if (this.password === null) {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Password tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      const userData = {
        email: this.email,
        password: this.password,
        branch: this.branchSelected,
        url: this.url
      }

      this.$store.dispatch('auth/login', userData).then((response) => {
        this.$router.push({ path: '/' })
      }).catch((error) => {
        this.$buefy.toast.open({
          duration: 2000,
          message: error.response.data.message,
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      })
    }
  },
  mounted () {
    const rs = localStorage.getItem('webapp_b2b')
    const auth = JSON.parse(rs)
    if (auth && auth.token) {
      this.$store.commit('auth/setLoginStatus', true)
      this.$router.push({ path: '/' })
    } else {
      this.$store.commit('auth/setLoginStatus', false)
    }
  }
}
</script>
