<template>
  <div>
    <b-button
      v-if="!menuAccess('/penawaran/create')"
      :disabled="menuAccess('/penawaran/create')"
      @click="createPenawaran()"
      type="is-info"
      icon-left="plus"
      outlined>
        Pesan Penawaran
    </b-button>
    &nbsp;
    <b-button
      v-if="!menuAccess('/project/input-tukang-langsung')"
      :disabled="menuAccess('/project/input-tukang-langsung')"
      @click="btnTambahProyekTukangLangsungOnClick()"
      type="is-primary"
      icon-left="plus"
      outlined>
        Pesan Tukang Langsung
    </b-button>
    &nbsp;
    <b-button
      v-if="!menuAccess('/project/input')"
      :disabled="menuAccess('/project/input')"
      @click="btnTambahProyekOnClick()"
      type="is-success"
      icon-left="plus"
      outlined>
        Pesan Tukang Khusus
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppButtonProject',
  props: {},
  computed: {
    ...mapGetters({
      roles: 'auth/roles',
      rolePath: 'auth/rolePath'
    })
  },
  methods: {
    createPenawaran () {
      this.$router.push('/penawaran/create')
    },
    btnTambahProyekTukangLangsungOnClick () {
      this.$router.push('/project/input-tukang-langsung')
    },
    btnTambahProyekOnClick () {
      this.$router.push('/project/input')
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    }
  }
}
</script>
