<template>
  <div class="m-2">
    <h1 style="text-align: center; margin-top: 15px;" class="title is-5">Tentukan Customer</h1>
    <div style="display: flex;">
      <div>
        <b-field style="width: 300px; display:flex;">
          <div style="padding-right: 10px; width: 15%; padding-top: 10px; text-align: center;">
            <h1 class="title is-6">+62</h1>
          </div>
          <div style="width: 85%;">
            <b-input placeholder="Masukan nomor telepon"
              expanded
              v-model="handphone"
              type="number"
              min="15">
            </b-input>
          </div>
        </b-field>
      </div>
      <div class="pl-2">
        <b-button @click="searchByPhone()">
          Cari Nomor
        </b-button>
      </div>
    </div>
    <div class="card" style="margin-top: 20px;">
      <div class="card-content">
        <div v-if="isNew && !isFirst" style="padding-bottom: 30px; padding-top: 30px;">
          <div style="margin-bottom: 10px;">
            Tidak ditemukan customer dengan nomor tercantum!
          </div>
          <b-field label="Buat akun baru?">
            <b-input placeholder="Masukan nama customer baru" v-model="name"></b-input>
          </b-field>
          <div style="text-align: right; margin-top: 20px;">
            <b-button @click="daftarAkun()" style="width: 200px;" type="is-success">Daftar Akun</b-button>
          </div>
        </div>
        <div v-if="!isNew" style="padding-bottom: 30px; padding-top: 30px;">
          <div style="margin-bottom: 10px;">
            Customer dengan nomor tercantum ditemukan!
          </div>
          <h1 class="title is-5"> {{ customer.name }} </h1>
          <div style="text-align: right; margin-top: 20px;">
            <b-button @click="pilihCustomer()" style="width: 200px;" type="is-success">Pilih</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import customer from '../../api/customer'

export default {
  name: 'ProjectInputCustomer',
  components: {
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      name: null,
      handphone: null,
      handphoneSearch: null,
      isNew: true,
      customer: null,
      isFirst: true
    }
  },
  methods: {
    lanjutkan () {
      this.$emit('nextonclick')
    },
    daftarAkun () {
      if (this.name === null) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Nama Tidak Boleh Kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      axios.post(`${this.url}/api/v1/vendor/customer_add`, {
        phone: this.handphone,
        name: this.name
      })
        .then((cb) => {
          // this.$store.commit('project/setCustomerId', cb.data.data.id)
          this.customer = {
            id: cb.data.data.id,
            name: this.name,
            cell_phone: this.handphone
          }
          this.isNew = false
        })
        .catch((err) => alert(err))
    },
    searchByPhone () {
      axios.get(`${this.url}/api/v1/vendor/customer_find?cell_phone=${this.handphone}`)
        .then((cb) => {
          if (cb.data.data === null) {
            this.customer = null
            this.isNew = true
            this.isFirst = false
            // this.$store.commit('project/setCustomerId', null)
          } else {
            this.customer = cb.data.data
            this.isNew = false
            // this.$store.commit('project/setCustomerId', cb.data.data.id)
          }
        })
        .catch(() => {
          this.customer = null
          this.isNew = true
        })
    },
    findCustomer () {
      customer.getCustomerByPhone((val) => {
        if (val) {
          this.customer = val
          this.isNew = false
        } else {
          this.customer = null
          this.isNew = true
        }
      })
    },
    pilihCustomer () {
      if (!this.customer) {
        return
      }
      this.$emit('catchvalue', this.customer)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
