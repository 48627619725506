<template>
  <div class="card">
    <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
      <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Top Up Saldo</h1>
    </div>
    <div class="card-content" style="text-align: center;">
      <div>Top up saldo dengan nominal sebesar</div>
      <div class="title is-5">Rp. {{ nominal | numFormat }}</div>
      <div>
        <b-button @click="ubahNominalOnClick()" style="width: 200px; margin-right: 5px;">Ubah Nominal</b-button>
        <b-button @click="iyaOnClick()" style="width: 200px;">Iya</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopUpConfirm',
  computed: {},
  props: {
    nominal: {
      type: Number
    }
  },
  methods: {
    ubahNominalOnClick () {
      this.$emit('setubahnominal')
    },
    iyaOnClick () {
      if (this.nominal < 200000) { // top up minimal rp.200.000
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Nilai top up saldo minimal Rp.200.000',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      this.$emit('setiya')
    }
  },
  data () {
    return {}
  }
}
</script>
