<template>
  <section>
    <div class="columns is-mobile" v-if="inProject">
      <div class="column is-8 is-three-fifths is-offset-one-fifth" v-show="getClass()">
        <b-notification type="is-info" has-icon aria-close-label="Close notification">
          untuk melakukan input data before-after, project harus sudah menugaskan pekerja dan project status Active atau Completed.
        </b-notification>
      </div>
    </div>

    <div class="card" v-for="(v, k) in inProject" :key="k">
      <header class="card-header" style="background-color: grey;">
        <p class="card-header-title" style="color: snow">{{ convDate(v.date_sesi) }} </p>
      </header>

      <div class="card-content">
        <div class="content">
          <!-- detail worker -->
          <div class="columns">
            <div class="box">
              <div class="columns is-vcentered mt-1">
                <div class="column is-one-quarter" style="width: 100px;">
                  <img :src="v.worker_image" />
                </div>
                <div class="column">
                  <span class="has-text-right has-text-black-bis is-size-6">
                    <strong>{{ v.worker_name }} - {{ v.worker_id}}</strong> <br/>
                    {{ v.skill }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="columns" style="border: 1px solid black">
            <!--- List Photo Before --->
            <div class="column" style="border: 1px solid black">
              <p class="has-text-weight-bold">List Photo Before</p>
              <div v-for="(item1, idx) in filterBefore" :key="idx">
                <ul v-if="v.date_sesi === item1.dateSesi">
                  <li v-if="v.worker_id === item1.workerId">
                    <img :src="item1.photo" alt="" loading="lazy">
                    <b-button type="is-white" icon-pack="uil" @click="deletePhotoBefore(item1.id)">
                      <b-icon icon="window-close" type="is-danger" size="is-7"></b-icon>
                    </b-button>
                  </li>
                </ul>
              </div>
            </div>
            <!--- List Photo After --->
            <div class="column" style="border: 1px solid black">
              <p class="has-text-weight-bold">List Photo After</p>
              <div v-for="(item1, idx) in filterAfter" :key="idx">
                <ul v-if="v.date_sesi === item1.dateSesi">
                  <li v-if="v.worker_id === item1.workerId">
                    <img :src="item1.photo" alt="" loading="lazy">
                    <b-button type="is-white" icon-pack="uil" @click="deletePhotoAfter(item1.id)">
                      <b-icon icon="window-close" type="is-danger" size="is-7"></b-icon>
                    </b-button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="columns mt-3 mb-2">
            <div class="column mt-2" v-bind:class="getClass()">
              <div class="column media-left">
                <div class="columns is-gapless">
                  <div class="column is-6">
                    <input id="fotoBefore" class="form-control form-control-lg" style="border: 1px solid black" type="file" v-if="uploadReady" ref="fileUpload" @change="onFileSelected">
                  </div>
                  <div class="column">
                    <button @click="onUploadBefore(v.worker_id, v.date_sesi)">Upload Photo Before</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="column mt-2" v-bind:class="getClass()">
              <div class="column media-left">
                <div class="columns is-gapless">
                  <div class="column is-6">
                    <input id="fotoAfter" class="form-control form-control-lg" style="border: 1px solid black" type="file" v-if="uploadReady" ref="fileUpload" @change="onFileSelected">
                  </div> &nbsp;
                  <div class="column">
                    <button @click="onUploadAfter(v.worker_id, v.date_sesi)">Upload Photo After</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'BeforeAfterProject',
  props: ['project'],
  data () {
    return {
      uploadReady: true,
      selectedFile: null,
      inProject: null,
      statusProject: null,
      photosList: null,
      photoListAfter: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles'
    }),
    filterBefore () {
      const pht = []
      this.photosList.forEach((s) => {
        pht.push({
          id: s.id,
          workerId: s.worker_id,
          dateSesi: s.date_sesi,
          photo: s.image
        })
      })
      return pht
    },
    filterAfter () {
      const pht2 = []
      this.photoListAfter.forEach((s) => {
        pht2.push({
          id: s.id,
          workerId: s.worker_id,
          dateSesi: s.date_sesi,
          photo: s.image
        })
      })
      return pht2
    }
  },
  methods: {
    catatanShow () {
      if (this.statusProject === 'Completed') {
        return 'is-show'
      }
    },
    reset () {
      const input = this.$refs.fileUpload
      input.type = 'text'
      input.type = 'file'
    },
    clear () {
      this.uploadReady = false
      this.$nextTick(() => {
        this.uploadReady = true
      })
    },
    resetImageAfter () {
      this.selectedFile = ''
      this.file = ''
      this.selectedFile.name = ''
      this.$refs.fileUploadAfter.value = ''
    },
    getClass () {
      if (this.statusProject === 'In Planning' ||
        this.statusProject === 'Mencari Mitra' ||
        this.statusProject === 'Cancelled') {
        return 'is-hidden'
      }
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    isProject (id) {
      axios.get(this.url + '/api/v1/vendor/project_detail_sesi?project_id=' + id)
        .then((cb) => {
          this.inProject = cb.data.data[0].worker_list
          this.statusProject = cb.data.data[0].status
          this.photosList = cb.data.data[0].photo.photo_before
          this.photoListAfter = cb.data.data[0].photo.photo_after
        }).catch(err => {
          console.log(err)
        })
    },
    onFileSelected (event) {
      this.selectedFile = event.target.files[0]

      if (!this.selectedFile) {
        event.preventDefault()
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No File Choosen!',
          position: 'is-bottom',
          type: 'is-success'
        })
        this.selectedFile = null
      }
    },
    onUploadBefore (workerId, dateSesi) {
      const fd = new FormData()
      const projectIds = this.$route.params.projectId
      fd.append('worker_id', workerId)
      fd.append('date_sesi', dateSesi)
      fd.append('photo-before', this.selectedFile, this.selectedFile.name)
      axios.put(this.url + '/api/v1/vendor/upload/' + projectIds + '/photo-before', fd)
        .then(res => {
          if (res.data.code === 1) {
            localStorage.setItem('reloaded', 0)
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Upload Foto Before Berhasil!',
              position: 'is-bottom',
              type: 'is-success'
            })
            this.isProject(this.$route.params.projectId)
            this.clear()
            this.reset()
            this.selectedFile = null
            this.loading = false
          }
        }).catch(err => {
          console.log(err)
        })
    },
    onUploadAfter (workerId, dateSesi) {
      const fd = new FormData()
      const projectIds = this.$route.params.projectId
      fd.append('worker_id', workerId)
      fd.append('date_sesi', dateSesi)
      fd.append('photo-after', this.selectedFile, this.selectedFile.name)

      axios.put(this.url + '/api/v1/vendor/upload/' + projectIds + '/photo-after', fd)
        .then((res) => {
          this.isProject(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3500,
            message: 'Upload Foto After Berhasil!',
            position: 'is-bottom',
            type: 'is-success'
          })
          this.clear()
          this.reset()
          this.selectedFile = null
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    },
    deletePhotoAfter (photoId) {
      if (photoId === null) { return }
      axios.delete(this.url + '/api/v1/vendor/photo-after/' + photoId)
        .then((cb) => {
          this.isProject(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: cb.data.message,
            position: 'is-bottom',
            type: 'is-success'
          })
        }).catch(() => {
          this.isProject(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Error Cancel Order!',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    deletePhotoBefore (photoId) {
      if (photoId === null) { return }
      axios.delete(this.url + '/api/v1/vendor/photo-before/' + photoId)
        .then((cb) => {
          this.isProject(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: cb.data.message,
            position: 'is-bottom',
            type: 'is-success'
          })
        }).catch(() => {
          this.isProject(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Error Cancel Order!',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {},
  created () {
    this.isProject(this.$route.params.projectId)
    this.catatanShow()
    // this.getClass()
  }
}
</script>

<style lang="scss" scoped>
.borderCard {
  border: 1px solid rgb(192,192,192);
}
.previewBlock {
  display: block;
  cursor: pointer;
  width: 300px;
  height: 280px;
  margin: 0 auto 20px;
  background-position: center center;
  background-size: cover;
}

// BASIC
ul {
  display: flex;
  flex-wrap: wrap;
}

li {
  height: 20vh;
  flex-grow: 3;
}

li:last-child {
  // There's no science in using "10" here. In all my testing, this delivered the best results.
  flex-grow: 5;
}

img {
  max-height: 100%;
  min-width: 50%;
  object-fit: cover;
  vertical-align: bottom;
}

// ADVANCED
// Portrait
@media (max-aspect-ratio: 1/1) {
  li {
    height: 15vh;
  }
}

// Short screens
@media (max-height: 480px) {
  li {
    height: 50vh;
  }
}

// Smaller screens in portrait
@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  ul {
    flex-direction: row;
  }

  li {
    height: auto;
    width: 100%;
  }
  img {
    width: 100%;
    max-height: 55vh;
    min-width: 0;
  }
}
</style>
