import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login/Index.vue'
import UserList from '../views/user/List.vue'
import Logout from '../views/login/Logout.vue'
import Penawaran from '../views/offer/Index.vue'
import Product from '../views/product/Index.vue'
import Laporan from '../views/laporan/Index.vue'
import ProjectList from '../views/project/List.vue'
import ProjectInput from '../views/project/Input.vue'
import Pengaturan from '../views/pengaturan/Index.vue'
import ProjectDetail from '../views/project/Detail.vue'
import PembayaranList from '../views/pembayaran/List.vue'
import UbahPassword from '../views/user/UbahPassword.vue'
import PendingTopUp from '../views/laporan/PendingTopUp.vue'
import ApprovalTopUp from '../views/top-up/ApprovalTopup.vue'
import BeforeAfterProject from '../views/project/BeforeAfter.vue'
import PengaturanProductDetail from '../views/pengaturan/ProductDetail.vue'
import ProjectInputTukangLangsung from '../views/project/InputTukangLangsung.vue'

// COMPONENTS
import CreateOffer from '../views/offer/CreateOffer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/project/list',
    name: 'ProjectList',
    component: ProjectList
  },
  {
    path: '/project/detail/:projectId/:source',
    name: 'ProjectDetail',
    component: ProjectDetail
  },
  {
    path: '/project/input',
    name: 'ProjectInput',
    component: ProjectInput
  },
  {
    path: '/project/input-tukang-langsung',
    name: 'ProjectInputTukangLangsung',
    component: ProjectInputTukangLangsung
  },
  {
    path: '/pembayaran/list',
    name: 'PembayaranList',
    component: PembayaranList
  },
  {
    path: '/user/list',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: Laporan
  },
  {
    path: '/ubah-password',
    name: 'UbahPassword',
    component: UbahPassword
  },
  {
    path: '/pengaturan',
    name: 'Pengaturan',
    component: Pengaturan
  },
  {
    path: '/pengaturan/product-detail/:productId',
    name: 'PengaturanProductDetail',
    component: PengaturanProductDetail
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/pending-top-up',
    name: 'PendingTopUp',
    component: PendingTopUp
  },
  {
    path: '/approval-top-up',
    name: 'ApprovalTopUp',
    component: ApprovalTopUp
  },
  {
    path: '/project/before-after/:projectId',
    name: 'BeforeAfterProject',
    component: BeforeAfterProject
  },
  {
    path: '/penawaran',
    name: 'Penawaran',
    component: Penawaran
  },
  {
    path: '/penawaran/create',
    name: 'CreateOffer',
    component: CreateOffer
  },
  {
    path: '/penawaran/detail/:projectId/:source',
    name: 'ProjectDetail',
    component: ProjectDetail
  }
]

const router = new VueRouter({
  routes,
  linkExactActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
  // next()
  const rs = localStorage.getItem('webapp_b2b')
  const auth = JSON.parse(rs)
  if (to.name !== 'Login' && (!auth || !auth.token)) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
