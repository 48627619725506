<template>
<div>
  <div class="columns">
    <div class="column">
    <p>Saldo:</p>
      <div style="font-size: 14px; font-weight: bold; margin-bottom: 5px; border-bottom: 1px solid;">
        Rp.<span>{{ userSaldo | numFormat }}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Saldo',
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isLogin: 'auth/isLogin',
      user: 'auth/user',
      userSaldo: 'setup/getUserSaldo'
    })
  },
  methods: {},
  mounted () {},
  created () {}
}

</script>
