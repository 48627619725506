<template>
  <div>
    <b-table
      :total="paginate.total"
      :per-page="paginate.perPage"
      :data="paginate.data"
      :loading="paginate.loading"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
      backend-pagination
      scrollable>
      <b-table-column field="external_id" label="ID Top-Up" v-slot="props">
        {{ props.row.external_id }}
      </b-table-column>
      <b-table-column field="paid_amount" label="Nominal Top Up" v-slot="props">
        {{ props.row.nominal | numFormat }}
      </b-table-column>
      <b-table-column field="fees_paid_amount" label="Biaya Bank" v-slot="props">
        <span v-if="props.row.status !== 'null'"> {{ props.row.fees_paid_amount | numFormat }} </span>
      </b-table-column>
      <b-table-column field="amount" label="Nominal Masuk" v-slot="props">
        {{ props.row.amount | numFormat }}
      </b-table-column>
      <b-table-column field="payment_method" label="Metode Pembayaran" v-slot="props">
        {{ props.row.payment_method }}
      </b-table-column>
      <b-table-column field="status" label="Status Pembayaran" v-slot="props">
        <span :class="{'tag is-success': props.row.status === 'approved', 'tag is-danger': props.row.status === 'expired', 'tag is-info': props.row.status === 'pending' }"><b>{{ props.row.status }}</b></span>
      </b-table-column>
      <b-table-column field="paid_date" label="Timestamp Bayar" v-slot="props">
       <span v-if="props.row.paid_date !== 'Invalid date'"> {{ convDate(props.row.paid_date) }} </span>
      </b-table-column>
      <!-- <b-table-column field="user_vendor_name" label="Penanggung Jawab" v-slot="props">
        {{ props.row.user_vendor_name }}
      </b-table-column> -->
      <b-table-column field="payment" label="Action" v-slot="props">
        <b-button :class="{'hide': props.row.status !== 'pending'}" type="is-success" @click="setInvoice(props.row)">BAYAR</b-button>
      </b-table-column>
      <!-- <b-table-column field="catatan" label="Action" v-slot="props">
        <b-button type="is-success" @click="exportInvoice(props.row)">Export Invoice</b-button>
      </b-table-column> -->

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="paginate.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
    <div>
      <b-modal v-model="topUpMenungguPembayaranIsShow" :width="800">
        <top-up-menunggu-pembayaran
          v-if="paymentInfo !== null"
          :amount="paymentInfo.amount"
          :payment_link="paymentInfo.payment_link"
          :source_id="paymentInfo.source_id"
          :original_amount="paymentInfo.original_amount"
          :external_id="paymentInfo.external_id"
          :is_paid="paymentInfo.is_paid"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import TopUpMenungguPembayaran from '../top-up/MenungguPembayaran.vue'

export default {
  name: 'laporanRiwayatTopUp',
  components: {
    TopUpMenungguPembayaran
  },
  props: {
    date: Object
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
      }
    }
  },
  methods: {
    exportInvoice (val) {
      alert(val.nominal)
    },
    onPageChange (page) {
      this.getData(this.date, page)
    },
    convDate (val) {
      return moment(val).format('DD MMMM YYYY')
    },
    getData (val, page) {
      this.paginate.loading = true
      this.paginate.page = page
      const pr = '/api/v1/vendor/report/topup-nominal?start_date=' + val.tgl1 + '&end_date=' + val.tgl2 + '&limit=' + this.paginate.perPage + '&page=' + this.paginate.page
      axios.get(this.url + pr)
        .then((cb) => {
          this.paginate.data = cb.data.data
          this.paginate.total = (cb.data.total_page * this.paginate.perPage)
          this.paginate.page = cb.data.current_page
          this.paginate.loading = false
        })
    },
    setInvoice (val) {
      this.paymentInfo = val
      this.topUpMenungguPembayaranIsShow = true
    }
  },
  data () {
    return {
      topUpMenungguPembayaranIsShow: false,
      filterTanggal: null,
      paymentInfo: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 15,
        data: []
      }
    }
  },
  created () {}
}
</script>

<style lang="scss" scoped>

.hide {
  display: none;
}

</style>
