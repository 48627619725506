<template>
  <section>
    <!-- HEADER DETAIL -->
    <div class="card">
      <div class="card-content">
        <p
          @click="back()"
          class="back-btn title is-6"
          style="margin-bottom: 10px;">
            <b-icon icon='arrow-left'/> Kembali ke proyek
        </p>
        <h1
          class="title is-4 pl-2">
          Detail Proyek - {{ !title ? 'Penawaran' : title }} -
            (#{{ !this.$route.params.projectId ? this.$route.params.idPenawaran : this.$route.params.projectId }})
        </h1>
      </div>
    </div>
    <!-- TABS DETAIL PROJECT -->
    <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
      <div class="level-left">
        <div @click="selectTab(0)" class="tab-label" v-bind:class="{'active' : activeTab === 0}"> Detail </div>
        <div v-if="!roleAccess('ADMIN')" @click="selectTab(1)" class="tab-label" v-bind:class="{'active' : activeTab === 1}"> Worker Sign </div>
        <div v-if="!roleAccess('ADMIN')" @click="selectTab(2)" class="tab-label" v-bind:class="{'active' : activeTab === 2}"> Before-After </div>
      </div>
    </div>
    <!-- DETAIL PROJECT -->
    <div class="columns is-gapless">
      <div class="column">
        <div class="card" v-show="activeTab === 0">
          <div class="card-content" v-if="project !== null">
            <div class="columns">
              <div class="column" style="border-right: solid thin #f0f0f0;">
                <div class="columns is-gapless mb-4" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
                  <div class="column">
                    <h1 class="title is-5 mt-3">Proyek ID #{{ project.id }}</h1>
                  </div>
                  <div v-if="!roleAccess('ADMIN')">
                    <div class="column" v-bind:class="hideWhenStatus()">
                      <b-button type="is-danger" @click="cancelOrder(project.id)">Batalkan Proyek</b-button>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="has-text-weight-bold" v-if="project.no_penawaran !== null">
                      No.Penawaran: {{ project.no_penawaran === null ? '-' : project.no_penawaran }} <br/>
                    </div>
                    <br/>
                    <div class="has-text-weight-bold">
                      Nama Customer: {{ project.consumer_name }} <br/>
                      Telp Customer: {{ project.consumer_phone }} <br/>
                    </div>
                    <br/>
                    <div class="has-text-weight-bold">
                      Pesanan:
                      <span
                        :class="{'tag is-success is-light': project.type === 'Langsung',
                                  'tag is-info is-light': project.type === 'Khusus',
                                  'tag is-primary is-light': project.type === 'Penawaran'}">
                        {{ project.type }}
                        </span>
                      <br/>
                      Pesanan Dibuat: {{ project.created_project }}<br/>
                      Tanggal Pembuatan Pesanan: {{ convDate(project.created_at) }} <br/> <br/>
                      Status Proyek:
                        <span
                          :class="{'tag is-success': project.status === 'Completed',
                                    'tag is-info': project.status === 'In Planning',
                                    'tag is-primary': project.status === 'Mencari Mitra',
                                    'tag is-danger': project.status === 'Cancelled'}">
                          {{ project.status }}
                        </span>
                    </div>
                    <div class="mt-5">
                      <strong>Deskripsi Pekerjaan</strong>
                      <div> {{ project.description }} </div>
                    </div>
                  </div>
                  <div class="garis_verikal" v-if="project.product_details.length > 0"></div>
                  <div class="column" v-if="project.product_details.length > 0">
                    <div>
                      <strong>ID Transaksi Barang</strong>
                      <h1 class="title is-6"># {{ project.idtransaksiitem }}</h1>
                    </div>
                    <br/>
                    <div>
                      <strong>Produk yang Dikerjakan</strong>
                      <div class="columns is-vcentered mt-1" v-for="(v, ii) in project.product_details" :key="ii">
                        <div class="column is-one-quarter">
                          <img :src="v.product_image" />
                        </div>
                        <div class="column">
                          <span class="has-text-right has-text-black-bis is-size-6">
                            <strong>{{ v.product_name }}</strong> <br/>
                            Layanan: {{ v.service_name }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns mt-4" style="border-top: solid thin #f0f0f0;">
                  <div class="column is-full">
                    <div class="columns">
                      <div class="column">
                        <span><strong>Tanggal Pengerjaan</strong></span>
                      </div>
                    </div>
                    <div class="columns" style="border-bottom: solid thin #f0f0f0;">
                      <div class="column is-12">
                        <div class="my-1" style="display: flex;">
                          <div style="width: 100%;">
                            <table class="table" style="width: 100%;">
                              <thead>
                                <tr>
                                  <td class="has-text-black">
                                    <div class="columns">
                                      <div class="column" v-if="!roleAccess('ADMIN')">
                                        <div v-bind:class="getClass()">
                                          <b-button type="is-info" @click="showReschedule()">Reschedule</b-button>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </thead>
                              <tbody v-for="(v, i) in project.schedule" :key="i">
                                <tr v-for="(vv, ii) in v.detail_sesi" :key="ii">
                                  <td>{{ convDate(v.date_sesi) }}, {{ vv.dataWorker.name }}
                                    {{ project.type !== 'Penawaran' ? vv.name : '' }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 20px;">
                  <div style="width: 30%;">
                    <strong>Total Biaya</strong>
                    <h1 class="title is-5">Rp.{{ parseInt(project.amount) | numFormat }}</h1>
                  </div>
                  <div style="width: 70%;" v-if="project.type !== 'Penawaran'">
                    <b-button type="is-success" @click="showDetailHarga()">Detail Harga</b-button>
                  </div>
                </div>
              </div>
              <div class="column">
                <!-- <div class="column">
                  <h1 class="title is-5">Catatan Proyek</h1>
                  <div class="columns">
                    <div class="column">
                      <div class="columns">
                        <div class="column is-11">
                          <p>{{ project.name }} | {{ project.start_date }}</p>
                          <p class="mt-3">{{ project.project_notes }}</p>
                        </div>
                        <div class="column">
                          <b-icon icon="dots-vertical" size="is-small"></b-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns is-mobile" v-bind:class="getClass()">
                    <div class="container is-max-desktop" v-if="project.project_notes === null">
                      <div class="column is-full">
                        <b-input placeholder="Tuliskan catatan-catatan yang berhubungan dengan proyek disini.Seperti komplain customer, kejadian mencolok, catatan untuk proyek selanjutnya, dll."
                          v-model="notes" rows="4" maxlength="200" type="textarea" required></b-input>
                        <b-button class="is-success" @click="saveNotes(project.id)" label="Simpan"></b-button>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="column">
                  <p class="title is-5">Catatan Proyek</p>
                  <p class="subtitle is-7">{{ project.name }} | {{ convDate(project.start_date) }}</p>
                  <div class="columns is-mobile">
                    <div class="container is-max-desktop" v-if="project.project_notes !== null">
                      <div class="column is-full">
                        <b-input rows="4" maxlength="200" type="textarea" :value="project.project_notes" disabled></b-input>
                      </div>
                    </div>
                    <div class="container is-max-desktop" v-if="project.project_notes === null">
                      <div class="column is-full" v-bind:class="getClass()">
                        <b-input placeholder="Tuliskan catatan-catatan yang berhubungan dengan proyek disini.Seperti komplain customer, kejadian mencolok, catatan untuk proyek selanjutnya, dll."
                          v-model="notes" rows="4" maxlength="200" type="textarea" required></b-input>
                        <b-button class="is-success" @click="saveNotes(project.id)" label="Simpan"></b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-loading :is-full-page="isFullPage" v-model="isLoading"></b-loading>
    </div>
    <!-- WORKER ASSIGN -->
    <div class="card" v-show="activeTab === 1">
      <div v-if="!roleAccess('ADMIN')">
        <sign-worker-manual :project='project'></sign-worker-manual>
      </div>
    </div>
    <!-- UPLOAD BUKTI PENGERJAAN -->
    <div class="card" v-show="activeTab === 2">
      <div v-if="!roleAccess('ADMIN')">
        <upload-before-after :project='project'></upload-before-after>
      </div>
    </div>
    <!-- MODAL DETAIL HARGA -->
    <div v-if="project !== null">
      <b-modal
        v-model="modalHargaDetail"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Detail Harga"
        close-button-aria-label="Close"
        aria-modal>
        <template>
          <div class="modal-card" style="width:auto">
            <header class="modal-card-head p-1">
              <p class="modal-card-title title is-5 has-text-centered m-3">Detail Harga</p>
            </header>
            <section class="modal-card-body has-background-white-ter">
              <div>
                <div class="columns">
                  <div class="column is-full">
                    <table class="is-fullwidth table is-bordered">
                      <tr class="has-text-weight-bold is-size-7">
                        <td>Tanggal Pengerjaan</td>
                        <td>Tukang</td>
                        <td>Sesi</td>
                        <td>Jumlah Tukang</td>
                        <td>Harga Pertukang</td>
                      </tr>
                      <tbody v-for="(value, ps) in project.schedule" :key="ps">
                        <tr v-for="(value2, ps2) in value.detail_sesi" :key="ps2">
                          <td class="is-size-7">{{ convDate(value.date_sesi) }}</td>
                          <td>
                            <div class="columns is-vcentered">
                              <div class="column is-one-quarter">
                                <p class="image is-32x32">
                                  <img :src="value2.dataWorker.image_uri" />
                                </p>
                              </div>
                              <div class="column is-full">
                                <span class="has-text-right is-size-7">{{ value2.dataWorker.name }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="px-4 py-1 is-size-7">{{ value2.name }} ({{ value2.start_hour }} - {{ value2.end_hour }})</td>
                          <td class="is-size-7 has-text-centered">{{ value2.dataWorker.total_worker }}</td>
                          <td class="is-size-7 has-text-centered">Rp. {{ value2.price | numFormat }}</td>
                        </tr>
                      </tbody>
                      <tr>
                        <td colspan="4" class="has-text-right is-size-7"><b>Sub Total</b></td>
                        <td class="px-5 is-size-7 has-text-centered"><span>Rp. {{ totalPerHari | numFormat }}</span></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <hr style="border-top: 1px solid #cecece;">
              <div class="columns">
                <div class="column is-full">
                  <table class="is-fullwidth">
                    <tr>
                      <td><b> Total Setelah Pph </b></td>
                      <td class="px-6"><b> Rp.{{ parseInt(project.amount) - parseInt(totalBiayaParkir) | numFormat }}</b></td>
                    </tr>
                    <tr>
                      <th><span><b> Biaya Parkir</b></span></th>
                      <th class="px-6"><span>Rp.{{ totalBiayaParkir | numFormat }} </span>
                        <!-- <span v-show="project.fee_charge_building !== 0"> /Worker</span> -->
                      </th>
                    </tr>
                    <tr>
                      <td><b> Total </b></td>
                      <td class="px-6"><b> Rp.{{ parseInt(project.amount) | numFormat }}</b></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="column is-10 is-vcentered">
                <div class="box py-2" style="background-color:#D3D3D3; border: #a9a9a9 1px solid; width: 115%;">
                  <div class="columns">
                    <div class="column is-9">
                      <p class="has-text-black-bis is-size-6"> *Harga diatas termasuk Pph <br>
                      Pph 2% </p>
                    </div>
                    <div class="column">
                      <span class="has-text-left has-text-black-bis is-size-6"><b> Rp.{{ project.pph | numFormat }}</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </template>
      </b-modal>
    </div>
    <!-- MODAL RESCHEDULE -->
    <div v-if="project !== null">
      <b-modal
        v-model="modalReschedule"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Reschedule Pengerjaan"
        close-button-aria-label="Close"
        scroll="keep"
        aria-modal>
        <template>
          <div class="modal-card" style="width:auto">
            <header class="modal-card-head p-1">
              <p class="modal-card-title title is-5 has-text-centered m-3">Reschedule Pengerjaan</p>
            </header>
            <section class="modal-card-body has-background-white-ter">
              <reschedule-project :product="this.productId"></reschedule-project>
            </section>
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
// import loadash from 'lodash'
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'
import SignWorkerManual from './WorkerSign.vue'
import UploadBeforeAfter from './BeforeAfter.vue'
import RescheduleProject from './RescheduleProject.vue'
// import product from '../../api/product'

export default {
  name: 'ProjectDetail',
  components: {
    SignWorkerManual,
    UploadBeforeAfter,
    RescheduleProject
  },
  data () {
    return {
      activeTab: 0,
      modalHargaDetail: false,
      modalReschedule: false,
      project: null,
      notes: null,
      catatanProyek: null,
      title: '',
      statusProj: null,
      productId: null,
      loading: true,
      errored: false,
      isFullPage: false,
      isLoading: false
      // workerSelected: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath'
    }),
    totalBiayaParkir () {
      let sum = 0
      this.project.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          sum += result.dataWorker.total_worker
        })
      })
      const biayaParkir = this.project.fee_charge_building
      const amountBiayaParkir = (biayaParkir * sum)
      return amountBiayaParkir
    },
    // totalSesiPlusTukang () {
    //   let sum = 0
    //   this.project.schedule.forEach((row) => {
    //     row.detail_sesi.forEach((result) => {
    //       sum = (parseFloat(result.price))
    //     })
    //   })
    //   return sum
    // },
    totalPerHari () {
      let x = 0
      this.project.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          x += (result.price * result.dataWorker.total_worker)
        })
      })
      return x
    },
    skillTukang () {
      const skill = []
      this.project.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          skill.push(result.dataWorker.name)
        })
      })
      return skill
    }
    // totalTukang () {
    //   let totalTukang = 0
    //   this.project.schedule.forEach((row, index) => {
    //     totalTukang += row.detail_sesi[index].dataWorker.total_worker
    //   })
    //   return totalTukang
    // }
  },
  methods: {
    getClass () {
      if (this.statusProj !== null) {
        if (this.statusProj === 'Active' ||
            this.statusProj === 'Completed' ||
            this.statusProj === 'Cancelled') {
          return 'is-hidden-mobile'
        }
      }
    },
    hideWhenStatus () {
      if (this.statusProj === 'Active' ||
          this.statusProj === 'Completed' ||
          this.statusProj === 'Cancelled') {
        return 'is-hidden-mobile'
      }
    },
    selectTab (val) {
      this.activeTab = val
    },
    cancelOrder (id) {
      if (id === null) { return }
      axios.get(`${this.url}/api/v1/vendor/project_detail/${id}/cancel`)
        .then((cb) => {
          this.getDetails(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Cancel Order Berhasil!',
            position: 'is-bottom',
            type: 'is-success'
          })
        }).catch(() => {
          this.getDetails(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Error Cancel Order!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.clearInput()
        })
    },
    buktiPengerjaan (v) {
      this.$router.push('/project/before-after/' + v.id)
    },
    roleAccess (role) {
      if (this.roles === null) { return true }

      let roleCheck = false
      if (this.roles !== role) {
        roleCheck = true
      }
      return roleCheck
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    clearInput () {
      this.notes = null
    },
    showReschedule () {
      this.viewmodal(2)
    },
    showDetailHarga () {
      this.viewmodal(1)
    },
    viewmodal (newVal) {
      if (newVal === 1) {
        this.modalHargaDetail = true
      }

      if (newVal === 2) {
        this.modalReschedule = true
      }
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    back () {
      this.$router.push({ name: 'ProjectList' })
    },
    getDetails (id) {
      this.isLoading = true

      this.$store.dispatch('project/proyekDetailsApi', { url: this.url, id: id })
        .then(cb => {
          if (cb.data.code === 1) {
            this.project = cb.data.data[0]
            this.statusProj = cb.data.data[0].project_status
            this.productId = cb.data.data[0].product_id
          }
          setTimeout(() => {
            this.isLoading = false
          }, 1 * 1000)
        })
        .catch((error) => {
          this.errored = true
          throw new Error(error)
        })
    },
    saveNotes (id) {
      if (this.notes === null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'data tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        this.clearInput()
        return
      }
      const data = {
        notes: this.notes
      }
      axios.put(`${this.url}/api/v1/vendor/project_detail/${id}/notes`, data)
        .then(() => {
          this.getDetails(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Simpan berhasil !...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.isShow = false
          this.clearInput()
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.clearInput()
        })
    }
  },
  mounted () {},
  created () {
    this.getDetails(this.$route.params.projectId)
    guard.check(this.$router, this.$router.currentRoute.path)
    this.title = this.$route.params.source
  }
}
</script>

<style lang="scss" scoped>
.back-btn {
  cursor: pointer;
}
.dash-container {
  padding: 20px 0px 0px 0px;
}
.line-item {
  margin-bottom: 10px;
}
.line-group {
  margin-bottom: 25px;
}

.is-horizontal-center {
  justify-content: center;
}

.garis_verikal {
  border-left: 1px black solid;
  height: 350px;
  width: 0px;
}

.is-hidden-mobile {
  display: none;
}

.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
