<template>
  <div class="card">
    <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
      <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Create Virtual Account</h1>
    </div>
    <div class="card-content">
      <!-- <b-field label="Rp." horizontal>
        <b-input v-model="nominal" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="Masukan nominal Top-Up"></b-input>
        <b-button @click="setTopUp()">Top Up</b-button>
      </b-field> -->
      <b-field label="Pilih Bank">
        <b-select v-model="bankSelected" placeholder="Select Bank" width="200">
          <option
            v-for="option in bank"
            :value="option"
            :key="option.id">
            {{ option.bank_code }} - {{ option.method }}
          </option>
        </b-select>
        <b-button class="mx-4" @click="setTopUpVa()">Create Account</b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateVirtualAccount',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  props: {
    date: Object
  },
  methods: {
    clearInput () {
      this.bankSelected = null
    },
    setTopUpVa () {
      if (this.bankSelected === '' || this.bankSelected === null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Pilih Salah Satu Bank..!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        this.clearInput()
        return
      }

      axios.post(this.url + '/api/v1/vendor/static-va', {
        bank_code: this.bankSelected.code
      })
        .then((cb) => {
          this.$emit('settopupva')
        })
        .catch((err) => alert(err))
    },
    listBankVA () {
      axios.get(this.url + '/api/v1/vendor/static-va/banks')
        .then((cb) => {
          this.bank = cb.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  watch: {
    nominal () {
      this.$emit('isinput', this.nominal)
    }
  },
  data () {
    return {
      bankSelected: null,
      nominal: null,
      bank: []
    }
  },
  created () {
    this.listBankVA()
  }
}
</script>
