<template>
  <div class="card">
    <div class="has-background-success" style="text-align: center; height: 50px; padding-top: 10px;">
      <h1 class="title is-5 has-text-white" style="margin-bottom: 0px;">Top Up Saldo</h1>
    </div>
    <div class="card-content">
      <iframe :src="payment_link" width="750" height="700"></iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopUpMenungguPembayaran',
  computed: {},
  props: {
    amount: {
      type: Number,
      required: true
    },
    source_id: {
      type: Number,
      required: true
    },
    original_amount: {
      type: Number,
      required: true
    },
    external_id: {
      type: String,
      required: true
    },
    is_paid: {
      type: Number,
      required: true
    },
    payment_link: {
      type: String,
      required: true
    }
  },
  methods: {
    lanjutkanOnClick () {
      this.$emit('setlanjutkan')
    }
  },
  data () {
    return {
      isOpen1: true,
      isOpen2: false
    }
  }
}
</script>
