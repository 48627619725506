<template>
  <transition name="fadeDown">
    <div v-if="isLogin" class="dash-container">
      <section style="margin-bottom: 20px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4">
                <b-icon icon="home" /> &nbsp;Dashboard
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <app-button-project/>
            </div>
          </div>
        </div>
      </section>

      <div class="columns">
        <div class="column">
          <div class="card" style="background-color: rgb(240, 240, 240); padding-bottom: 20px;">
            <div class="card-content">
              <div class="level">
                <div class="level-left">
                  <h4 class="title is-6">Mencari Mitra</h4>
                </div>
              </div>
            </div>
            <proyek-mencari-mitra-list/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import AppButtonProject from '../components/AppButtonProject.vue'
import ProyekMencariMitraList from '../components/ProyekMencariMitraList.vue'

export default {
  name: 'Home',
  components: {
    AppButtonProject,
    ProyekMencariMitraList
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user'
    })
  },
  data () {
    return {}
  },
  methods: {},
  created () {
    const rs = localStorage.getItem('reloaded')
    const v = JSON.parse(rs)

    if (v === 0) {
      localStorage.setItem('reloaded', '1')
      location.reload()
    } else {
      localStorage.removeItem('reloaded')
    }
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.project-btn {
  color: green;
  cursor: pointer;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.linkdisable {
  opacity: 0.5;
  pointer-events: none;
}
.bg-top {
  background-color: #ebeeff;
}
.level-item {
  color: rgb(95, 95, 95);
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
}
.right-border {
  border-right: solid thin rgb(184, 184, 184);
}
.account-nav {
  // background-color: gray;
  // border-radius: 5px;
  // color: white;
  border-bottom: solid thin grey;
  font-size: 12px;
  height: 30px;
}
.account-link {
  width: 50%;
  cursor: pointer;
  padding-top: 5px;
  &:hover {
    background-color: black;
    color: white;
  }
  text-align: center;
  border-right: solid thin grey;
}
</style>
