
import Vue from 'vue'
import Buefy from 'buefy'
import VCalendar from 'v-calendar'
import * as VueGoogleMaps from 'vue2-google-maps'
import Geocoder from '@pderas/vue2-geocoder'
import axios from 'axios'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'buefy/dist/buefy.css'
import App from './App.vue'
import router from './router'
import store from './store'
import VueEasyLightbox from 'vue-easy-lightbox'
import JsonExcel from 'vue-json-excel'

Vue.config.productionTip = false

axios.defaults.headers.common['API-KEY'] = process.env.VUE_APP_API_KEY
axios.defaults.headers.Authorization = process.env.VUE_APP_HEADER_AUTHORIZATION
store.commit('setup/setUrl', process.env.VUE_APP_URL_API_APP)

Vue.use(JsonExcel)
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueEasyLightbox)
Vue.filter('numFormat', numFormat(numeral))
Vue.use(Buefy, {})
Vue.use(VCalendar)
Vue.use(Autocomplete)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_KEY_MAPS,
    libraries: 'places'
  }
})

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: 'lat-lng', // or 'lat-lng'
  googleMapsApiKey: process.env.VUE_APP_API_KEY_MAPS
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
