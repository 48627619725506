<template>
  <div class="columns">
    <div class="column">
      <div class="card">
        <div class="card-content">
          <section v-bind:class="statusProj()" v-if="project !== null">
            <div class="level">
              <div class="level-left"> <h4 class="title is-6">Sign Tukang</h4> </div>
            </div>
            <div class="card-content borderCard">
              <div class="columns">
                <div class="column">
                <!-- Search Worker -->
                  <div class="mt-2">
                    <b-field label="Find a Worker" :label-position="labelPosition">
                      <autocomplete
                      placeholder="Cari worker"
                      aria-label="Cari worker"
                      ref="autocomplete"
                      :get-result-value="getResultValue"
                      @submit="handleResultValue"
                      :search="cariWorker"
                      :debounce-time="500"
                      clearable>
                      <template #result="{ result, props }">
                        <div class="media" v-bind="props">
                          <div class="media-left"> <img width="32" :src="result.image_uri"> </div>
                          <div class="media-content"> {{ result.name }} </div>
                        </div>
                      </template>
                      </autocomplete>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="columns" v-if="workerSelected !== null">
                <div class="column">
                  <!-- Pilih Skills -->
                  <div class="mt-3">
                    <b-field label="Pilih Skills">
                      <div v-if="currentSkill === null">
                        <b-dropdown
                          scrollable
                          max-height="300"
                          expanded
                          @change="skillIsSelected"
                          v-model="currentSkill"
                          aria-role="list">
                          <template #trigger>
                            <b-button label="Pilih skills yang anda butuhkan" type="is-light" icon-right="menu-down" />
                          </template>
                          <b-dropdown-item v-for="(menu, index) in skills" :key="index" :value="menu" aria-role="listitem">
                            <div class="media">
                              <img :src="menu.image_uri" class="media-left" style="width: 25px;" />
                              <div class="media-content"> <h3>{{menu.name}}</h3> </div>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div v-if="currentSkill !== null" style="border: solid thin grey; border-radius: 5px; padding: 10px;">
                        <div class="media">
                          <img :src="currentSkill.image_uri" class="media-left" style="width: 25px;" />
                          <div class="media-content"> <h3>{{currentSkill.name}}</h3> </div>
                        </div>
                      </div>
                    </b-field>
                  </div>
                  <!-- Pilih Tanggal -->
                  <div class="mt-3">
                    <b-field label="Pilih Tanggal">
                      <div v-if="tglProject === null">
                        <b-dropdown
                          scrollable
                          max-height="300"
                          expanded
                          @change="tglIsSelected"
                          v-model="tglProject"
                          aria-role="list">
                          <template #trigger>
                            <b-button label="Pilih Tanggal" type="is-light" icon-right="menu-down" />
                          </template>
                          <b-dropdown-item
                            v-for="(ps, index) in project.schedule"
                            :key="index"
                            :value="ps" aria-role="listitem">
                            <div class="media">
                              <div class="media-content"> <h3>{{ convDate(ps.date_sesi) }}</h3> </div>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div v-if="tglProject !== null" style="border: solid thin grey; border-radius: 5px; padding: 10px;">
                        <div class="media">
                          <div class="media-content"> <h3>{{ tglProject.date_sesi }}</h3> </div>
                        </div>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Preview Insert -->
          <div class="card-content">
            <div v-if="workerSelected !== null">
              <table class="table is-fullwidth">
                <tr>
                  <th>Worker</th>
                  <th>Skill</th>
                  <th>Tanggal Pengerjaan</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>
                    <div class="media">
                      <img :src="workerSelected.image_uri" class="media-left" style="width: 25px;" />
                      <div class="media-content">
                        <h3>{{ workerSelected.name }}</h3>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="currentSkill !== null">
                      <div class="media">
                        <img :src="currentSkill.image_uri" class="media-left" style="width: 25px;" />
                        <div class="media-content">
                          <h3>{{currentSkill.name}}</h3>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="tglProject !== null">
                      <div> {{ convDate(tglProject.date_sesi) }} </div>
                    </div>
                  </td>
                  <td style="padding-top: 20px; padding-bottom: 20px">
                    <b-button @click="selectedAll(workerSelected)" type="is-success" size="is-small">Save</b-button> &nbsp;
                    <b-button @click="clearData()" type="is-danger" size="is-small">Delete</b-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- result worker assign -->
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <div class="card">
                  <div class="card-content">
                    <div class="level">
                      <div class="level-left">
                        <h4 class="title is-6">Tukang Yang Sudah Dipilih: </h4>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column" v-if="projectWorker !== null">
                        <table class="table is-fullwidth" v-if="projectWorker.worker_list !== null">
                          <tr>
                            <th>Nama Tukang</th>
                            <th>Skill</th>
                            <th>Tanggal Pengerjaan</th>
                            <th v-bind:class="statusProj()">Action</th>
                          </tr>
                          <tr v-for="(value, index) in projectWorker.worker_list" :key="index">
                            <td>
                              <div class="media">
                                <img :src="value.worker_image" class="media-left" style="width: 25px;" />
                                <div class="media-content">
                                  <h3>{{ value.worker_name }}</h3>
                                </div>
                              </div>
                            </td>
                            <td> {{ value.skill }} </td>
                            <td> {{ convDate(value.date_sesi) }} </td>
                            <td v-bind:class="statusProj()">
                              <b-button @click="removeCurrentSkill(value.id)" icon-left="delete" size="is-small" type="is-danger" outlined />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkerSign',
  props: ['project'],
  data () {
    return {
      isLoading: false,
      search: null,
      workerData: [],
      workerSelected: null,
      skillWorker: null,
      selectedSkillWorker: [],
      labelPosition: 'on-border',
      skills: [],
      currentSkill: null,
      selectedS: null,
      hideSkills: false,
      tglProject: null,
      selectedTglproj: null,
      workerId: {},
      projectWorker: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  watch: {},
  methods: {
    convDate (val) {
      return moment(val).format('dddd, DD MMMM YYYY')
    },
    statusProj () {
      const stats = this.project.status
      if (stats === 'Active' || stats === 'Completed' || stats === 'Cancelled') {
        return 'is-hidden'
      }
    },
    clearData () {
      this.workerSelected = null
      this.currentSkill = null
      this.tglProject = null
    },
    tglIsSelected (event) {
      this.selectedTglproj = event
    },
    skillIsSelected (event) {
      this.selectedS = event
    },
    backToSearch () {
      this.workerSelected = null
    },
    getResultValue (val) {
      if (val !== null) {
        return val.name
      }
    },
    handleResultValue (val) {
      let totalW = 0
      this.projectWorker.schedule.forEach((rw, _idx) => {
        rw.detail_sesi.forEach((rows, _idxs) => {
          if (rows.dataWorker.total_worker !== null) {
            totalW += rows.dataWorker.total_worker
          }
        })
      })

      var lengthWorker = this.projectWorker.worker_list.length
      for (let i = 0; i < lengthWorker; i++) {
        if (lengthWorker >= totalW) {
          this.workerSelected = null
          this.$refs.autocomplete.value = ''
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Worker Sudah Dipilih Melebihi batas!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false
        // } else if (val.id === this.projectWorker.worker_list[i].worker_id) {
        //   console.log(this.projectWorker.worker_list[i])
        //   this.workerSelected = null
        //   this.$refs.autocomplete.value = ''
        //   this.$buefy.toast.open({
        //     duration: 2000,
        //     message: 'Worker Sudah Dipilih!',
        //     position: 'is-bottom',
        //     type: 'is-danger'
        //   })
        //   return false
        } else if (val !== null && val.id !== this.projectWorker.worker_list[i].worker_id) {
          this.workerSelected = val
        }
      }

      this.workerSelected = val
    },
    cariWorker (val) {
      if (val === null) { return }
      const _search = this.url + '/api/v1/vendor/worker?search=' + val
      return new Promise((resolve) => {
        if (val?.length < 1) { return resolve([]) }
        axios.get(_search)
          .then((cb) => {
            resolve(cb.data.data)
          })
          .catch(() => {
            resolve([])
          })
      })
    },
    cariSkill () {
      axios.get(this.url + '/api/v1/vendor/skill?page=1&limit=1000')
        .then((cb) => {
          this.skills = cb.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectedAll (val) {
      var lengthWorker = this.projectWorker.worker_list.length
      var dateSesi = this.tglProject.date_sesi
      for (let i = 0; i < lengthWorker; i++) {
        if (val.id === this.projectWorker.worker_list[i].worker_id && dateSesi === this.projectWorker.worker_list[i].date_sesi) {
          this.workerSelected = null
          this.$refs.autocomplete.value = ''
          this.$buefy.toast.open({
            duration: 2500,
            message: 'Worker Sudah Dipilih!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false
        }
      }

      const data = {
        worker_id: val.id,
        skill_id: this.currentSkill.id,
        worker_image: val.image_uri,
        project_id: this.project.id,
        sesi_id: this.tglProject.sesi_id,
        date_sesi: this.tglProject.date_sesi
      }
      axios.post(this.url + '/api/v1/vendor/worker-assign', data)
        .then(() => {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Simpan berhasil !...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearData()
          this.$refs.autocomplete.value = ''
          this.getWorkerAssign(this.$route.params.projectId)
          this.loading = false
        }).catch((err) => {
          console.log()
          this.$buefy.toast.open({
            duration: 3000,
            message: err.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    removeCurrentSkill (v) {
      this.$buefy.dialog.confirm({
        message: 'Hapus tukang terpilih ?',
        onConfirm: () => {
          axios.delete(this.url + '/api/v1/vendor/worker-assign/' + v + '/remove')
            .then((cb) => {
              this.clearData()
              this.$refs.autocomplete.value = ''
              this.getWorkerAssign(this.$route.params.projectId)
              this.$buefy.toast.open({
                duration: 2000,
                message: 'Hapus tukang berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
              this.loading = false
            }).catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus tukang',
                position: 'is-bottom',
                type: 'is-danger'
              })
              this.loading = false
            })
        }
      })
    },
    getWorkerAssign (id) {
      axios.get(this.url + '/api/v1/vendor/project_detail_sesi?project_id=' + id)
        .then((cb) => {
          this.projectWorker = cb.data.data[0]
        })
    }
  },
  created () {
    this.cariWorker()
    this.cariSkill()
    this.getWorkerAssign(this.$route.params.projectId)
  }
}
</script>

<style lang="scss" scoped>
.is-hidden {
  visibility: hidden;
}

.is-show {
  visibility: visible;
}

.borderCard {
  border: 1px solid rgb(192,192,192);
}

</style>
